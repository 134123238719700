import * as Yup from 'yup';

function equalTo(ref, msg) {
    return Yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg || 'Path must be the same as Reference',
        params: {
            reference: ref.path,
        },
        test: function (value) {
            return value === this.resolve(ref);
        },
    });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export default Yup.object().shape({
    educationInfo: Yup.array().of(
        Yup.object().shape({
            schoolName: Yup.string()
                .min(3, 'Too Short')
                .max(70, 'Too Long')
                .required('Required')
                .nullable(),
            campusName: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            zipCode: Yup.string().required('Required'),
            start: Yup.string().required('Required'),
            isCurrent: Yup.boolean(),
            end: Yup.string().when('isCurrent', {
                is: false,
                then: Yup.string().required('Required'),
            }),
            degree: Yup.string().required('Required').nullable(),
            schoolType: Yup.string().required('Required'),
            majors: Yup.array().of(Yup.string()).min(1).required('Required').nullable(),
            minors: Yup.array().of(Yup.string()).min(1).required('Required').nullable(),
        })
    ),
});
