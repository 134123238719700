import { connect } from 'react-redux';
import { updateSessionToken } from 'actions/session';
import { updateReauthenticateSessionID } from 'actions/reAuthenticate';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        pathname: state.router.location.pathname,
        profile: state.profile,
        reAuthenticateSessionID: state.reAuthenticate?.reAuthenticateSessionID,
        reauthenticateSessionStatus: state.reAuthenticate?.reauthenticateSessionStatus,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSessionToken: (token, redirect, source) =>
            dispatch(updateSessionToken(token, redirect, source)),
        updateReauthenticateSessionID: (sessionID, redirect, source) =>
            dispatch(updateReauthenticateSessionID(sessionID, redirect, source)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps);
