/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'core-components/Button';
import styles from '../DeviceLinks.module.scss';
import Form from 'react-bootstrap/Form';
import { Field } from 'formik';
import ValidatedPhoneNumber from 'core-components/ValidatedPhoneNumber';
import { setButtonVariant } from 'utils/utilities';

const Message = (props) => {
    const medallionSuccess = props.communication.success && props.communication.type === 'MESSAGE';
    const reauthenticateSuccess =
        props.communication.success && props.communication.type === 'REAUTHENTICATE_MESSAGE';
    const success = medallionSuccess || reauthenticateSuccess;

    const [secs, setSecs] = React.useState(60);

    React.useEffect(() => {
        let interval = null;
        if (secs >= 0 && success) {
            interval = setTimeout(() => {
                const diff = (Date.now() - props.communication.instant) / 1000;
                setSecs(parseInt(60 - parseInt(diff)));
            }, 1000);
        } else {
            setSecs(60);
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [success, secs]);

    const { handleSubmit, isSubmitting } = props;

    return (
        <Form noValidate onSubmit={handleSubmit}>
            <Card.Body style={{ padding: '24px 0px 0px' }} className={styles.body}>
                <div>
                    <h4 className={styles.PassportCardHeading}>
                        {success
                            ? 'Successfully sent a link on your phone'
                            : 'Receive a link on your phone'}
                    </h4>
                    <p className={styles.PassportCardSubheading}>
                        {success
                            ? 'Please open the link we have sent on your mobile device'
                            : 'Enter your mobile phone number and we will send you a link to complete this verification on your mobile device.'}
                    </p>
                    {success ? (
                        <span className="text-muted" style={{ fontSize: '12px' }}>
                            {`You can again retry in ${secs} seconds`}
                        </span>
                    ) : (
                        <Field
                            name="phoneNumber"
                            component={ValidatedPhoneNumber}
                            label="Phone Number"
                            className={styles.phoneNumber}
                        />
                    )}
                </div>
            </Card.Body>
            <Card.Footer
                style={{
                    padding: '35px 0px 0px',
                    border: 'none',
                    background: 'none',
                }}
            >
                <Button
                    className={styles.passportFooterBtn}
                    block
                    disabled={success || isSubmitting}
                    variant={setButtonVariant(props.theme?.button.variant)}
                    type="submit"
                >
                    Send Link
                </Button>
                {!props.isReauthenticate
                    ? props.verificationOption === 'OR' && (
                          <Button
                              onClick={() =>
                                  props.history.push({
                                      pathname: '/verification',
                                      state: { noDevice: true },
                                  })
                              }
                              block
                              variant="link"
                          >
                              Don't have a valid ID or passport and a device?
                          </Button>
                      )
                    : null}
            </Card.Footer>
        </Form>
    );
};

Message.propTypes = {
    communication: PropTypes.object,
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    verificationOption: PropTypes.string,
    theme: PropTypes.object,
    history: PropTypes.object,
    isReauthenticate: PropTypes.bool,
};

export default Message;
