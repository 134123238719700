import React, { Fragment, useState } from 'react';
import UserDetails from '../VerificationOptions/UserDetails';
import VerificationQuestions from '../VerificationQuestions';

function VerificationQuizView({
    profile,
    kba,
    confirmUserInformationForAdditionalChecks,
    confirmUserInformation,
    ...props
}) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onUserDetailsSuccess = async () => {
        setShow(false);
        props.history.goBack();
    };

    return (
        <Fragment>
            <UserDetails
                {...props}
                show={show}
                confirmUserInformationForAdditionalChecks={
                    confirmUserInformationForAdditionalChecks
                }
                confirmUserInformation={confirmUserInformation}
                handleShow={handleShow}
                handleClose={handleClose}
                onSuccess={onUserDetailsSuccess}
            />
            <VerificationQuestions {...props} handleShow={handleShow} />
        </Fragment>
    );
}

export default VerificationQuizView;
