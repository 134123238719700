/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import { REAUTHENTICATE_ROUTE, uuidPattern, REAUTHENTICATE_ROUTES } from 'utils/constants';

const PrivateRoute = ({
    component: Component,
    updateSessionToken,
    session,
    onMount = false,
    reauthenticateSession,
    updateReauthenticateSessionID,
    ...rest
}) => {
    // to check if "reauthenticate" is included in route or not
    const isReAuthenticateRoute = rest?.location?.pathname?.includes(REAUTHENTICATE_ROUTE);

    // function to exact sessionUUID from reauthenticate route and store it and redirect to home route for reauthenticate
    const configureReauthenticateSession = useCallback(() => {
        let values = rest.location && queryString.parse(rest.location.search);
        const match = rest.location.pathname.match(uuidPattern);

        if (match?.length) {
            // Session start from desktop -> fetch session id from url and store in redux
            updateReauthenticateSessionID(match[0], `/${REAUTHENTICATE_ROUTE}`);
        } else if (values?.sessionUUID) {
            // Session start from desktop and then open in mobile -> fetch session id from params and directly redirect to capture selfie page
            updateReauthenticateSessionID(
                values?.sessionUUID,
                REAUTHENTICATE_ROUTES.CAPTURE_SELFIE
            );
        }
    }, []);

    useEffect(() => {
        let values = rest.location && queryString.parse(rest.location.search);
        if (_.isEmpty(values)) {
            values = session;
        }

        if (isReAuthenticateRoute) {
            configureReauthenticateSession();
        } else if (values && (values.token || values.source)) {
            updateSessionToken(values.token, rest.location.pathname, values.source);
        } else if (onMount && !session.token) {
            window.location.href = '/session-expired?session=invalid';
        }
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => {
                const renderComponent = rest.render ? rest.render(props) : <Component {...props} />;

                // if Medallion token or Reauthenticate sessionUUID available then load the component
                return onMount || session.token || reauthenticateSession ? (
                    renderComponent
                ) : (
                    <Redirect to="/session-expired?session=invalid" />
                );
            }}
        />
    );
};

PrivateRoute.propTypes = {
    updateSessionToken: PropTypes.func,
    updateReauthenticateSessionID: PropTypes.func,
    session: PropTypes.object,
    component: PropTypes.any,
    reauthenticateSession: PropTypes.string,
    onMount: PropTypes.bool,
};

export default PrivateRoute;
