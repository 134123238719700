import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classes from '../Instructions/carousal/carousel-body/carousel.module.css';
import { Slide } from '@material-ui/core';
import Loader from 'components/Skeleton/Loader';

const Item = ({ activeSlide, item, slideDirection }) => {
    const [image1Loaded, setImage1Loaded] = useState(false);
    const [image2Loaded, setImage2Loaded] = useState(false);

    const isAllImagesLoaded = () => {
        if (item?.img2) {
            return image1Loaded && image2Loaded;
        } else {
            return image1Loaded;
        }
    };

    useEffect(() => {
        setImage1Loaded(false);
        setImage2Loaded(false);
    }, [activeSlide, item]);

    return (
        <Slide
            direction={slideDirection}
            in={activeSlide === item.id}
            mountOnEnter={true}
            unmountOnExit={true}
            timeout={400}
        >
            <div
                className={
                    item.id === activeSlide
                        ? `${classes.carouselItem} ${classes.carouselItemActive}`
                        : classes.carouselItem
                }
            >
                <h3 className={classes.title}>{item.title}</h3>
                <p className={classes.description}>{item.description}</p>

                {!isAllImagesLoaded() && <Loader height={395} width={281} radius={20} />}

                <div
                    style={{ display: isAllImagesLoaded() ? 'block' : 'none' }}
                    className={classes.imageContainer}
                >
                    <img
                        src={item.img1}
                        className={classes.selfieImage}
                        alt={'Correct ID'}
                        onLoad={() => setImage1Loaded(true)}
                        role="presentation"
                        aria-hidden="true"
                    />

                    {item.img2 && (
                        <img
                            src={item.img2}
                            className={classes.selfieImage}
                            alt={'Incorrect ID'}
                            onLoad={() => setImage2Loaded(true)}
                            role="presentation"
                            aria-hidden="true"
                        />
                    )}
                </div>
            </div>
        </Slide>
    );
};

Item.propTypes = {
    item: PropTypes.object.isRequired,
    activeSlide: PropTypes.number.isRequired,
    slideDirection: PropTypes.oneOf(['left', 'right']).isRequired,
};

export default Item;
