import { REQUEST_RESULT, UPDATE_RESULT, RESET_ALL_REDUCERS } from 'actions';

export default (state = {}, action) => {
    return {
        ...root(state, action),
    };
};

const root = (state = { loading: false }, action) => {
    switch (action.type) {
        case REQUEST_RESULT:
            return { ...state, loading: true };
        case UPDATE_RESULT:
            return { ...action.data, loading: false };
        case RESET_ALL_REDUCERS:
            return { loading: false };
        default:
            return state;
    }
};
