import React from 'react';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import { getIn } from 'formik';

export default ({ field, form, label, placeholder, type, countryCode, ...props }) => {
    const dispatchForCode = function (event, callback) {
        let code;

        if (event.key !== undefined) {
            code = event.key;
        } else if (event.keyIdentifier !== undefined) {
            code = event.keyIdentifier;
        } else if (event.keyCode !== undefined) {
            code = event.keyCode;
        }

        callback(code);
    };

    const { errors, touched } = form;
    const isInvalid = !!getIn(errors, field.name) && getIn(touched, field.name);

    const fieldValue = (countryCode ? countryCode : form.values.countryCode) + field.value;

    return (
        <Form.Group controlId={field && field.name} className={props.className}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Row className="m-0">
                <PhoneInput
                    onKeyDown={(event) => {
                        dispatchForCode(event, (code) => {
                            if (code === 'Enter') {
                                form.submitForm();
                            }
                        });
                    }}
                    country="us"
                    value={fieldValue}
                    onChange={(value, country) => {
                        if (country.dialCode !== form.values.countryCode) {
                            form.setFieldValue('countryCode', country.dialCode);
                        } else {
                            const phone = value;
                            form.setFieldValue(field.name, phone.replace(country.dialCode, ''));
                        }
                    }}
                    isValid={!isInvalid}
                />
                {isInvalid && (
                    <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                        {getIn(errors, field.name)}
                    </Form.Control.Feedback>
                )}
            </Form.Row>
        </Form.Group>
    );
};
