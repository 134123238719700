import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Background from 'core-components/Background';
import styles from './FailedScreens.module.scss';
import outOfAttemptsImg from 'assets/images/out-of-attempts.svg';
import cn from 'classnames';

function IDFailed({ profile }) {
    const changeDescription = profile?.company?.uuid === '27feebcb-295c-46f5-8e6b-6cc5af742b69';

    const description = changeDescription ? (
        <>
            Thank you for your interest in Constellation. <br /> <br />
            Unfortunately, we were not able to verify your identity. We apologize for the
            inconvenience, but we are unable to complete your enrollment.
        </>
    ) : (
        'All attempts used. Please contact administrator to proceed.'
    );

    return (
        <Background className={styles.idFailedWrapper}>
            <Card.Header className={cn(styles.idFailedCardHeader, styles.cardHeader)}>
                ID authentication failed
            </Card.Header>
            <Card.Body className={styles.idFailedCardBody}>
                <img
                    src={outOfAttemptsImg}
                    alt="Out of Attempts illustration"
                    className={styles.attemptsIllustration}
                />
                <p className={styles.idFailedDesc}>{description}</p>
            </Card.Body>
        </Background>
    );
}

IDFailed.propTypes = {
    profile: PropTypes.object,
};

export default IDFailed;
