import { createPollingActions, getPollingState } from 'redux-polling'
import { getStatusAPI, setStatusAPI } from 'api/status'

/* Actions */
const pollingInterval = 5000
const historyLength = 1

async function polling() {
  const point = await getStatusAPI()
  return point
}

export const actions = createPollingActions(
  'status',
  polling,
  pollingInterval,
  historyLength,
)

/* Selectors */
const isPollingActive = (state) => getPollingState(state, 'status').isActive
const getPointHistory = (state) => getPollingState(state, 'status').history
const getLastPoint = (state) => getPollingState(state, 'status').lastEntry
const getCount = (state) => getPollingState(state, 'status').count

export const selectors = {
  isPollingActive,
  getPointHistory,
  getLastPoint,
  getCount
}

export const setStatus = (status) => () => {
  return setStatusAPI(status)
}
