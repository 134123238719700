export const TRIES_THRESHOLD = 2;
export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';
export const ignoreMessages = ['Manual Review was accessed', 'The expiration dates match'];
export const eventNames = {
    completed: 'Verification Completed',
    options: 'Verification Option Viewed',
    captureId: 'Id Captured',
    captureSelfie: 'Selfie Captured',
    confirmUserInfo: 'User Info Confirmed',
    kba: 'KBA Completed',
    manualReview: 'Manual Review Completed',
    IdFailed: 'ID Failed',
    KbaFailed: 'KBA Failed',
    userVerificationStatus: 'User Verification Status',
};

export const emptyEmployerFormValues = {
    countryCode: '1',
    companyName: '',
    houseNumber: '',
    street: '',
    city: '',
    zipCode: '',
    state: '',
    country: 'US',
    employerPhoneNumber: '',
    start: '',
    end: '',
    jobTitle: '',
    jobType: '',
    reason: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    isCurrent: false,
};

export const emptyEductionFormValues = {
    schoolName: '',
    campusName: '',
    city: '',
    zipCode: '',
    state: '',
    country: 'US',
    start: '',
    end: '',
    degree: '',
    schoolType: '',
    status: '',
    majors: [],
    minors: [],
    isCurrent: false,
};

export const emptyLicenseFormValues = {
    organizationName: '',
    state: '',
    country: 'US',
    title: '',
    certificationNumber: '',
    start: '',
    end: '',
    isCurrent: false,
};

export const eductionFromOptions = {
    attending: [
        {
            label: 'Currently Attending',
            value: 'currentlyAttending',
        },
    ],

    completed: [
        {
            label: 'Completed Successfully',
            value: 'completedSuccessfully',
        },
    ],
};

export const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const VERIFICATION_DESCRIPTION = {
    SINGLE: 'You will need to verify your identity using the method shown below',
    AND: (
        <>
            You will need to verify your identity using <strong>all</strong> methods shown below
        </>
    ),
    OR: (
        <>
            You will need to verify your Identity using <strong>any</strong> method shown below
        </>
    ),
};

export const identityProofKey = {
    numQuestions: 'Number of Questions',
    score: 'Quiz Score',
    status: 'Verification Status',
};

// ID verification Result component objects 👇

export const govtIdObject = [
    { name: 'scannedUser', display: 'Photo ID' },
    { name: 'passport', display: 'Passport Data' },
];

export const removedKey = [
    'web_response_code',
    'id_front',
    'id_back',
    'face_image',
    'signature_image',
    'selfie_image',
];

export const booleanKey = ['is_barcode_read', 'is_ocr_read'];

export const replacedKey = {
    dob: 'Date of Birth',
    firstname: ' First Name',
    middlename: 'Middle Name',
    lastname: 'Last Name',
    streetName: 'Street Name',
    mrz: 'Machine Readable Zone(MRZ)',
    id: 'ID',
    idCountry: 'ID Country',
    is_barcode_read: 'is Barcode Read?',
    is_ocr_read: 'Is it OCR Readable?',
};

export const replacedNestedKey = {
    score: 'Liveness Score',
};

export const notInStartCase = [
    'DateOfBirth',
    'DateOfBirth4',
    'ExpirationDate',
    'ExpirationDate4',
    'IssueDate',
    'IssueDate4',
    'license',
    'id_issued_date',
    'id_expiration_date',
    'dob',
];

// ID verification Result component objects 👆

// Finicity app popup configuration
export const finicityConnectOptions = {
    overlay: 'rgba(199, 201, 199, 0.8)',
};

// Reauthenticate Route
export const REAUTHENTICATE_ROUTE = 'reauthenticate';

export const REAUTHENTICATE_ROUTES = {
    CAPTURE_SELFIE: `/${REAUTHENTICATE_ROUTE}/capture/selfie`,
    SELFIE_FAILURE: `/${REAUTHENTICATE_ROUTE}/selfie/failure`,
    VERIFY_CODE: `/${REAUTHENTICATE_ROUTE}/verify/code`,
    VERIFY_CODE_FAILURE: `/${REAUTHENTICATE_ROUTE}/code/failure`,
    RESULT: `/${REAUTHENTICATE_ROUTE}/result`,
    SELFIE_TIMEOUT: `/${REAUTHENTICATE_ROUTE}/selfie/timeout`,
};

// regex to get UUID
export const uuidPattern =
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

export const VERIFICATION_FLOWS = {
    NORMAL: 'Normal',
    REAUTHENTICATE: 'Reauthenticate',
};

export const REAUTHENTICATE_STATUS = {
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
};

export const REAUTHENTICATE_TOASTER_MESSAGES = {
    SELFIE_SUCCESS: 'Selfie matched successfully',
    CODE_SENT: 'Code resent',
};

const VERIFICATION_SOURCE = {
    MEDALLION: 'Medallion',
};

const VERIFICATION_METHODS = {
    ID: 'ID',
    PASSPORT: 'Passport',
    KBA: 'Knowledge based authentication',
};

const VERIFICATION_STATUS = {
    INITIATED: 'initiated',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
};

export const USER_VERIFICATION = {
    SOURCE: VERIFICATION_SOURCE,
    METHODS: VERIFICATION_METHODS,
    STATUS: VERIFICATION_STATUS,
};

export const ID_AUTHENTICATION_TYPES = {
    UNKNOWN: 'Unknown',
    PASSED: 'Passed',
    FAILED: 'Failed',
    SKIPPED: 'Skipped',
    CAUTION: 'Caution',
    ATTENTION: 'Attention',
};

export const COMPANY_ACCESS_CODES = {
    CONSTELLATION: '27feebcb-295c-46f5-8e6b-6cc5af742b69',
    NEWLINE_AI: 'de3cb00c-e32b-4df7-94cb-f22bd9cf329d',
};

export const AUTHENTICATE_CONTACT = {
    NAME: 'Authenticate.com',
};

export const DOCUMENT_ERROR_MESSAGES = {
    EXPIRED: 'The document has expired',
    EXPIRE_DATE_MISMATCH: 'The expiration dates do not match',
};

export const MESSAGES = {
    IS_BLURRY: 'Image appears blurry. Please retry.',
    HAS_GLARE: 'Image has glare. Please retry.',
    TOO_FAR:
        'The image you submitted is too far away from the camera. Move closer, but also make sure all four corners can still be seen.',
    DEFAULT: 'Please ensure that all text is visible.',
};

export const MEDALLION_LINK_ERR_MSG = {
    EXPIRED: 'JWT is either invalid or expired. Please initiate the verification process again.',
    INVALID:
        'This Authorization Key is either invalid or has not been activated yet. Please contact an administrator to activate this Authorization Key.',
};

export const INSTRUCTION_TYPES = {
    ID: 'id',
    SELFIE: 'selfie',
};
