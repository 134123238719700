import http from './http';

export const sendMessageAPI = (data) => {
    return http.post('/verification/self/request/phone', data);
};

export const sendEmailAPI = (data) => {
    return http.post('/verification/self/request/email', data);
};

export async function saveSelfieAndLiveness({
    documentType,
    liveness = {
        livenessScoreResult: { score: 0, livenessAssessment: null },
        error: null,
        errorCode: null,
        transactionId: null,
    },
    faceMatch = 0,
    selfieImage = null,
}) {
    return http.post('/verification/self/liveness', {
        documentType,
        liveness,
        faceMatch,
        selfieImage,
    });
}
