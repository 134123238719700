import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'utils/utilities';
import {
    updateUserProfile,
    getTitle,
    professionalLicenseVerification,
    getProfile,
} from 'actions/profile';
import { mergeWith } from 'lodash';
import validationSchema from './ProfessionalLicense.validation';
import { formValuesToAPI } from './ProfessionalLicense.mapper';
import { emptyLicenseFormValues } from 'utils/constants';

const initialValues = () => {
    const localForm = sessionStorage.getItem('professionalLicense');
    const localFormValues = JSON.parse(localForm);

    return {
        professionLisences: localFormValues?.length ? localFormValues : [emptyLicenseFormValues],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserProfile: (data) => dispatch(updateUserProfile(data)),
        getTitle: (userAccessCode, state, value) =>
            dispatch(getTitle(userAccessCode, state, value)),
        professionalLicenseVerification: (userAccessCode, value) =>
            dispatch(professionalLicenseVerification(userAccessCode, value)),
        getProfile: () => dispatch(getProfile()),
    };
};

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        session: state.session,
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: (props) =>
            mergeWith({}, initialValues(), props.user, (a, b) => (b === null ? a : b)),
        enableReinitialize: true,
        validationSchema,
        handleSubmit: (values, { setStatus, setSubmitting, props }) => {
            setStatus(true);
            props
                .professionalLicenseVerification(
                    props.profile.company.user.userDetails.uuid,
                    formValuesToAPI(values)
                )
                .then((result) => {
                    setSubmitting(false);
                    setStatus(false);
                    sessionStorage.removeItem('professionalLicense');
                    props.getProfile();
                    props.renderNextStep();
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error));
                    setStatus(false);
                    setSubmitting(false);
                });
        },
        displayName: 'UserInfoForm',
    })
);
