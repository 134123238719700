import * as Yup from 'yup';

function equalTo(ref, msg) {
    return Yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg || 'Path must be the same as Reference',
        params: {
            reference: ref.path,
        },
        test: function (value) {
            return value === this.resolve(ref);
        },
    });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export default Yup.object().shape({
    professionLisences: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required('Required').nullable(),
            organizationName: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            country: Yup.string().required('Required'),
            start: Yup.string().required('Required'),
            isCurrent: Yup.boolean(),
            end: Yup.string().when('isCurrent', {
                is: false,
                then: Yup.string().required('Required'),
            }),
            certificationNumber: Yup.string().required('Required'),
        })
    ),
});
