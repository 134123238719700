export const schoolTypes = [
  { label: 'University', value: 'university' },
  { label: 'College', value: 'college' },
  { label: 'Community College', value: 'community college' },
  { label: 'Junior College', value: 'junior college' },
  {
    label: 'Technical, Vocational or Trade School',
    value: 'technical, vocational or trade school'
  }
];
