import {
    UPDATE_REAUTHENTICATE_SESSION_UUID,
    RESET_ALL_REDUCERS,
    UPDATE_SOURCE,
    REQUEST_REAUTHENTICATE_SESSION_STATUS,
    UPDATE_REAUTHENTICATE_SESSION_STATUS,
    UPDATE_REAUTHENTICATE_SESSION_STATUS_ERROR,
    REQUEST_REAUTHENTICATE_UPDATE_STATUS,
    UPDATE_REAUTHENTICATE_UPDATE_STATUS,
    UPDATE_REAUTHENTICATE_UPDATE_STATUS_ERROR,
    UPDATE_REAUTHENTICATE_ADDITIONAL_VERIFICATION,
    UPDATE_SESSION_DEVICE_INFO,
    UPDATE_SESSION_DEVICE_INFO_ERROR,
    REQUEST_SESSION_DEVICE_INFO,
    HIDE_CODE_RESEND_LINK,
} from 'actions';
import {
    reauthenticateStatusAPI,
    updateReauthenticateStatusAPI,
    getDeviceInfoAPI,
} from 'api/reAuthenticate';
import { replace } from 'connected-react-router';
import apiDispatcher from './apiDispatcher';
import { createPollingActions, getPollingState } from 'redux-polling';

export const reAuthenticateSessionUpdate = (sessionID) => async (dispatch) => {
    const result = await apiDispatcher({
        axiosMethod: () => reauthenticateStatusAPI(sessionID),
        action: {
            inProgressType: REQUEST_REAUTHENTICATE_SESSION_STATUS,
            successType: UPDATE_REAUTHENTICATE_SESSION_STATUS,
            failureType: UPDATE_REAUTHENTICATE_SESSION_STATUS_ERROR,
        },
    })(dispatch);

    if (result.isErr()) {
        dispatch(replace('/session-expired?session=expired'));
    }
};

export const updateReauthenticateStatus = (data, sessionID) => async (dispatch) => {
    await apiDispatcher({
        axiosMethod: () => updateReauthenticateStatusAPI(data, sessionID),
        action: {
            inProgressType: REQUEST_REAUTHENTICATE_UPDATE_STATUS,
            successType: UPDATE_REAUTHENTICATE_UPDATE_STATUS,
            failureType: UPDATE_REAUTHENTICATE_UPDATE_STATUS_ERROR,
        },
    })(dispatch);
};

export const updateReauthenticateSessionID = (sessionID, redirect, source) => (dispatch) => {
    dispatch({
        type: RESET_ALL_REDUCERS,
    });

    dispatch({
        type: UPDATE_REAUTHENTICATE_SESSION_UUID,
        data: sessionID,
    });
    source &&
        dispatch({
            type: UPDATE_SOURCE,
            data: {
                source,
            },
        });
    sessionID && dispatch(reAuthenticateSessionUpdate(sessionID));
    dispatch(replace(redirect));
};

export const updateReauthenticateAdditionalVerification = (data) => {
    return {
        type: UPDATE_REAUTHENTICATE_ADDITIONAL_VERIFICATION,
        payload: data,
    };
};

export const getDeviceInfoAction = (sessionID) => async (dispatch) => {
    await apiDispatcher({
        axiosMethod: () => getDeviceInfoAPI(sessionID),
        action: {
            inProgressType: REQUEST_SESSION_DEVICE_INFO,
            successType: UPDATE_SESSION_DEVICE_INFO,
            failureType: UPDATE_SESSION_DEVICE_INFO_ERROR,
        },
    })(dispatch);
};

// VerifyEmailPhone.jsx -> action to hide email/phone code resend Link after code is send.
export const setHideCodeResendLink = (data) => {
    return {
        type: HIDE_CODE_RESEND_LINK,
        payload: data,
    };
};

// Polling of reauthenticate session status API 👇

export const getReauthenticateSessionStatus = (sessionID) => (dispatch) => {
    return reauthenticateStatusAPI(sessionID).then(() => {
        dispatch(actions.reset());
        dispatch(actions.start(sessionID));
    });
};

// Actions

const pollingInterval = 5000;
const historyLength = 1;

export const actions = createPollingActions(
    'reauthenticate-status',
    (sessionID) => reauthenticateStatusAPI(sessionID),
    pollingInterval,
    historyLength
);

// Selectors

const isPollingActive = (state) => getPollingState(state, 'reauthenticate-status').isActive;
const getPointHistory = (state) => getPollingState(state, 'reauthenticate-status').history;
const getLastPoint = (state) => getPollingState(state, 'reauthenticate-status').lastEntry;
const getCount = (state) => getPollingState(state, 'reauthenticate-status').count;

export const selectors = {
    isPollingActive,
    getPointHistory,
    getLastPoint,
    getCount,
};
