import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export const formValuesToAPI = (formValues) => {
    return formValues.employers.map((employer) => {
        return {
            employerName: employer.companyName,
            employerPhone: employer.employerPhoneNumber,
            employerAddress: `${employer.houseNumber},${employer.street}`,
            employerAddressTown: employer.city,
            employerAddressCountry: employer.country,
            employerAddressStateProvince: employer.state,
            employerAddressPostalCode: employer.zipCode,
            jobTitle: employer.jobTitle,
            dateEmployedFrom: moment(employer.start).format('DD-MM-YYYY'),
            dateEmployedTo: employer.isCurrent
                ? moment(new Date()).format('DD-MM-YYYY')
                : moment(employer.end).format('DD-MM-YYYY'),
            supervisorName: employer.contactName,
            supervisorContactInfo: employer.contactNumber,
            currentEmployment: 1,
            contractType: employer.jobType,
            manualVerification: false,
            uniqueIdentifier: uuidv4(),
        };
    });
};

export const reduxToFormValues = (reduxValues) => {
    return {
        ...reduxValues,
        dob: moment(reduxValues.dateOfBirth, 'YYYY-MM-DD'),
        ssn: '',
    };
};
