import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';
import Image from 'core-components/Image';
import { setButtonVariant } from 'utils/utilities';
import Button from 'core-components/Button';
import { ReactComponent as CheckCircle } from 'images/filled-check-circle.svg';

const AllAttemptsUsed = ({
    title,
    titleAlignment = 'left',
    description,
    showBtn,
    btnText,
    onClick,
    variant,
    isVerificationDone = false,
}) => {
    return (
        <>
            <h1 className={styles.header} style={{ textAlign: titleAlignment }}>
                {title}
            </h1>
            <div className={styles.body}>
                {isVerificationDone ? (
                    <div className={styles.allDoneWrapper}>
                        <CheckCircle className={styles.checkIcon} />
                        <p className={styles.allDoneText}>You’re all done!</p>
                    </div>
                ) : (
                    <Image
                        className={styles.image}
                        name="out-of-attempts.svg"
                        svg="true"
                        alt="out-of-attempts.svg"
                    />
                )}
                <p className={styles.description}>{description}</p>
                {showBtn && (
                    <Button
                        block
                        variant={setButtonVariant(variant)}
                        className={styles.btn}
                        onClick={onClick}
                    >
                        {btnText}
                    </Button>
                )}
            </div>
        </>
    );
};

AllAttemptsUsed.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    showBtn: PropTypes.bool,
    btnText: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    titleAlignment: PropTypes.string,
    isVerificationDone: PropTypes.bool,
};

export default AllAttemptsUsed;
