import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export const formValuesToAPI = (formValues) => {
    return formValues.educationInfo.map((educationInfo) => {
        return {
            schoolName: educationInfo.schoolName,
            schoolType: educationInfo.schoolType,
            institutionCampusName: educationInfo.campusName,
            institutionAddressTown: educationInfo.city,
            institutionAddressCountry: educationInfo.country,
            institutionAddressStateProvince: educationInfo.state,
            institutionAddressPostalCode: educationInfo.zipCode,
            startDate: moment(educationInfo.start).format('DD-MM-YYYY'),
            endDate: educationInfo.isCurrent
                ? moment(new Date()).format('DD-MM-YYYY')
                : moment(educationInfo.end).format('DD-MM-YYYY'),
            degreeTitle: educationInfo.degree,
            currentlyAttending: educationInfo.status === 'currentlyAttending' ? '1' : '0',
            completedSuccessfully: educationInfo.status === 'completedSuccessfully' ? '1' : '0',
            majors: educationInfo.majors,
            minors: educationInfo.minors,
            manualVerification: false,
            uniqueIdentifier: uuidv4(),
        };
    });
};

export const reduxToFormValues = (reduxValues) => {
    return {
        ...reduxValues,
        dob: moment(reduxValues.dateOfBirth, 'YYYY-MM-DD'),
        ssn: '',
    };
};
