import React from 'react';
import { ReactComponent as Info } from 'assets/images/Info.svg';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyle = makeStyles(() => ({
    errorMessage: {
        width: '100%',
        marginTop: '0.25rem',
        fontSize: '80%',
        color: '#dc3545',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    },
    infoIcon: {
        height: '16px',
        width: '16px',
    },
}));

const ErrorWithInfoIcon = React.forwardRef((props, ref) => {
    const classes = useStyle();

    return (
        <div className={classes.errorMessage}>
            <Info className={classes.infoIcon} />
            {props.children}
        </div>
    );
});

ErrorWithInfoIcon.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorWithInfoIcon;
