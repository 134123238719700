import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import Navbar from 'react-bootstrap/Navbar';
import styles from './Navbar.module.scss';
import { REAUTHENTICATE_ROUTE } from 'utils/constants';
// assets
import MedallionLogo from '../../images/Medallion_Logo_TM_RGB.svg';
import ReAuthenticateLogo from 'assets/images/ReAuthenticate_rgb.svg';

function MainNavbar({ profile }) {
    const location = useLocation();

    const isReAuthenticateRoute = location?.pathname?.includes(REAUTHENTICATE_ROUTE);
    const isSessionExpired = location?.pathname?.includes('/session-expired');
    const isAuthenticating = profile.company?.name === 'Authenticating.com';
    const currentLogo = isReAuthenticateRoute ? ReAuthenticateLogo : MedallionLogo;
    const isCompanyLogoAvailable = profile?.company?.logo ? profile.company.logo : currentLogo;

    // load the navbar if profile data is there or reauthenticate route
    const isLoading =
        (profile && !profile.loading && profile.company) ||
        isReAuthenticateRoute ||
        isSessionExpired;

    return isLoading ? (
        <div className={styles.wrapper}>
            <Navbar variant="dark" fixed="top" className={styles.navbar}>
                <img
                    src={isAuthenticating ? MedallionLogo : isCompanyLogoAvailable}
                    className={styles.img}
                    alt="Logo"
                />
            </Navbar>
        </div>
    ) : null;
}

MainNavbar.propTypes = {
    profile: PropTypes.object,
};

export default MainNavbar;
