/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
// components
import Dashboard from './Dashboard';
import Verification from './Verification';
import ProfessionalLicense from './Professional License';
import EmploymentInformation from './Employment Information';
import EducationInformation from './Education Information';
import ThankYou from './ThankYou';
import NotFound from '../NotFound';
import Loader from 'core-components/Loader';
import Reauthenticate from 'components/Reauthenticate';
import { REAUTHENTICATE_ROUTE } from 'utils/constants';

const ROUTE_MAP = {
    DEFAULT: ['/', '/verification', '/thank-you'],
};

const Home = ({
    match,
    profile,
    setVerificationConfiguration,
    reAuthenticateSessionID,
    ...props
}) => {
    const [currentStep, setCurrentStep] = React.useState(0);
    let ROUTES = ROUTE_MAP.DEFAULT;

    const consentEntry = profile?.company?.user?.consentEntry;

    const isConsentAvailable =
        consentEntry &&
        consentEntry?.backgroundCheckDisclosureAccepted === 1 &&
        consentEntry?.glbPurposeAndDppaPurpose === 1 &&
        consentEntry?.fcraPurpose === 1;

    const redirectFromHomePage = () => {
        props.history.push(profile?.defaultRoute[currentStep + 1]);
    };

    // functionality for getting verification configuration 👇

    const idVerification = profile?.workflow?.idVerifications;
    const enabledVerifications = [];
    const mandatoryVerifications = [];

    for (const key in idVerification) {
        const check = idVerification[key];
        if (check.enabled) {
            enabledVerifications.push(check.verification_type);
            if (check.mandatory) {
                mandatoryVerifications.push(check.verification_type);
            }
        }
    }

    const allOrAnyMethod =
        mandatoryVerifications.length === enabledVerifications.length ? 'AND' : 'OR';

    const verificationConfiguration = enabledVerifications.length === 1 ? 'SINGLE' : allOrAnyMethod;

    React.useEffect(() => {
        verificationConfiguration && setVerificationConfiguration(verificationConfiguration);
    }, [verificationConfiguration, setVerificationConfiguration, idVerification]);

    // functionality for getting verification configuration 👆

    const isReauthenticateSession = reAuthenticateSessionID?.length;

    React.useEffect(() => {
        const homeRoute = props.location.pathname === '/';

        if (isConsentAvailable && homeRoute) {
            redirectFromHomePage();
        }

        return () => homeRoute && setCurrentStep(0);
    }, [isConsentAvailable, redirectFromHomePage, props.location.pathname]);

    React.useEffect(() => {
        const index = ROUTES.indexOf(props.location.pathname);
        const bypass = queryString.parse(props.location.search).bypass;
        index !== currentStep &&
            !bypass &&
            !isMobile &&
            !isConsentAvailable &&
            !isReauthenticateSession &&
            props.history.replace('/');
    }, []);

    const renderNextStep = (replace = false) => {
        let step = profile?.defaultRoute?.indexOf(props.location.pathname);
        setCurrentStep(step + 1);
        replace
            ? props.history.replace(profile?.defaultRoute[step + 1])
            : props.history.push(profile?.defaultRoute[step + 1]);
    };

    const renderLastStep = (replace = false) => {
        let step = profile?.defaultRoute?.length;
        if (step) {
            setCurrentStep(step - 1);
            replace
                ? props.history.replace(profile?.defaultRoute[step - 1])
                : props.history.push(profile?.defaultRoute[step - 1]);
        }
    };

    const extendedInputs = profile?.company?.user?.extendedInputs;
    const currentRouteNumber = profile?.defaultRoute?.indexOf(props?.location?.pathname);
    const verificationDetails = profile?.company?.user?.verificationDetails;

    const getTotalStepValues = () => {
        let total = 1;
        for (const key in extendedInputs) {
            if (extendedInputs.hasOwnProperty(key) && extendedInputs[key] === true) {
                total++;
            }
        }
        return total;
    };

    const verificationStep = `Verification ${currentRouteNumber ?? 0} of ${getTotalStepValues()}`;

    const isLastPoint =
        extendedInputs?.education === verificationDetails?.isEductionRecorded &&
        extendedInputs?.employment === verificationDetails?.isEmploymentRecorded &&
        extendedInputs?.professionalLicense === verificationDetails?.isProfessionalLicenseRecorded;

    const isEductionRecorded = profile?.company?.user?.verificationDetails?.isEductionRecorded;
    const isEmploymentRecorded = profile?.company?.user?.verificationDetails?.isEmploymentRecorded;
    const isProfessionalLicenseRecorded =
        profile?.company?.user?.verificationDetails?.isProfessionalLicenseRecorded;

    const eepFormRouteObject = {
        '/education': isEductionRecorded,
        '/employment': isEmploymentRecorded,
        '/professional-license': isProfessionalLicenseRecorded,
    };

    const isPreviousFormSubmitted = (data) => {
        const previousFormIndex = profile?.defaultRoute?.indexOf(data) - 1;
        const formPathName = profile?.defaultRoute[previousFormIndex];

        if (formPathName in eepFormRouteObject) {
            return eepFormRouteObject[formPathName];
        } else {
            return false;
        }
    };

    if (!profile) {
        return <Loader />;
    }

    return (
        <Switch>
            <Route
                exact
                path={`${match.path}`}
                render={(props) => (
                    <Dashboard
                        {...props}
                        renderNextStep={renderNextStep}
                        isLastPoint={isLastPoint}
                    />
                )}
                {...props}
            />

            <Route
                path={`${match.path}verification`}
                render={(props) => (
                    <Verification
                        {...props}
                        renderNextStep={renderNextStep}
                        renderLastStep={renderLastStep}
                        verificationStep={verificationStep}
                        isLastPoint={isLastPoint}
                    />
                )}
                {...props}
            />

            {profile?.company?.user?.extendedInputs?.employment && (
                <Route
                    path={`${match.path}employment`}
                    render={(props) => (
                        <EmploymentInformation
                            {...props}
                            renderNextStep={renderNextStep}
                            renderLastStep={renderLastStep}
                            verificationStep={verificationStep}
                            isLastPoint={isLastPoint}
                            isPreviousFormSubmitted={isPreviousFormSubmitted}
                        />
                    )}
                    {...props}
                />
            )}
            {profile?.company?.user?.extendedInputs?.education && (
                <Route
                    path={`${match.path}education`}
                    render={(props) => (
                        <EducationInformation
                            {...props}
                            renderNextStep={renderNextStep}
                            renderLastStep={renderLastStep}
                            verificationStep={verificationStep}
                            isLastPoint={isLastPoint}
                            isPreviousFormSubmitted={isPreviousFormSubmitted}
                        />
                    )}
                    {...props}
                />
            )}
            {profile?.company?.user?.extendedInputs?.professionalLicense && (
                <Route
                    path={`${match.path}professional-license`}
                    render={(props) => (
                        <ProfessionalLicense
                            {...props}
                            renderNextStep={renderNextStep}
                            renderLastStep={renderLastStep}
                            verificationStep={verificationStep}
                            isLastPoint={isLastPoint}
                            isPreviousFormSubmitted={isPreviousFormSubmitted}
                        />
                    )}
                    {...props}
                />
            )}
            <Route exact path={`${match.path}thank-you`} component={ThankYou} {...props} />
            {/* Pure Reauthenticate flow */}
            <Route
                path={`${match.path}${REAUTHENTICATE_ROUTE}`}
                render={(props) => <Reauthenticate {...props} />}
                {...props}
            />
            <Route path="*" component={NotFound} />
        </Switch>
    );
};

Home.propTypes = {
    match: PropTypes.object,
    profile: PropTypes.object,
    setVerificationConfiguration: PropTypes.func,
    reAuthenticateSessionID: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
};

export default Home;
