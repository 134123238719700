import React from 'react';
import styles from './Footer.module.scss';
import moment from 'moment/moment';
import AuthenticateLogo from 'assets/images/authenticate_logo_light.svg';

const Footer = (props) => {
    const currentYear = moment().year();

    return (
        <footer className={styles.root}>
            <div className={styles.innerContainer}>
                <div className={styles.leftSection}>
                    <a href="https://authenticating.com/" target="_blank" rel="noreferrer">
                        <img alt="logo" className={styles.logo} src={AuthenticateLogo} />
                    </a>
                    <p className={styles.footerText}>
                        {`Copyright © ${currentYear} Authenticating.com LLC. All rights reserved.`}
                    </p>
                </div>
                <div className={styles.rightSection}>
                    <a
                        href="https://authenticating.com/terms-of-use.html"
                        className={styles.footerLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms of Use
                    </a>

                    <a
                        href="https://authenticating.com/privacy-policy.html"
                        className={styles.footerLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                </div>
            </div>
            <hr className={styles.divider} />
            <p className={styles.disclaimerText}>
                Disclaimer: Authenticating.com LLC d/b/a Authenticate, is not a consumer reporting
                agency & its service does not constitute "consumer reporting" as such term is
                defined in the federal Fair Credit Reporting Act (FCRA), 15 U.S.C.A. sec 1681 et
                seq. The data provided from this service may not be used as a factor in establishing
                a consumer's eligibility for credit, insurance, employment or for any other purpose
                in the FCRA & you agree not to use this service for such a purpose or to take
                adverse action regarding any consumer application based on this service.
            </p>
        </footer>
    );
};

export default Footer;
