import {
    UPDATE_TOKEN,
    RESET_TOKEN,
    UPDATE_SOURCE,
    REMOVE_LAST_IMAGE,
    SESSION_USER_SAVED,
    RESET_ALL_REDUCERS,
} from '.';
import { replace } from 'connected-react-router';
import { getProfile } from './profile';
import {
    createTokenAPI,
    twoFactorAuthAPI,
    verifyTwoFactorAuthAPI,
    verifyCaptchaAPI,
} from 'api/session';

export const updateSessionToken = (token, redirect, source) => (dispatch) => {
    dispatch({
        type: RESET_ALL_REDUCERS,
    });

    dispatch({
        type: UPDATE_TOKEN,
        data: {
            token,
        },
    });
    source &&
        dispatch({
            type: UPDATE_SOURCE,
            data: {
                source,
            },
        });
    dispatch(getProfile());
    dispatch(replace(redirect));
};

export const resetSessionToken = () => (dispatch) => {
    dispatch({
        type: RESET_TOKEN,
    });
};

export const createToken = (data) => (dispatch) => {
    return createTokenAPI({
        ...data,
        authToken: process.env.REACT_APP_COMPANY_LOW_CODE_SDK_KEY,
    });
};

export const twoFactorAuth = (token) => () => {
    return twoFactorAuthAPI(token);
};

export const verifyTwoFactorAuth = (code, token) => () => {
    return verifyTwoFactorAuthAPI(code, token);
};

export const verifyCaptcha = (captchaValue) => () => {
    return verifyCaptchaAPI({ response: captchaValue });
};

export const removeLastImage = () => (dispatch) => {
    dispatch({
        type: REMOVE_LAST_IMAGE,
    });
};

export const sessionUserProfileSaved = () => ({ type: SESSION_USER_SAVED });
