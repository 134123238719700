import React from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from '../Dropdown';
import { ErrorMessage } from 'formik';
import ErrorWithInfoIcon from '../Error/ErrorWithInfoIcon';
import PropTypes from 'prop-types';

const ValidatedDropdown = ({ field, form, label, error, type, ...props }) => {
    return (
        <Form.Group controlId={field.name} className={props.className}>
            {label && <Form.Label>{label}</Form.Label>}
            <Dropdown {...field} {...props} isInvalid={form && !!form.errors[field.name]} />
            <ErrorMessage name={field.name} component={ErrorWithInfoIcon} />
        </Form.Group>
    );
};

ValidatedDropdown.propTypes = {
    field: PropTypes.object,
    form: PropTypes.object,
    label: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string,
};

export default ValidatedDropdown;
