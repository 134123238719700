import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'utils/utilities';
import {
    updateUserProfile,
    getDegree,
    getSchool,
    educationVerification,
    getProfile,
} from 'actions/profile';
import { mergeWith } from 'lodash';
import validationSchema from './EducationInformation.validation';
import { formValuesToAPI } from './EducationInformation.mapper';
import { emptyEductionFormValues } from 'utils/constants';

const initialValues = () => {
    const localForm = sessionStorage.getItem('educationForm');
    const localFormValues = JSON.parse(localForm);

    return { educationInfo: localFormValues?.length ? localFormValues : [emptyEductionFormValues] };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserProfile: (data) => dispatch(updateUserProfile(data)),
        getDegree: (userAccessCode, value) => dispatch(getDegree(userAccessCode, value)),
        getSchool: (userAccessCode, value) => dispatch(getSchool(userAccessCode, value)),
        educationVerification: (userAccessCode, value) =>
            dispatch(educationVerification(userAccessCode, value)),
        getProfile: () => dispatch(getProfile()),
    };
};

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        session: state.session,
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: (props) =>
            mergeWith({}, initialValues(), props.user, (a, b) => (b === null ? a : b)),
        enableReinitialize: true,
        validationSchema,
        handleSubmit: (values, { setStatus, setSubmitting, props }) => {
            setStatus(true);
            props
                .educationVerification(
                    props.profile.company.user.userDetails.uuid,
                    formValuesToAPI(values)
                )
                .then((result) => {
                    setSubmitting(false);
                    setStatus(false);
                    sessionStorage.removeItem('educationForm');
                    props.getProfile();
                    props.renderNextStep();
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error));
                    setStatus(false);
                    setSubmitting(false);
                });
        },
        displayName: 'UserInfoForm',
    })
);
