/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import Background from 'core-components/Background';
import Card from 'react-bootstrap/Card';
import styles from './VerificationOptions.module.scss';
import Loader from 'core-components/Loader';
import CardDeck from 'react-bootstrap/CardDeck';
import Alert from 'react-bootstrap/Alert';
import Image from 'core-components/Image';
import cn from 'classnames';
import UserDetails from './UserDetails';
import { trackEvent, trackEventDuration } from 'api/productAnalytics';
import {
    eventNames,
    finicityConnectOptions,
    USER_VERIFICATION,
    VERIFICATION_DESCRIPTION,
} from 'utils/constants';
import VerificationCard from './components/VerificationCard';
import { FinicityConnect } from '@finicity/connect-web-sdk';
import { getBankConnectUrlAPI } from 'api/bank';
import { toast } from 'react-toastify';
import {
    getErrorMessage,
    handleResultScreenRedirect,
    isVerificationCompleted,
} from 'utils/utilities';
import { datadogRum } from '@datadog/browser-rum';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const ROUTES = ['/verification/links', '/verification/kba'];

const VerificationOptions = ({
    profile,
    confirmUserInformation,
    confirmUserInformationForAdditionalChecks,
    KbaError,
    userInfo,
    common,
    verifications,
    verificationStep,
    isLastPoint,
    location,
    history,
    renderLastStep,
    renderNextStep,
    getProfile,
    setStatus,
    identity,
    resetKBA,
    resetAttempt,
    verificationConfiguration,
    getFincityResult,
    stopPolling,
    isPollingActive,
    lastPoint,
    count,
    resetPolling,
    ...props
}) => {
    const [noDevice, setNoDevice] = React.useState(location?.state?.noDevice);
    const [isFlowComplete, setIsFlowComplete] = React.useState(undefined);
    const [show, setShow] = React.useState(confirmUserInformation);
    const [isFetching, setIsFetching] = React.useState(false);

    React.useEffect(() => {
        if (confirmUserInformation) {
            setShow(true);
        }
    }, [confirmUserInformation]);

    React.useEffect(() => {
        if (lastPoint?.status === 200 || count >= 36) {
            stopPolling();
            resetPolling();
            handleResultScreenRedirect(profile, verificationConfiguration, lastPoint, history);
            getProfile();
        }
    }, [count, lastPoint?.status, stopPolling, handleResultScreenRedirect, getProfile]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onUserDetailsSuccess = async () => {
        setShow(false);
        const redirectRoute = location.state?.redirectTo;
        if (redirectRoute === ROUTES[1]) {
            resetKBA();
        }
        await getProfile();

        if (redirectRoute) {
            history.push(redirectRoute);
        }
    };

    const tries = profile?.company?.user?.verificationDetails;
    const retryStatus = profile?.company?.user?.retryStatus;
    const isKBAVerified = tries?.isKbaVerified === 1;
    // isDocumentValid is true only when id verification successful (no authDB), false when failed or not attempted
    const isDocumentVerified = tries?.isDocumentValid;
    const isBankVerified = tries?.isBankVerified;

    useEffect(() => {
        try {
            if (profile?.company?.user && profile?.workflow) {
                // TODO decide what the behavior should be if all attempts have been used but the user
                // failed them all.
                let { isEEPEnabled, isIdentityVerificationCompleted } = isVerificationCompleted({
                    extendedInputs: profile?.company?.user?.extendedInputs,
                    kbaVerified: isKBAVerified,
                    bankVerified: isBankVerified,
                    documentVerified: isDocumentVerified,
                    workflowIdVerifications: profile?.workflow?.idVerifications,
                });
                // If they've compelted all of the verifications,
                // redirect them to the thank you page
                if (!isEEPEnabled && isIdentityVerificationCompleted) {
                    history.push('/thank-you');
                } else if (isEEPEnabled && isIdentityVerificationCompleted) {
                    setIsFlowComplete(true);
                }
            }
        } catch (error) {
            datadogRum.addError(error, {
                userUUID: profile?.company?.user?.userDetails?.uuid,
                companyUUID: profile?.company?.uuid,
                companyName: profile?.company?.name,
            });
        }
    }, [
        profile?.company?.user,
        profile?.workflow,
        isDocumentVerified,
        isKBAVerified,
        isBankVerified,
    ]);

    const getMaxAttempts = (verificationType) => {
        const kbaAttempts = verifications?.kba?.attempts || 0;
        const idAttempts = verifications?.govtId?.attempts || 0;
        const bankAttempts = verifications?.bank?.attempts || 0;

        if (!verificationType) return 2;

        switch (verificationType) {
            case 'govtId':
                return idAttempts;
            case 'KBA':
                return kbaAttempts;
            case 'bank':
                return bankAttempts;
            default:
        }
    };

    const isCardEnabled = (current, verificationType) => {
        return getAttemptLeft(current, verificationType) > 0;
    };

    const getAttemptLeft = (current, type) => {
        return getMaxAttempts(type) - current;
    };

    const triedAttempts = profile?.company?.user?.verificationDetails?.govtIdAttempts;
    const documentOnly = verificationConfiguration === 'SINGLE' && verifications?.govtId?.enabled;
    const quizOnly = verificationConfiguration === 'SINGLE' && verifications?.kba?.enabled;

    const isKbaCardEnabled =
        identity.kba.error !== '417' &&
        isCardEnabled(tries?.kbaAttempts, 'KBA') &&
        !isKBAVerified &&
        !documentOnly;

    const isDocumentCardEnabled =
        isCardEnabled(tries?.govtIdAttempts, 'govtId') &&
        !noDevice &&
        !isDocumentVerified &&
        !quizOnly;

    const isBankCardEnabled = isCardEnabled(tries?.bankAttempts, 'bank') && !isBankVerified;

    const isLastDocumentTryFailed =
        retryStatus &&
        (retryStatus.documentConfidenceRating !== null || retryStatus.passportResult !== null) &&
        !isDocumentVerified &&
        isCardEnabled(tries?.govtIdAttempts, 'govtId');

    // TODO this is probably deprecated with @George's latest update
    // const configureSingleMethod = () => {
    //     if (documentOnly) {
    //         setIsFlowComplete(isDocumentVerified);
    //     } else if (quizOnly) {
    //         setIsFlowComplete(isKBAVerified);
    //     } else {
    //         setIsFlowComplete(isBankVerified);
    //     }
    // };

    // const isAnyVerificationComplete =
    //     (verifications?.govtId?.enabled && isDocumentVerified) ||
    //     (verifications?.kba?.enabled && isKBAVerified) ||
    //     (verifications?.bank?.enabled && isBankVerified);

    // TODO this is probably deprecated with @George's latest update
    // useEffect(() => {
    //     const isMoveLast = isLastPoint;
    //     isMoveLast && isFlowComplete && renderLastStep();
    //     isFlowComplete && renderLastStep();
    //     switch (verificationConfiguration) {
    //         case 'OR':
    //             setIsFlowComplete(isAnyVerificationComplete);
    //             break;
    //         case 'AND':
    //             setIsFlowComplete(isDocumentVerified && !isKbaCardEnabled && isBankVerified);
    //             break;
    //         case 'SINGLE':
    //             configureSingleMethod();
    //             break;
    //         default:
    //     }
    // }, [verificationConfiguration, configureSingleMethod, isAnyVerificationComplete]);

    const isEEPAllowed =
        verificationConfiguration === 'AND' &&
        !isKBAVerified &&
        isDocumentVerified &&
        isBankVerified;

    useEffect(() => {
        if (isEEPAllowed) {
            return null;
        } else if (isFlowComplete && !KbaError) {
            renderNextStep();
        } else {
            !profile.loading && getProfile().then(() => setStatus('IN_PROGRESS'));
        }
    }, [isFlowComplete, isEEPAllowed, getProfile]);

    const userAccessCode = profile?.company?.user?.userDetails?.uuid;

    useEffect(() => {
        if (profile.company?.user?.userDetails) {
            if (common.documentAttempts.uuid) {
                userAccessCode !== common.documentAttempts.uuid && resetAttempt();
            }
        }
    }, []);

    const trackOptionsViewed = () => {
        if (profile.workflow) {
            const verifications = profile.workflow.idVerifications;
            delete verifications?.verificationOption;

            const options = Object.keys(verifications) || [];

            trackEvent({
                event: eventNames.options,
                uac: userAccessCode,
                otherValues: {
                    options,
                    'Link UUID': profile.company?.user?.selfVerificationUuid,
                    'Company Name': profile.company?.name,
                },
            });
        }
    };

    const trackVerificationStatus = () => {
        if (!profile?.company) return;

        trackEvent({
            event: eventNames.userVerificationStatus,
            uac: userAccessCode,
            uniqueOnly: false,
            otherValues: {
                'Verification Method': USER_VERIFICATION.METHODS.ID,
                'Company Name': profile.company.name,
                'Manual Verification': false,
                'Link UUID': profile.company?.user?.selfVerificationUuid,
                Source: USER_VERIFICATION.SOURCE.MEDALLION,
                UAC: userAccessCode,
                Status: USER_VERIFICATION.STATUS.INITIATED,
                CAC: profile.company.uuid,
            },
        });
    };

    useEffect(() => {
        if (profile.company) {
            trackEventDuration({
                event: eventNames.options,
                uac: userAccessCode,
            });
        }
    }, []);

    const attemptsText =
        verificationConfiguration === 'SINGLE'
            ? 'All attempts used. Please contact the administrator to proceed.'
            : 'All attempts used. Please use alternate method for verification.';

    const getAttemptText = (current, verificationType) => {
        const attemptText = getMaxAttempts(verificationType) > 1 ? 'attempts left' : 'attempt left';

        const leftAttempts = getAttemptLeft(current, verificationType);
        const maxAllowedAttempts = getMaxAttempts(verificationType);

        if (isCardEnabled(current, verificationType))
            return `${leftAttempts}/${maxAllowedAttempts} ${attemptText}`;

        if (leftAttempts === 0) {
            return '0 attempts left';
        }

        return attemptsText;
    };

    const getDocumentAttemptText = () => {
        if (isDocumentVerified) {
            return 'Successfully verified using photo ID';
        }

        return getAttemptText(triedAttempts, 'govtId');
    };

    const getKbaAttemptText = () => {
        if (KbaError) {
            return (
                identity.kba.message ||
                'We could not generate a knowledge based authentication quiz from the information provided. Please authenticate yourself with a photo ID.'
            );
        }

        if (isKBAVerified) {
            return 'Successfully verified using KBA';
        }

        return getAttemptText(tries.kbaAttempts, 'KBA');
    };

    const getBankAttemptText = () => {
        if (isBankVerified) {
            return 'Successfully verified using financial account';
        }

        return getAttemptText(tries.bankAttempts, 'bank');
    };

    const isKbaError = confirmUserInformation && !KbaError;

    // if you have to confirm user info, and it's not due to a KBA Error, do nothing on click, else if you don't need to confirm user info, evaluate the rest of the expressions.
    const handleIdCard = () => {
        (!isKbaError || !confirmUserInformation) &&
            isDocumentCardEnabled &&
            history.push(ROUTES[0]);

        trackOptionsViewed();
        trackVerificationStatus();
    };

    const handleKbaCard = () => {
        !confirmUserInformation && isKbaCardEnabled && history.push(ROUTES[1]);
        trackOptionsViewed();
    };

    // Fincity connect app event handlers for managing error, load and completed state
    const connectEventHandlers = {
        onDone: () => {
            FinicityConnect.destroy();
            resetPolling();
            getFincityResult(userAccessCode);
        },
        onCancel: () => {
            FinicityConnect.destroy();
        },
        onError: (event) => {
            datadogRum.addError(event);
        },
        onLoad: (event) => {
            //event when connect app loads first time
        },
    };

    const launchConnectApp = (connectUrl) => {
        FinicityConnect.launch(connectUrl, connectEventHandlers, finicityConnectOptions);
    };

    const fetchConnectUrl = () => {
        setIsFetching(true);
        getBankConnectUrlAPI(
            {
                userAccessCode: userAccessCode,
                useWebview: false,
            },
            profile?.company?.jwt
        )
            .then((response) => {
                if (response.data) {
                    launchConnectApp(response.data?.link);
                }
                setIsFetching(false);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error));
            });
    };

    const handleBankCard = (e) => {
        if (!isFetching && (!isKbaError || !confirmUserInformation) && isBankCardEnabled) {
            fetchConnectUrl();
        }
    };

    const totalIdAttempts = verifications?.govtId?.attempts;
    const isLastTry =
        isLastDocumentTryFailed &&
        triedAttempts < 2 &&
        totalIdAttempts > 1 &&
        isDocumentCardEnabled;

    const isCompanyLoading = !profile.loading && profile.company;
    const isProfileLoading = profile.loading || !profile.company || isPollingActive;

    const idVerification = profile?.workflow?.idVerifications;

    const GOVT_ID_CARD = {
        id: 0,
        type: 'GOVT_ID',
        isCompleted: !isDocumentCardEnabled || isDocumentVerified,
        enabled: idVerification?.govtId?.enabled,
        card_properties: {
            id: 'documentCard',
            handleOnClick: handleIdCard,
            getAttemptText: getDocumentAttemptText,
            handleShow: handleShow,
            isLastTry: isLastTry,
            KbaError: KbaError,
            isDocumentVerified: isDocumentVerified,
            isDocumentCardEnabled: isDocumentCardEnabled,
            confirmUserInformation: confirmUserInformation,
        },
    };

    const KBA_CARD = {
        id: 1,
        type: 'KBA',
        isCompleted: !isKbaCardEnabled || isKBAVerified,
        enabled: idVerification?.kba?.enabled,
        card_properties: {
            id: 'kbaCard',
            handleOnClick: handleKbaCard,
            getAttemptText: getKbaAttemptText,
            handleShow: handleShow,
            confirmUserInformation: confirmUserInformation,
            KbaError: KbaError,
            isKBAVerified: isKBAVerified,
            isKbaCardEnabled: isKbaCardEnabled,
        },
    };

    const BANK_CARD = {
        id: 2,
        type: 'BANK',
        isCompleted: !isBankCardEnabled || isBankVerified,
        enabled: idVerification?.bank?.enabled,
        card_properties: {
            id: 'bankCard',
            handleOnClick: handleBankCard,
            getAttemptText: getBankAttemptText,
            handleShow: handleShow,
            confirmUserInformation: confirmUserInformation,
            KbaError: KbaError,
            isBankVerified: isBankVerified,
            isBankCardEnabled: isBankCardEnabled,
        },
    };

    // Array for rendering initial cards that is enable in workflow and cards that is not verified yet
    const inCompleteCards = [
        GOVT_ID_CARD.enabled && GOVT_ID_CARD,
        KBA_CARD.enabled && KBA_CARD,
        BANK_CARD.enabled && BANK_CARD,
    ];

    // Array for storing cards that has been completed
    const completedCards = [];
    // Store the index value of completed cards
    const completedCardsIndexes = [];

    // Method to check completed card in "inCompleteCards" list and storing index of it in "completedCardsIndexes" list
    inCompleteCards.map((card, index) => card?.isCompleted && completedCardsIndexes.push(index));

    // Method to check completed card index in "inCompleteCards" list and push it to "completedCards" list
    completedCardsIndexes.forEach((card_index) => {
        completedCards.push(inCompleteCards[card_index]);
        delete inCompleteCards[card_index];
    });

    const allVerificationCards = [...inCompleteCards, ...completedCards];

    return (
        <React.Fragment>
            <UserDetails
                {...props}
                show={show}
                confirmUserInformationForAdditionalChecks={
                    confirmUserInformationForAdditionalChecks
                }
                confirmUserInformation={confirmUserInformation}
                handleShow={handleShow}
                handleClose={handleClose}
                onSuccess={onUserDetailsSuccess}
            />
            <Background className={styles.bodyWrapper} wrapper={isProfileLoading && styles.wrapper}>
                <>
                    <Card.Header className={styles.cardHeader}>
                        {(!profile.loading || !profile.company) && (
                            <Alert.Link
                                className={styles.backArrow}
                                onClick={() => window.history.back()}
                            >
                                <Image name="Back.svg" />
                            </Alert.Link>
                        )}

                        <div>
                            <Card.Title className={styles.cardHeading}>
                                Identity Verification
                            </Card.Title>
                            <Card.Text className={styles.subHeading}>{verificationStep}</Card.Text>
                        </div>
                    </Card.Header>

                    {isProfileLoading ? (
                        <div className={styles.loaderContainer}>
                            <Loader />
                        </div>
                    ) : (
                        <div className={styles.mainCardBox}>
                            {isCompanyLoading && (
                                <div className={styles.nodevice}>
                                    {VERIFICATION_DESCRIPTION[verificationConfiguration]}
                                </div>
                            )}

                            {noDevice && (
                                <div className={styles.nodevice}>
                                    <Typography>
                                        Please use either Knowledge Authentication Quiz or Financial
                                        Account Ownership <br />
                                        if you don't have a valid Government ID or a mobile device.
                                    </Typography>
                                    <br />
                                    <Alert.Link onClick={() => setNoDevice(false)}>
                                        I have a valid ID or passport and a device!
                                    </Alert.Link>
                                </div>
                            )}

                            <Card.Body className={styles.body}>
                                {isCompanyLoading && (
                                    <CardDeck
                                        className={cn(
                                            styles.cardDeck,
                                            noDevice && styles.reverseCardDeck
                                        )}
                                    >
                                        {allVerificationCards.length > 0 &&
                                            allVerificationCards
                                                .filter((card) => card)
                                                .map((card, index) => (
                                                    <Fragment key={card.id}>
                                                        {index !== 0 && (
                                                            <div>
                                                                <p className={styles.andOrText}>
                                                                    {verificationConfiguration}
                                                                </p>
                                                            </div>
                                                        )}
                                                        <VerificationCard
                                                            {...card.card_properties}
                                                        />
                                                    </Fragment>
                                                ))}
                                    </CardDeck>
                                )}
                            </Card.Body>
                        </div>
                    )}
                </>
            </Background>
        </React.Fragment>
    );
};

VerificationOptions.propTypes = {
    profile: PropTypes.object,
    confirmUserInformation: PropTypes.any,
    confirmUserInformationForAdditionalChecks: PropTypes.any,
    KbaError: PropTypes.bool,
    userInfo: PropTypes.any,
    common: PropTypes.any,
    verifications: PropTypes.object,
    verificationStep: PropTypes.string,
    isLastPoint: PropTypes.bool,
    location: PropTypes.object,
    history: PropTypes.any,
    renderLastStep: PropTypes.func,
    renderNextStep: PropTypes.func,
    getProfile: PropTypes.func,
    setStatus: PropTypes.func,
    identity: PropTypes.object,
    resetKBA: PropTypes.func,
    resetAttempt: PropTypes.func,
    verificationConfiguration: PropTypes.string,
    getFincityResult: PropTypes.func,
    stopPolling: PropTypes.func,
    isPollingActive: PropTypes.any,
    lastPoint: PropTypes.object,
    count: PropTypes.number,
    resetPolling: PropTypes.func,
    props: PropTypes.object,
    session: PropTypes.object,
};

export default VerificationOptions;
