import React from 'react';
import PropTypes from 'prop-types';

import classes from './footer.module.css';
import Dots from './dots';
import { useDispatch } from 'react-redux';
import { renderEndInstructionBtnText } from 'utils/utilities';
import {
    setInstructionType,
    setLastUsedInstructionType,
    setShowInstructions,
} from 'actions/instructions';

const Footer = ({
    activeSlide,
    isLastSlide,
    itemsLength,
    onClickNext,
    onClickSkip,
    onEndProcessClick,
    onDotClick,
    instructionType,
}) => {
    const dispatch = useDispatch();

    const handleBtnClick = () => {
        dispatch(setShowInstructions(false));
        dispatch(setInstructionType(null));
        dispatch(setLastUsedInstructionType(instructionType));
        onEndProcessClick();
    };
    return (
        <div className={classes.footer}>
            {!isLastSlide && (
                <button className={classes.footerBtn} onClick={onClickSkip}>
                    Skip
                </button>
            )}
            <Dots activeSlide={activeSlide} itemsLength={itemsLength} handleDotClick={onDotClick} />
            {!isLastSlide && (
                <button className={classes.footerBtnSolid} onClick={onClickNext}>
                    Next
                </button>
            )}
            {isLastSlide && (
                <button className={classes.footerBtnSolid} onClick={handleBtnClick}>
                    {renderEndInstructionBtnText(instructionType)}
                </button>
            )}
        </div>
    );
};

Footer.propTypes = {
    activeSlide: PropTypes.number,
    isLastSlide: PropTypes.bool,
    itemsLength: PropTypes.number,
    onClickNext: PropTypes.func,
    onClickSkip: PropTypes.func,
    onEndProcessClick: PropTypes.func,
    onDotClick: PropTypes.func,
    instructionType: PropTypes.string,
};

export default Footer;
