import React from 'react';
import Card from 'react-bootstrap/Card';
import styles from '../DeviceLinks.module.scss';
import Button from 'core-components/Button';
import { QRCodeSVG } from 'qrcode.react';

export default (props) => {
    return (
        <React.Fragment>
            <Card.Body style={{ padding: '24px 0px 0px' }} className={styles.body}>
                <h4 className={styles.PassportCardHeading}>Scan QR code</h4>
                <p className={styles.PassportCardSubheading}>
                    Point your phone's camera to this QR code and follow the link.
                </p>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <QRCodeSVG value={props.link} />
                </div>
            </Card.Body>
            {props.verificationOption === 'OR' && (
                <Card.Footer
                    style={{
                        padding: '68px 0px 0px',
                        border: 'none',
                        background: 'none',
                    }}
                    className="text-muted"
                >
                    <Button
                        // className={styles.passportFooterBtn}
                        onClick={() =>
                            props.history.push({
                                pathname: '/verification',
                                state: { noDevice: true },
                            })
                        }
                        block
                        variant="link"
                    >
                        Don't have a valid ID or passport and a device?
                    </Button>
                </Card.Footer>
            )}
        </React.Fragment>
    );
};
