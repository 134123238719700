import React from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
// components
import Background from 'core-components/Background';
import styles from './IdSessionTimeOut.module.css';
import Button from 'core-components/Button';
import { setButtonVariant } from 'utils/utilities';
// assets
import SessionTimeout from 'assets/images/session_timeout.svg';
import { connect } from 'react-redux';
import { resetSessionTimer } from 'actions/general';
import { resetConfig } from 'screens/actions/configActions';
import { resetIDProperties } from 'screens/actions/idPropertiesActions';
import { resetProcessedData } from 'screens/actions/processDataActions';
import { VERIFICATION_FLOWS, REAUTHENTICATE_ROUTES } from 'utils/constants';

function IdSessionTimeOut({
    history,
    location,
    resetSessionTimer,
    resetConfig,
    resetIDProperties,
    resetProcessedData,
    verificationFlow,
    ...props
}) {
    const isReauthenticate = verificationFlow === VERIFICATION_FLOWS.REAUTHENTICATE;

    const handleRestartSession = () => {
        resetSessionTimer();
        resetConfig();
        resetIDProperties();
        resetProcessedData();

        if (isReauthenticate) {
            history.push(REAUTHENTICATE_ROUTES.CAPTURE_SELFIE);
        } else {
            history.push('/mobile');
        }
    };

    return (
        <Background className={styles.sessionWrapper}>
            <Card.Header className={styles.cardHeader}>Session Time Out</Card.Header>
            <Card.Body className={styles.cardBody}>
                <div className={styles.messageBox}>
                    <p className={styles.displayMessage}>
                        Please restart the session to complete your verification
                    </p>
                </div>

                <div className={styles.animationContainer}>
                    <img
                        src={SessionTimeout}
                        alt="Session timeout illustration"
                        className={styles.timeoutImage}
                    />
                </div>
                <div className={styles.btnContainer}>
                    <Button
                        block
                        className={styles.nextStepBtn}
                        variant={setButtonVariant(props?.theme.button.variant)}
                        onClick={handleRestartSession}
                    >
                        Restart
                    </Button>
                </div>
            </Card.Body>
        </Background>
    );
}

IdSessionTimeOut.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    resetSessionTimer: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    resetConfig: PropTypes.func.isRequired,
    resetIDProperties: PropTypes.func.isRequired,
    resetProcessedData: PropTypes.func.isRequired,
    verificationFlow: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetSessionTimer: () => dispatch(resetSessionTimer()),
        resetConfig: () => dispatch(resetConfig()),
        resetIDProperties: () => dispatch(resetIDProperties()),
        resetProcessedData: () => dispatch(resetProcessedData()),
    };
};

export default connect(null, mapDispatchToProps)(IdSessionTimeOut);
