import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import NotFound from 'components/NotFound';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.    return { hasError: true };  }    // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        datadogRum.addError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <NotFound />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
