import { RESET_ALL_REDUCERS } from '../actions';

export function configReducer(
    state = {
        instanceID: null,
        frontSubmitted: false,
        backSubmitted: false,
    },
    action
) {
    switch (action.type) {
        case '@@acuant/SET_INSTANCE_ID':
            return {
                ...state,
                instanceID: action.data,
            };
        case '@@acuant/FRONT_ID_SUBMITTED':
            return {
                ...state,
                frontSubmitted: true,
            };
        case '@@acuant/BACK_ID_SUBMITTED':
            return {
                ...state,
                backSubmitted: true,
            };

        case RESET_ALL_REDUCERS: // does the same as the reset config reducer
        case '@@acuant/RESET_CONFIG':
            return {
                ...state,
                instanceID: null,
                frontSubmitted: false,
                backSubmitted: false,
            };
        default:
            return state;
    }
}
