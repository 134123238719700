import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import { analytics } from 'config';
import { eventNames, ID_AUTHENTICATION_TYPES, INSTRUCTION_TYPES } from 'utils/constants';
import { isArray } from 'lodash';

if (analytics.PRODUCT.ENABLED)
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, { debug: false });

export const trackEvent = ({ event, uac, uniqueOnly = true, otherValues = {} }) => {
    if (!analytics.PRODUCT.ENABLED) {
        return false;
    }

    const properties = {
        time: moment.now(),
        distinct_id: uac,
        $user_id: uac,
        ...otherValues,
    };

    if (uniqueOnly) {
        properties['$insert_id'] = uac;
    }
    mixpanel.track(event, properties);
    return true;
};

//mixpanel calculate time until track method called for same event
export const trackEventDuration = ({ event, uac }) => {
    if (!analytics.PRODUCT.ENABLED) {
        return false;
    }

    mixpanel.time_event(event, {
        distinct_id: uac,
    });
    return true;
};

export const trackIdCaptureEvent = ({
    company,
    instruction,
    captureStage,
    authenticationStatus,
    idType,
    errorMessage = null,
    eventName = eventNames.captureId,
}) => {
    if (!analytics.PRODUCT.ENABLED) {
        return false;
    }

    const user = company?.user?.userDetails;

    const idVerifications = company?.user?.verificationDetails;

    let eventMetadata = {
        'Completion Time': moment().format('YYYY-MM-DD HH:mm:ss'),
        'Company Name': company.name,
        'Verification Type': 'ID',
        'Liveness Check': company.livenessCheckEnabled,
        'ID Capture Stage': captureStage,
        'ID Instruction Used': instruction?.lastUsedInstructionType === INSTRUCTION_TYPES.ID,
        'ID Authentication Status': authenticationStatus ?? ID_AUTHENTICATION_TYPES.FAILED,
        'ID Type': idType ?? 'Unknown',
        'Manual Review': eventName === eventNames.manualReview,
        Attempts: idVerifications.govtIdAttempts + 1,
    };

    if (errorMessage) {
        let message = isArray(errorMessage) ? errorMessage?.join(',') : errorMessage;

        eventMetadata['Error Message'] = message;
    }

    trackEvent({
        event: eventName,
        uac: user.uuid,
        uniqueOnly: false,
        otherValues: {
            ...eventMetadata,
        },
    });

    return true;
};
