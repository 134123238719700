import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import { Field, FieldArray } from 'formik';
import cn from 'classnames';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
// components
import styles from './EmploymentInformation.module.scss';
import Button from 'core-components/Button';
import ValidatedDate from 'core-components/ValidatedDate';
import ValidatedFormInputField from 'core-components/ValidatedFormInputField';
import ValidatedDropdown from 'core-components/ValidatedDropdown';
import states from 'utils/constants/states';
import { jobTypes } from 'utils/constants/jobTypes';
import { emptyEmployerFormValues, dateMask } from 'utils/constants';
import Checkbox from 'core-components/Checkbox';
import Loader from 'core-components/Loader';
import Background from 'core-components/Background';
import ValidatedPhoneNumber from 'core-components/ValidatedPhoneNumber';
import Image from 'core-components/Image';
import country from 'utils/constants/country';
import DeleteWarningPopup from 'core-components/DeleteWarningPopup';
// assets
import { ReactComponent as Delete } from 'images/Close.svg';

export default ({
    profile,
    show,
    handleShow,
    handleClose,
    renderLastStep,
    renderNextStep,
    isLastPoint,
    isPreviousFormSubmitted,
    ...props
}) => {
    const { status, handleSubmit, isSubmitting, values, verificationStep, location, history } =
        props;

    const [collapse, setCollapse] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const isEmploymentRecorded = profile?.company?.user?.verificationDetails?.isEmploymentRecorded;

    useEffect(() => {
        isEmploymentRecorded && renderNextStep();
    }, [isEmploymentRecorded, renderNextStep]);

    useEffect(() => {
        isLastPoint && renderLastStep();
    }, [isLastPoint, renderLastStep]);

    const deleteEmployerForm = (employer, pop) => {
        const isFromEdited =
            employer.city ||
            employer.companyName ||
            employer.end ||
            employer.start ||
            employer.contactEmail ||
            employer.contactName ||
            employer.contactNumber ||
            employer.employerPhoneNumber ||
            employer.houseNumber ||
            employer.jobTitle ||
            employer.jobType ||
            employer.reason ||
            employer.state ||
            employer.isCurrent ||
            employer.street ||
            employer.zipCode;

        if (isFromEdited) {
            setShowModal(true);
        } else {
            setCollapse(collapse - 1);
            pop();
        }
    };

    const handleOpenForm = (index) => setCollapse(index);

    const handleDeleteForm = (e, data, pop) => {
        e.stopPropagation();
        deleteEmployerForm(data, pop);
    };

    const handleSkip = () => {
        saveFormToLocal();
        renderNextStep();
    };

    const saveFormToLocal = () => {
        sessionStorage.setItem('employerForm', JSON.stringify(values.employers));
    };

    const displayBackButton =
        location.pathname !== profile?.defaultRoute[2] &&
        !isPreviousFormSubmitted(location.pathname);

    return (
        <>
            <Background className={styles.wrapper}>
                <Card.Header className={styles.employmentHeader}>
                    {displayBackButton && (
                        <Alert.Link
                            className={styles.backArrow}
                            onClick={() => {
                                saveFormToLocal();
                                history.goBack();
                            }}
                        >
                            <Image name="Back.svg" />
                        </Alert.Link>
                    )}

                    <div>
                        <Card.Title className={styles.heading}>Employment Verification</Card.Title>
                        <Card.Text className={styles.subHeading}>{verificationStep}</Card.Text>
                    </div>
                </Card.Header>

                <Form onSubmit={handleSubmit}>
                    <FieldArray
                        name="employers"
                        render={({ pop, push }) => (
                            <>
                                {status ? (
                                    <Loader />
                                ) : (
                                    <>
                                        {values.employers.map((employer, index, employers) => (
                                            <Fragment key={index}>
                                                <DeleteWarningPopup
                                                    showModal={showModal}
                                                    setShowModal={setShowModal}
                                                    collapse={collapse}
                                                    setCollapse={setCollapse}
                                                    data={employers}
                                                    pop={pop}
                                                    isSubmitting={isSubmitting}
                                                    name="Employer"
                                                />

                                                <div
                                                    className={cn(
                                                        styles.sectionHeader,
                                                        collapse !== index &&
                                                            styles.closeSectionHeader
                                                    )}
                                                    onClick={() => handleOpenForm(index)}
                                                >
                                                    <p className={styles.formTitle}>
                                                        Employer {index + 1}
                                                    </p>
                                                    {employers?.length - 1 === index &&
                                                        index !== 0 &&
                                                        collapse === employers?.length - 1 && (
                                                            <Button
                                                                className={styles.deleteBtn}
                                                                onClick={(e) =>
                                                                    handleDeleteForm(
                                                                        e,
                                                                        employer,
                                                                        pop
                                                                    )
                                                                }
                                                            >
                                                                <Delete />
                                                            </Button>
                                                        )}
                                                </div>
                                                <div>
                                                    <Collapse in={index === collapse}>
                                                        <Card.Body
                                                            className={styles.cardBody}
                                                            key={index}
                                                        >
                                                            <Form.Row>
                                                                <Col xs="12">
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Employer Name*
                                                                    </Form.Label>
                                                                    <Field
                                                                        type="text"
                                                                        name={`employers.${index}.companyName`}
                                                                        variant="outlined"
                                                                        component={
                                                                            ValidatedFormInputField
                                                                        }
                                                                        placeholder="Company name"
                                                                        defaultValue={
                                                                            employers[index]
                                                                                .companyName
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                            <p
                                                                className={
                                                                    styles.formSectionHeading
                                                                }
                                                            >
                                                                Address*
                                                            </p>

                                                            <Form.Row>
                                                                <Col md={6} xs={6}>
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        House No.
                                                                    </Form.Label>
                                                                    <Field
                                                                        component={
                                                                            ValidatedFormInputField
                                                                        }
                                                                        type="text"
                                                                        variant="outlined"
                                                                        name={`employers.${index}.houseNumber`}
                                                                        placeholder="House no."
                                                                        defaultValue={
                                                                            employers[index]
                                                                                .houseNumber
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col md={6} xs={6}>
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Street Address
                                                                    </Form.Label>
                                                                    <Field
                                                                        component={
                                                                            ValidatedFormInputField
                                                                        }
                                                                        type="text"
                                                                        variant="outlined"
                                                                        name={`employers.${index}.street`}
                                                                        placeholder="Street address"
                                                                        defaultValue={
                                                                            employers[index].street
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col md={6} xs={6}>
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        City
                                                                    </Form.Label>
                                                                    <Field
                                                                        component={
                                                                            ValidatedFormInputField
                                                                        }
                                                                        type="text"
                                                                        variant="outlined"
                                                                        name={`employers.${index}.city`}
                                                                        placeholder="City name"
                                                                        defaultValue={
                                                                            employers[index].city
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col md={6} xs={6}>
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        ZIP Code
                                                                    </Form.Label>
                                                                    <Field
                                                                        component={
                                                                            ValidatedFormInputField
                                                                        }
                                                                        type="text"
                                                                        variant="outlined"
                                                                        name={`employers.${index}.zipCode`}
                                                                        placeholder="ZIP code"
                                                                        defaultValue={
                                                                            employers[index].zipCode
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Form.Row>

                                                            <Form.Row>
                                                                <Col xs={12}>
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Country
                                                                    </Form.Label>
                                                                    <Field
                                                                        component={
                                                                            ValidatedDropdown
                                                                        }
                                                                        name={`employers.${index}.country`}
                                                                        items={country}
                                                                        disabled={true}
                                                                    />
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        State
                                                                    </Form.Label>
                                                                    <Field
                                                                        placeholder="State name"
                                                                        component={
                                                                            ValidatedDropdown
                                                                        }
                                                                        name={`employers.${index}.state`}
                                                                        items={states}
                                                                    />
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Employer Phone Number*
                                                                    </Form.Label>
                                                                    <Field
                                                                        name={`employers.${index}.employerPhoneNumber`}
                                                                        component={
                                                                            ValidatedPhoneNumber
                                                                        }
                                                                        block
                                                                        className={styles.input}
                                                                        countryCode={
                                                                            employers[index]
                                                                                .countryCode
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Col xs="12">
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Start and End Date{' '}
                                                                        <span>
                                                                            (must be exact month and
                                                                            year)
                                                                        </span>
                                                                        *
                                                                    </Form.Label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row className={styles.dateRow}>
                                                                <Col xs="6">
                                                                    <Field
                                                                        labelClass={
                                                                            styles.formLabel
                                                                        }
                                                                        component={ValidatedDate}
                                                                        name={`employers.${index}.start`}
                                                                        label="Start"
                                                                        maxDate={moment().toDate()}
                                                                        showMonthYearPicker
                                                                        dateFormat="MM/yyyy"
                                                                        example="MM/YYYY"
                                                                        mask={dateMask}
                                                                    />
                                                                </Col>
                                                                <Col xs="6">
                                                                    <Field
                                                                        labelClass={
                                                                            styles.formLabel
                                                                        }
                                                                        component={ValidatedDate}
                                                                        disabled={
                                                                            employer.isCurrent
                                                                        }
                                                                        name={`employers.${index}.end`}
                                                                        label="End"
                                                                        minDate={moment(
                                                                            employer.start
                                                                        ).toDate()}
                                                                        maxDate={moment().toDate()}
                                                                        showMonthYearPicker
                                                                        dateFormat="MM/yyyy"
                                                                        example="MM/YYYY"
                                                                        mask={dateMask}
                                                                    />
                                                                </Col>

                                                                <Field
                                                                    component={Checkbox}
                                                                    custom
                                                                    name={`employers.${index}.isCurrent`}
                                                                    className={styles.checkBox}
                                                                    size="sm"
                                                                    label="Current"
                                                                />
                                                            </Form.Row>

                                                            <p
                                                                className={
                                                                    styles.formSectionHeading
                                                                }
                                                            >
                                                                Company Reference Information *
                                                            </p>

                                                            <Form.Row>
                                                                <Col md="6" xs={12}>
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Job Title
                                                                    </Form.Label>
                                                                    <Field
                                                                        component={
                                                                            ValidatedFormInputField
                                                                        }
                                                                        type="text"
                                                                        variant="outlined"
                                                                        name={`employers.${index}.jobTitle`}
                                                                        placeholder="Job title"
                                                                        defaultValue={
                                                                            employers[index]
                                                                                .jobTitle
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col md={6} xs={12}>
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Job Type
                                                                    </Form.Label>
                                                                    <Field
                                                                        placeholder="Select"
                                                                        component={
                                                                            ValidatedDropdown
                                                                        }
                                                                        name={`employers.${index}.jobType`}
                                                                        items={jobTypes}
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Col xs="12">
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Reason for Leaving
                                                                    </Form.Label>
                                                                    <Field
                                                                        component={
                                                                            ValidatedFormInputField
                                                                        }
                                                                        type="text"
                                                                        variant="outlined"
                                                                        name={`employers.${index}.reason`}
                                                                        placeholder="Reason for leaving"
                                                                        defaultValue={
                                                                            employers[index].reason
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Col xs="12">
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Contact Name
                                                                    </Form.Label>
                                                                    <Field
                                                                        component={
                                                                            ValidatedFormInputField
                                                                        }
                                                                        type="text"
                                                                        variant="outlined"
                                                                        name={`employers.${index}.contactName`}
                                                                        placeholder="Contact name"
                                                                        defaultValue={
                                                                            employers[index]
                                                                                .contactName
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Col xs="12">
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Contact Phone Number{' '}
                                                                    </Form.Label>
                                                                    <Field
                                                                        name={`employers.${index}.contactNumber`}
                                                                        component={
                                                                            ValidatedPhoneNumber
                                                                        }
                                                                        block
                                                                        className={styles.input}
                                                                        countryCode={
                                                                            employers[index]
                                                                                .countryCode
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Col xs="12">
                                                                    <Form.Label
                                                                        className={styles.formLabel}
                                                                    >
                                                                        Email Address
                                                                    </Form.Label>
                                                                    <Field
                                                                        component={
                                                                            ValidatedFormInputField
                                                                        }
                                                                        type="text"
                                                                        variant="outlined"
                                                                        name={`employers.${index}.contactEmail`}
                                                                        placeholder="Email address"
                                                                        defaultValue={
                                                                            employers[index]
                                                                                .contactEmail
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Form.Row>
                                                        </Card.Body>
                                                    </Collapse>
                                                </div>
                                            </Fragment>
                                        ))}
                                    </>
                                )}
                                <Card.Footer className={styles.employmentFooter}>
                                    <Button
                                        variant="outline-primary"
                                        disabled={isSubmitting}
                                        className={styles.footerBtn}
                                        onClick={() => {
                                            saveFormToLocal();
                                            push(emptyEmployerFormValues);
                                            setCollapse(collapse + 1);
                                        }}
                                    >
                                        Add Employer
                                    </Button>
                                    <Button
                                        variant="primary"
                                        className={styles.footerBtn}
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Done
                                    </Button>
                                </Card.Footer>
                            </>
                        )}
                    />
                </Form>
            </Background>
            <div className={styles.skipBtnContainer}>
                <Button
                    variant="link"
                    disabled={isSubmitting}
                    onClick={handleSkip}
                    className={styles.skipBtn}
                >
                    Skip
                </Button>
            </div>
        </>
    );
};
