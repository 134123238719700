import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { setButtonVariant } from 'utils/utilities';
import Card from 'react-bootstrap/Card';
import Button from 'core-components/Button';
import styles from './SelfiePage.module.css';

import { useDispatch } from 'react-redux';
import { setInstructionType, setShowInstructions } from 'actions/instructions';
import CardBodyContent from './CardBodyContent';
import LinkToInstructionScreen from 'core-components/Instructions/LinkToInstructionScreen';
import Instructions from '../Instructions/index';

import cn from 'classnames';
import { data } from 'utils/constants/selifeInstructions';
import { INSTRUCTION_TYPES } from 'utils/constants';

function SelfiePage({ handleOnClick, ...props }) {
    const dispatch = useDispatch();

    const isSelfieError = !!props?.selfieErrorMsg;

    const handleSelfieGuideClick = () => {
        dispatch(setShowInstructions(true));
        dispatch(setInstructionType(INSTRUCTION_TYPES.SELFIE));
    };

    const cardHeaderContent = isSelfieError ? 'Selfie Image Capture Failed!' : 'Selfie';
    const selfieInstructionsBtnText = isSelfieError
        ? 'How to take a selfie?'
        : 'Unlock Pro Tips for an Epic Selfie!';

    const captureSelfieBtnText = isSelfieError ? 'Please Try Again' : 'Take Selfie Image';

    return (
        <Fragment>
            <Card.Header as="h5" className={styles.scanCardHeader}>
                {cardHeaderContent}
            </Card.Header>
            <Card.Body className={styles.scanCardBody}>
                <CardBodyContent showErrorContent={isSelfieError} errMsg={props?.selfieErrorMsg} />
            </Card.Body>
            <div id="acuant-face-capture-container"></div>
            <Card.Footer
                className={
                    isSelfieError
                        ? cn(styles.resultCardFooter, styles.selfieErrFooter)
                        : styles.resultCardFooter
                }
            >
                <LinkToInstructionScreen
                    handleOnClick={handleSelfieGuideClick}
                    buttonText={selfieInstructionsBtnText}
                    className={styles.instructionsLink}
                />
                <Button
                    block
                    variant={setButtonVariant(props.theme?.button.variant)}
                    className={styles.footerBtn}
                    onClick={handleOnClick}
                >
                    {captureSelfieBtnText}
                </Button>
            </Card.Footer>
            {props.showInstructions && (
                <Instructions handleEndProcess={handleOnClick} data={data} />
            )}
        </Fragment>
    );
}

SelfiePage.propTypes = {
    theme: PropTypes.object,
    handleOnClick: PropTypes.func,
    showInstructions: PropTypes.bool,
    selfieErrorMsg: PropTypes.string,
};

export default SelfiePage;
