import * as Yup from 'yup';

function equalTo(ref, msg) {
    return Yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg || 'Path must be the same as Reference',
        params: {
            reference: ref.path,
        },
        test: function (value) {
            return value === this.resolve(ref);
        },
    });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export default ({ confirmUserInformationForAdditionalChecks }) => {
    return Yup.object().shape({
        firstName: Yup.string().required('Required'),
        middleName: Yup.string().when('acceptMiddleName', {
            is: false,
            then: Yup.string().required('Required'),
        }),
        lastName: Yup.string().required('Required'),
        dob: Yup.string().nullable().required('Required'),
        confirmSSN: confirmUserInformationForAdditionalChecks
            ? Yup.string()
                  .min(4, 'Invalid SSN')
                  .when('ssn', {
                      is: (ssn) => ssn && ssn.length > 0,
                      then: Yup.string()
                          .required('Please confirm the SSN')
                          .oneOf([Yup.ref('ssn'), null], 'SSN must match'),
                      otherwise: Yup.string().oneOf([Yup.ref('ssn'), null], 'SSN must match'),
                  })
            : Yup.string()
                  .min(4, 'Invalid SSN')
                  .required('Required')
                  .oneOf([Yup.ref('ssn'), null], 'SSN must match'),
        ssn: confirmUserInformationForAdditionalChecks
            ? Yup.string().min(4, 'Invalid SSN')
            : Yup.string().min(4, 'Invalid SSN').required('Required'), // If the source is an integration we don't require SSN
        houseNumber: confirmUserInformationForAdditionalChecks
            ? Yup.string().when('country', {
                  is: 'United States of America',
                  then: Yup.string().required('Required'),
              })
            : Yup.string(),
        streetName: confirmUserInformationForAdditionalChecks
            ? Yup.string().when('country', {
                  is: 'United States of America',
                  then: Yup.string().required('Required'),
              })
            : Yup.string(),
        city: confirmUserInformationForAdditionalChecks
            ? Yup.string().when('country', {
                  is: 'United States of America',
                  then: Yup.string().required('Required'),
              })
            : Yup.string(),
        zipCode: confirmUserInformationForAdditionalChecks
            ? Yup.string().when('country', {
                  is: 'United States of America',
                  then: Yup.string().required('Required'),
              })
            : Yup.string(),
        state: confirmUserInformationForAdditionalChecks
            ? Yup.string().when('country', {
                  is: 'United States of America',
                  then: Yup.string().required('Required'),
              })
            : Yup.string(),
    });
};
