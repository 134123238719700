import { connect } from 'react-redux';
import { actions as statusActions, selectors as statusSelectors, setStatus } from 'actions/status';
import {
    actions as reauthenticateStatusActions,
    selectors as reauthenticateStatusSelectors,
    getReauthenticateSessionStatus,
} from 'actions/reAuthenticate';

const mapStateToProps = (state) => {
    return {
        // Medallion flow states and polling
        session: state.session,
        isPollingActive: statusSelectors.isPollingActive(state),
        lastPoint: statusSelectors.getLastPoint(state),
        count: statusSelectors.getCount(state),
        profile: state.profile,
        verificationConfiguration: state.general?.verificationConfiguration,
        // Reauthenticate flow states and polling
        isReauthenticatePollingActive: reauthenticateStatusSelectors.isPollingActive(state),
        reauthenticateLastPoint: reauthenticateStatusSelectors.getLastPoint(state),
        reauthenticateCount: reauthenticateStatusSelectors.getCount(state),
        reAuthenticateSessionID: state.reAuthenticate?.reAuthenticateSessionID,
        reauthenticateSessionStatus: state.reAuthenticate?.reauthenticateSessionStatus,
        sessionStatus: state.reAuthenticate?.sessionStatus,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // Medallion flow actions and polling
        startPolling: () => dispatch(statusActions.start()),
        stopPolling: () => dispatch(statusActions.stop()),
        resetPolling: () => dispatch(statusActions.reset()),
        setStatus: (status) => dispatch(setStatus(status)),
        // Reauthenticate flow actions and polling
        startReauthenticatePolling: () => dispatch(reauthenticateStatusActions.start()),
        stopReauthenticatePolling: () => dispatch(reauthenticateStatusActions.stop()),
        resetReauthenticatePolling: () => dispatch(reauthenticateStatusActions.reset()),
        getReauthenticateSessionStatus: (sessionID) =>
            dispatch(getReauthenticateSessionStatus(sessionID)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps);
