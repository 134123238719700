import { datadogRum } from '@datadog/browser-rum';
import { loggerConfig } from 'config';

export const getLivenessToken = () => {
    if (process.env.NODE_ENV === 'development') {
        return btoa(`${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`);
    } else {
        return process.env.REACT_APP_AUTH_TOKEN;
    }
};

export const getLivenessForCapturedImage = (params, callback) => {
    window.AcuantPassiveLiveness.getLiveness(params, callback);
};

export const startFaceDetectionAndLiveness = (params, states) => {
    window.AcuantPassiveLiveness.start(params, states);
};

export const initializeAcuantSDK = ({ isInitialized, isIntializing, changeState }) => {
    if (!isInitialized && !isIntializing) {
        changeState({ isIntializing: true });

        // eslint-disable-next-line no-undef
        let webSDK = AcuantJavascriptWebSdk;

        webSDK.setUnexpectedErrorCallback = (error) => {
            if (loggerConfig.LOGGING_ENABLED) {
                datadogRum.addError(error, { acuantSdkError: true });
            }
        };

        webSDK.initialize(
            (function () {
                if (process.env.NODE_ENV === 'development') {
                    return btoa(
                        `${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`
                    );
                } else {
                    return process.env.REACT_APP_AUTH_TOKEN;
                }
            })(),
            process.env.REACT_APP_AUTH_ENDPOINT,
            {
                onSuccess: function () {
                    changeState({
                        isIntializing: false,
                        isInitialized: true,
                        isAcuantSdkLoaded: true,
                    });

                    webSDK.start(() => {});
                },

                onFail: function (code, description) {
                    changeState({
                        isAcuantSdkLoaded: true,
                        isIntializing: false,
                    });

                    if (loggerConfig.LOGGING_ENABLED) {
                        datadogRum.addError(new Error('Acuant SDK Initialize Error'), {
                            acuantSdkError: true,
                            extra: { description, code },
                        });
                    } else {
                        console.error({
                            code,
                            description,
                            message: 'Acuant Error',
                        });
                    }
                },
            }
        );
    }
};

export const loadAcuantSDKScript = ({
    initializeFailed,
    isAcuantSdkLoaded,
    isInitialized,
    isIntializing,
    changeState,
}) => {
    const isSdkLoaded = document.querySelector('script[src*="AcuantJavascriptWebSdk.min.js"]');

    if (isSdkLoaded) {
        return changeState({ isAcuantSdkLoaded: true, isInitialized: true });
    }

    const baseUrl = `${window.location.origin}/`;

    window.acuantConfig = {
        path: baseUrl,
    };
    window.onAcuantSdkLoaded = function () {
        initializeAcuantSDK({
            isInitialized,
            isIntializing,
            changeState,
        });
    };

    let loadedState = {
        sdk: true, // as it is sync
        camera: false,
        passiveLiveness: false,
        openCVScript: false,
        qrcode: false,
    };

    const scriptLoaded = (name) => () => {
        loadedState[name] = true;

        if (
            loadedState.sdk &&
            loadedState.camera &&
            loadedState.passiveLiveness &&
            loadedState.openCVScript &&
            loadedState.qrcode
        ) {
            if (window.loadAcuantSdk) {
                // eslint-disable-next-line
                window.loadAcuantSdk();
            } else {
                changeState({
                    initializeFailed: true,
                });

                datadogRum.addAction('Show Engine Initialization Failed', {
                    acuantSdkError: true,
                    state: { isAcuantSdkLoaded, initializeFailed },
                    loadedState,
                    loadAcuant: window.loadAcuantSdk,
                });
            }
        }
    };
    const sdk = document.createElement('script');
    sdk.src = `${baseUrl}AcuantJavascriptWebSdk.min.js`;
    sdk.onload = scriptLoaded('sdk');

    document.body.appendChild(sdk);

    const acuantCamera = document.createElement('script');
    acuantCamera.src = `${baseUrl}AcuantCamera.min.js`;
    acuantCamera.async = true;
    acuantCamera.onload = scriptLoaded('camera');

    document.body.appendChild(acuantCamera);

    const html5Qrcode = document.createElement('script');
    html5Qrcode.src = `${baseUrl}html5-qrcode.min.js`;
    html5Qrcode.async = true;
    html5Qrcode.onload = scriptLoaded('qrcode');

    document.body.appendChild(html5Qrcode);

    const acuantPassiveLiveness = document.createElement('script');
    acuantPassiveLiveness.src = `${baseUrl}AcuantPassiveLiveness.min.js`;
    acuantPassiveLiveness.async = true;
    acuantPassiveLiveness.onload = scriptLoaded('passiveLiveness');

    document.body.appendChild(acuantPassiveLiveness);

    const openCVScript = document.createElement('script');
    openCVScript.src = `${baseUrl}opencv.min.js`;
    openCVScript.async = true;
    openCVScript.onload = scriptLoaded('openCVScript');

    document.body.appendChild(openCVScript);
};
