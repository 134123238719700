import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Background from 'core-components/Background';
import { Field } from 'formik';
import Button from 'core-components/Button';
import Card from 'react-bootstrap/Card';
import Loader from 'core-components/Loader';
import styles from './OTPForm.module.scss';
import OtpInput from 'react-otp-input';

const RETRY_OFFSET_SECONDS = 60;

function useInterval(callback, delay) {
    const savedCallback = React.useRef();

    // Remember the latest callback.
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    React.useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
}

export default (props) => {
    const [counter, setCounter] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const { twoFactorAuth } = props;
    const { handleSubmit, isSubmitting, token } = props;
    const secondsLeft = RETRY_OFFSET_SECONDS - counter;
    const canRetry = secondsLeft <= 0;

    const sendOTPAndResetTimer = () => {
        setLoading(true);
        twoFactorAuth(token).then(() => {
            setLoading(false);
            setCounter(0);
        });
    };
    React.useEffect(sendOTPAndResetTimer, [token, twoFactorAuth]);

    useInterval(() => {
        setCounter(counter + 1);
    }, 1000);

    const handleChange = (otp) => {
        props.setFieldValue('value', otp);
    };
    return (
        <>
            <Background className={styles.otpCardWrapper} wrapper={loading && styles.wrapper}>
                <Card.Header as="h5" className={styles.cardHeader}>
                    Enter One-Time Passcode
                </Card.Header>
                {loading ? (
                    <Card.Body className={styles.body}>
                        <Loader />
                    </Card.Body>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Card.Body className={styles.cardbody}>
                            <p className={styles.description}>
                                We have sent a one time passcode to email{' '}
                                <b>{`${props.metadata.email}`}</b>. Please enter in below to
                                proceed.
                            </p>

                            <div className={styles.description}>
                                Enter the one time passcode here
                            </div>
                            <Field
                                component={OtpInput}
                                renderInput={(props) => <input {...props} />}
                                numInputs={6}
                                renderSeparator={<span>&ensp;</span>}
                                isInputNum={true}
                                value={props.values.value}
                                onChange={handleChange}
                                inputStyle={styles.otpInput}
                                containerStyle={styles.container}
                                focusStyle={styles.focusOtpInput}
                            />

                            {canRetry ? (
                                <Button
                                    variant="link"
                                    onClick={sendOTPAndResetTimer}
                                    className={styles.retryBtn}
                                >
                                    Retry
                                </Button>
                            ) : (
                                <p
                                    className={styles.timer}
                                >{`You can try again in  ${secondsLeft} seconds.`}</p>
                            )}
                        </Card.Body>
                        <Card.Footer className={styles.cardFooter}>
                            <Row>
                                <Col>
                                    <Button
                                        block
                                        variant="outline-primary"
                                        className={styles.footerBtn}
                                        onClick={() => props.reset()}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        block
                                        className={styles.footerBtn}
                                        disabled={isSubmitting || props.values.value.length !== 6}
                                        variant="primary"
                                        type="submit"
                                    >
                                        Proceed
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Form>
                )}
            </Background>
        </>
    );
};
