import React from 'react';
import styles from './DeleteWarningPopup.module.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'core-components/Button';
import Image from 'core-components/Image';

function DeleteWarningPopup({
    showModal,
    setShowModal,
    collapse,
    setCollapse,
    pop,
    data,
    isSubmitting,
    name,
}) {
    const handleSubmit = () => {
        if (collapse !== 0) {
            setCollapse(collapse - 1);
            pop();
            setShowModal(false);
        }
    };

    const handleClose = () => setShowModal(false);

    return (
        <Modal centered show={showModal} onHide={handleClose} className={styles.modal}>
            <Modal.Header className={styles.modalHeader}>
                <Modal.Title className={styles.modalTitle}>Delete {name}?</Modal.Title>
                <Image name="Close.svg" onClick={handleClose} />
            </Modal.Header>

            <Modal.Body className={styles.modalBody}>
                Are you sure you want to delete "{name} {data.length}" ?
            </Modal.Body>

            <Modal.Footer className={styles.modalFooter}>
                <Button variant="outline-primary" className={styles.modalBtn} onClick={handleClose}>
                    Cancel
                </Button>

                <Button
                    variant="primary"
                    disabled={isSubmitting}
                    className={styles.modalBtn}
                    onClick={handleSubmit}
                >
                    Delete {name}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteWarningPopup;
