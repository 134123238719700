/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Background from 'core-components/Background';
import Card from 'react-bootstrap/Card';
import Button from 'core-components/Button';
import styles from './Lander.module.scss';
import Media from 'react-bootstrap/Media';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch } from 'react-redux';
import { updateProfile } from 'actions/profile';
import LanderContent from './LanderContent';
import AuthenticateLogo from 'components/AuthenticateLogo';

export default (props) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(
            updateProfile({
                company: {
                    name: 'Authenticating.com',
                },
            })
        );
    }, []);
    return (
        <>
            <Background className={styles.welcomeCard}>
                <Card.Header className={styles.cardHeaderWrapper}>
                    <Media className={styles.cardHeader}>
                        {/* <Avatar
              className={cn(styles.logo, 'align-self-center mr-2')}
              // round
              size={36}
              src={require('images/logo-small.png')}
            /> */}
                        <Media.Body>
                            <div>
                                <b>Medallion™</b>
                            </div>
                        </Media.Body>
                    </Media>
                </Card.Header>
                <Card.Body className={styles.body}>
                    <div>
                        <p className={styles.cardheading}>
                            <b>Use Authenticating.com to quickly verify your identity.</b>
                        </p>
                        <LanderContent />
                    </div>
                </Card.Body>
                <Card.Footer
                    style={{ background: '#FFFFFF', border: 'none', padding: '20px 15px 24px' }}
                >
                    <Row>
                        <Col>
                            <Button
                                block
                                variant="outline-primary"
                                className={styles.footerBtn}
                                onClick={() =>
                                    window.open(
                                        'https://portal.authenticating.com/register',
                                        '_blank'
                                    )
                                }
                            >
                                Get API key
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                block
                                className={styles.footerBtn}
                                variant="primary"
                                onClick={() => props.history.push('/onboarding')}
                            >
                                <AuthenticateLogo />
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Background>
        </>
    );
};
