import { REQUEST_COMMUNICATION, UPDATE_COMMUNICATION, RESET_COMMUNICATION } from '.';
import { sendMessageAPI, sendEmailAPI } from 'api/communication';
import { sendReauthenticateEmailAPI, sendReauthenticateMessageAPI } from 'api/reAuthenticate';
import { getErrorMessage, toCamelCase } from 'utils/utilities';
import { toast } from 'react-toastify';

export const updateCommunication = (data) => {
    return {
        type: UPDATE_COMMUNICATION,
        data,
    };
};

export const sendCommunication = (type, data, sessionID) => (dispatch) => {
    dispatch({ type: REQUEST_COMMUNICATION });

    return getCommunicationByType(type, data, sessionID)
        .then((response) => {
            setTimeout(
                () =>
                    dispatch({
                        type: RESET_COMMUNICATION,
                    }),
                60000
            );
            dispatch(updateCommunication({ ...toCamelCase(response.data), type: type }));
            return response.data;
        })
        .catch((error) => {
            toast.error(getErrorMessage(error));
        });
};

const getCommunicationByType = (type, data, sessionUUID) => {
    switch (type) {
        case 'MESSAGE':
            return sendMessageAPI(data);

        case 'EMAIL':
            return sendEmailAPI(data);

        // APIs for send SMS and email for Reauthenticate session
        case 'REAUTHENTICATE_MESSAGE':
            return sendReauthenticateMessageAPI(data, sessionUUID);

        case 'REAUTHENTICATE_EMAIL':
            return sendReauthenticateEmailAPI(data, sessionUUID);
        default:
    }
};
