import React from 'react';
import Card from 'react-bootstrap/Card';
import styles from './Card.module.scss';
import cn from 'classnames';

export default props => {
  return (
    <Card className={cn(styles.card, props.className)}>
      {props.children}
    </Card>
  );
};
