import React from 'react';
import { Switch, Route } from 'react-router-dom';
import VerificationOptions from './VerificationOptions';
import VerificationQuizView from './VerificationQuizView';
import DeviceLinks from './DeviceLinks';
import NotFound from 'components/NotFound';
import KbaResult from 'components/KbaResult';
import BankSuccess from 'components/BankSuccess';
import IDFailed from 'screens/Error/VerificationFailed/IDFailed';
import KbaFailed from 'screens/Error/VerificationFailed/KbaFailed';
import BankFailed from 'screens/Error/VerificationFailed/BankFailed';

export default ({
    match,
    renderNextStep,
    renderLastStep,
    webhook,
    profile,
    kba,
    verificationStep,
    isLastPoint,
    ...props
}) => {
    const userInfo =
        !profile.loading && profile.company && profile.company.user ? profile.company.user : null;

    const KbaError = kba.error === '417';
    const sourceIsIntegration = ['guesty', 'hostaway', 'e-commerce'].includes(profile.source);
    const enabledAdditionalChecksSetting =
        profile.integrationSettings && profile.integrationSettings.enabledAdditionalChecks
            ? profile.integrationSettings.enabledAdditionalChecks.value
            : [];

    const confirmUserInformationForAdditionalChecks =
        profile?.company?.userInformationStatus === 'askUserToConfirm' ||
        (sourceIsIntegration && userInfo && enabledAdditionalChecksSetting.length > 0);

    const confirmUserInformation =
        KbaError || (!props.session.profileSaved && confirmUserInformationForAdditionalChecks);

    return (
        <Switch>
            <Route
                exact
                path={`${match.path}/`}
                render={(props) => (
                    <VerificationOptions
                        {...props}
                        renderNextStep={renderNextStep}
                        renderLastStep={renderLastStep}
                        confirmUserInformationForAdditionalChecks={
                            confirmUserInformationForAdditionalChecks
                        }
                        confirmUserInformation={confirmUserInformation}
                        KbaError={KbaError}
                        userInfo={userInfo}
                        verificationStep={verificationStep}
                        isLastPoint={isLastPoint}
                    />
                )}
            />
            <Route
                exact
                path={`${match.path}/links`}
                render={(props) => <DeviceLinks {...props} webhook={webhook} />}
            />
            <Route
                exact
                path={`${match.path}/kba`}
                render={(props) => (
                    <VerificationQuizView
                        {...props}
                        webhook={webhook}
                        confirmUserInformationForAdditionalChecks={
                            confirmUserInformationForAdditionalChecks
                        }
                        confirmUserInformation={confirmUserInformation}
                    />
                )}
            />
            <Route
                exact
                path={`${match.path}/kba/result`}
                render={(props) => <KbaResult {...props} profile={profile} />}
            />
            <Route
                exact
                path={`${match.path}/bank/success`}
                render={(props) => <BankSuccess {...props} profile={profile} />}
            />
            <Route
                exact
                path={`${match.path}/kba/failed`}
                render={(props) => <KbaFailed {...props} profile={profile} />}
            />
            <Route
                exact
                path={`${match.path}/idFailed`}
                render={(props) => <IDFailed {...props} profile={profile} />}
            />
            <Route
                exact
                path={`${match.path}/bank/failed`}
                render={(props) => <BankFailed {...props} profile={profile} />}
            />

            <Route path="*" component={NotFound} />
        </Switch>
    );
};
