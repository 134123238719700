import {
    UPDATE_REAUTHENTICATE_ADDITIONAL_VERIFICATION,
    UPDATE_REAUTHENTICATE_SELFIE_CHECK,
} from 'actions';
import { reauthenticateSelfieVerificationApi } from 'api/reAuthenticate';
import moment from 'moment';
import {
    REAUTHENTICATE_ROUTES,
    REAUTHENTICATE_STATUS,
    REAUTHENTICATE_TOASTER_MESSAGES,
} from 'utils/constants';
import { minimalToaster } from 'utils/utilities';

const useReauthenticateFlow = ({
    selfie,
    processedData,
    reAuthenticateSessionId,
    history,
    dispatch,
    sessionStatus,
    isDeviceInfoSent,
    startSessionTimer,
    updateReauthenticateStatus,
    getDeviceInfoAction,
    setShowInstructions,
    setLoading,
}) => {
    const processReauthenticateSelfie = async () => {
        if (selfie !== null) {
            const { COMPLETED, FAILED } = REAUTHENTICATE_STATUS;

            try {
                const payload = {
                    ...processedData,
                    result: { selfieImage: `data:image/jpeg;base64,${selfie}` },
                };

                const response = await reauthenticateSelfieVerificationApi(
                    reAuthenticateSessionId,
                    payload
                );

                setLoading(false);
                if (setShowInstructions) {
                    dispatch(setShowInstructions(false));
                }

                const selfieVerification = response?.data?.selfieVerification;

                dispatch({
                    payload: selfieVerification,
                    type: UPDATE_REAUTHENTICATE_SELFIE_CHECK,
                });

                if (selfieVerification?.status === COMPLETED) {
                    const additionalVerification = response.data.additionalVerification;

                    dispatch({
                        payload: additionalVerification,
                        type: UPDATE_REAUTHENTICATE_ADDITIONAL_VERIFICATION,
                    });

                    minimalToaster(REAUTHENTICATE_TOASTER_MESSAGES.SELFIE_SUCCESS);
                } else if (selfieVerification.status === FAILED) {
                    if (selfieVerification?.attempts < sessionStatus?.allowedAttempts) {
                        history.push(REAUTHENTICATE_ROUTES.CAPTURE_SELFIE);
                    } else {
                        history.push(REAUTHENTICATE_ROUTES.SELFIE_FAILURE);
                    }
                }
            } catch (error) {
                history.push(REAUTHENTICATE_ROUTES.SELFIE_FAILURE);
            }
        }
    };

    const initReauthenticateFlow = () => {
        // set session status to IN_PROGRESS if it's not given before
        if (!sessionStatus?.status) {
            updateReauthenticateStatus('IN_PROGRESS', reAuthenticateSessionId);
        }

        // Call the device info API only on the first load
        if (!isDeviceInfoSent) {
            getDeviceInfoAction(reAuthenticateSessionId);
        }

        startSessionTimer(moment().add(7, 'minute'));
    };

    return {
        initReauthenticateFlow,
        processReauthenticateSelfie,
    };
};

export default useReauthenticateFlow;
