export function capturedReducer(
  state = {
    image: {
      data: '',
      width: 0,
      height: 0,
    },
    glare: -1,
    sharpness: -1,
  },
  action,
) {
  switch (action.type) {
    case '@@acuant/ADD_CAPTURED_IMAGE':
      return {
        ...action.data,
      }
    default:
      return state
  }
}
