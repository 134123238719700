export const REQUEST_KBA = 'REQUEST_KBA';
export const UPDATE_KBA = 'UPDATE_KBA';
export const RESET_KBA = 'RESET_KBA';

export const REQUEST_PROFILE = 'REQUEST_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const RESET_TOKEN = 'RESET_TOKEN';
export const UPDATE_SOURCE = 'UPDATE_SOURCE';

export const REQUEST_RESULT = 'REQUEST_RESULT';
export const UPDATE_RESULT = 'UPDATE_RESULT';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const REQUEST_COMMUNICATION = 'REQUEST_COMMUNICATION';
export const UPDATE_COMMUNICATION = 'UPDATE_COMMUNICATION';
export const RESET_COMMUNICATION = 'RESET_COMMUNICATION';

export const REMOVE_LAST_IMAGE = 'REMOVE_LAST_IMAGE';

export const SESSION_USER_SAVED = 'SESSION_USER_SAVED';

export const RESET_ALL_REDUCERS = 'RESET_ALL_REDUCERS';

export const SET_DOCUMENT_ATTEMPT = 'SET_DOCUMENT_ATTEMPT';
export const RESET_DOCUMENT_ATTEMPT = 'RESET_DOCUMENT_ATTEMPT';

export const SAVE_ID_ERROR_MESSAGES = 'SAVE_ID_ERROR_MESSAGES';
export const CLEAR_ID_ERROR_MESSAGES = 'CLEAR_ID_ERROR_MESSAGES';

export const SET_VERIFICATION_CONFIGURATION = 'SET_VERIFICATION_CONFIGURATION';
export const CLEAR_VERIFICATION_CONFIGURATION = 'CLEAR_VERIFICATION_CONFIGURATION';

export const START_SESSION_TIMER = 'START_SESSION_TIMER';
export const RESET_SESSION_TIMER = 'RESET_SESSION_TIMER';

export const UPDATE_REAUTHENTICATE_SESSION_UUID = 'UPDATE_REAUTHENTICATE_SESSION_UUID';
export const RESET_REAUTHENTICATE_SESSION_UUID = 'RESET_REAUTHENTICATE_SESSION_UUID';

export const REQUEST_REAUTHENTICATE_SESSION_STATUS = 'REQUEST_REAUTHENTICATE_SESSION_STATUS';
export const UPDATE_REAUTHENTICATE_SESSION_STATUS = 'UPDATE_REAUTHENTICATE_SESSION_STATUS';
export const UPDATE_REAUTHENTICATE_SESSION_STATUS_ERROR =
    'UPDATE_REAUTHENTICATE_SESSION_STATUS_ERROR';

export const REQUEST_REAUTHENTICATE_UPDATE_STATUS = 'REQUEST_REAUTHENTICATE_UPDATE_STATUS';
export const UPDATE_REAUTHENTICATE_UPDATE_STATUS = 'UPDATE_REAUTHENTICATE_UPDATE_STATUS';
export const UPDATE_REAUTHENTICATE_UPDATE_STATUS_ERROR =
    'UPDATE_REAUTHENTICATE_UPDATE_STATUS_ERROR';

export const UPDATE_REAUTHENTICATE_SELFIE_CHECK = 'UPDATE_REAUTHENTICATE_SELFIE_CHECK';
export const UPDATE_REAUTHENTICATE_ADDITIONAL_VERIFICATION =
    'UPDATE_REAUTHENTICATE_ADDITIONAL_VERIFICATION';

export const REQUEST_SESSION_DEVICE_INFO = 'REQUEST_SESSION_DEVICE_INFO';
export const UPDATE_SESSION_DEVICE_INFO = 'UPDATE_SESSION_DEVICE_INFO';
export const UPDATE_SESSION_DEVICE_INFO_ERROR = 'UPDATE_SESSION_DEVICE_INFO_ERROR';

export const HIDE_CODE_RESEND_LINK = 'HIDE_CODE_RESEND_LINK';

export const UPDATE_SHOW_INSTRUCTIONS = 'UPDATE_SHOW_INSTRUCTIONS';
export const SET_INSTRUCTION_TYPE = 'SET_INSTRUCTION_TYPE';
export const SET_LAST_USED_INSTRUCTION_TYPE = 'SET_LAST_USED_INSTRUCTION_TYPE';
