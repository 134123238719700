import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({
    ContentSection = null,
    ActionSection = null,
    open = false,
    onClose = () => {},
    title = 'Title Template',
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'inherit' }}>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {ContentSection ? <ContentSection /> : null}
                </DialogContentText>
            </DialogContent>
            <DialogActions>{ActionSection ? <ActionSection /> : null}</DialogActions>
        </Dialog>
    );
}
