import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/reauthenticateScreens.module.css';
import Card from 'react-bootstrap/Card';
import Button from 'core-components/Button';
import { Field } from 'formik';
import Form from 'react-bootstrap/Form';
import ValidatedFormInputField from 'core-components/ValidatedFormInputField';
import {
    setButtonVariant,
    hideEmailPhoneInfo,
    getReauthenticateAdditionalMethodData,
    minimalToaster,
} from 'utils/utilities';
import Loader from 'core-components/Loader';
import {
    REAUTHENTICATE_ROUTE,
    REAUTHENTICATE_STATUS,
    REAUTHENTICATE_TOASTER_MESSAGES,
} from 'utils/constants';
import { reauthenticateCodeResendAPI } from 'api/reAuthenticate';
import Background from 'core-components/Background';

function VerifyEmailPhone({
    handleSubmit,
    profile,
    reAuthenticateStatus,
    dirty,
    isSubmitting,
    history,
    reAuthenticateSessionId,
    setSubmitting,
    hideCodeResendLink,
    setHideCodeResendLink,
    ...props
}) {
    const verifyMethodData = getReauthenticateAdditionalMethodData(
        reAuthenticateStatus?.additionalVerification
    );

    const verifyMethod = verifyMethodData.method;

    const emailOrPhone = verifyMethodData?.value;

    const selfieCompleted = useMemo(() => {
        const selfieVerification = reAuthenticateStatus?.selfieVerification;

        return selfieVerification?.status === REAUTHENTICATE_STATUS.COMPLETED;
    }, [reAuthenticateStatus]);

    const resendVerificationCode = async () => {
        setSubmitting(true);
        try {
            await reauthenticateCodeResendAPI(reAuthenticateSessionId);

            setHideCodeResendLink(true);
            minimalToaster(REAUTHENTICATE_TOASTER_MESSAGES.CODE_SENT);
        } catch (error) {
            //TODO: handle failure case if reauthenticate session not valid
        }

        setSubmitting(false);
    };

    useEffect(() => {
        if (!selfieCompleted) {
            history.push(`/${REAUTHENTICATE_ROUTE}`);
        }
    }, [selfieCompleted, history]);

    useEffect(() => {
        let resendLinkTimeout;

        if (hideCodeResendLink) {
            resendLinkTimeout = setTimeout(() => {
                setHideCodeResendLink(false);
            }, [60000]);
        }

        return () => {
            if (resendLinkTimeout) clearTimeout(resendLinkTimeout);
        };
    }, [hideCodeResendLink, setHideCodeResendLink]);

    return (
        <Background className={styles.containerWrapper}>
            <Card className={styles.verifyCodeWrapper}>
                {isSubmitting ? (
                    <Loader className={styles.verifyCodeLoader} />
                ) : (
                    <>
                        <Card.Header className={styles.cardHeader}>
                            {verifyMethod === 'Email'
                                ? 'Verify your email address'
                                : 'Verify your phone number'}
                        </Card.Header>
                        <Card.Body className={styles.cardBody}>
                            <p className={styles.description}>
                                {verifyMethod === 'Email'
                                    ? 'Verification code has been sent to your email'
                                    : 'Verification code has been sent to your phone'}
                                <span> {hideEmailPhoneInfo(emailOrPhone)}</span>
                            </p>
                            {!hideCodeResendLink && (
                                <Button
                                    block
                                    variant="link"
                                    className={styles.resendBtn}
                                    onClick={resendVerificationCode}
                                >
                                    Resend
                                </Button>
                            )}

                            <Form
                                noValidate
                                onSubmit={handleSubmit}
                                className={styles.validateCodeForm}
                            >
                                <Field
                                    component={ValidatedFormInputField}
                                    type="text"
                                    name="code"
                                    variant={reAuthenticateStatus?.theme?.textInputVariant}
                                    label="Enter code"
                                    className={styles.verifyCodeInput}
                                />

                                <p className={styles.attemptText}>
                                    {verifyMethodData?.attempts}/
                                    {reAuthenticateStatus?.allowedAttempts} attempts left
                                </p>

                                <Button
                                    block
                                    variant={setButtonVariant(
                                        reAuthenticateStatus?.theme?.button?.variant
                                    )}
                                    className={styles.submitCodeBtn}
                                    type="submit"
                                    disabled={!dirty}
                                >
                                    {verifyMethod === 'Email' ? 'Verify email' : 'Verify phone'}
                                </Button>
                            </Form>
                        </Card.Body>
                    </>
                )}
            </Card>
        </Background>
    );
}

VerifyEmailPhone.propTypes = {
    profile: PropTypes.any,
    handleSubmit: PropTypes.func,
    dirty: PropTypes.bool,
    reAuthenticateStatus: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    reAuthenticateSessionId: PropTypes.string.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    hideCodeResendLink: PropTypes.bool.isRequired,
    setHideCodeResendLink: PropTypes.func.isRequired,
};

export default VerifyEmailPhone;
