import React from 'react';
import PropTypes from 'prop-types';
import styles from '../VerificationQuestions.module.scss';
import Button from 'core-components/Button';

function KbaErrorMsg({ errMsg, handleShow, cta }) {
    return (
        <div>
            <p className={styles.error_message}>{errMsg}</p>
            {cta && (
                <Button onClick={handleShow} block variant="link">
                    Confirm User Information to Proceed
                </Button>
            )}
        </div>
    );
}

KbaErrorMsg.propTypes = {
    errMsg: PropTypes.string.isRequired,
    handleShow: PropTypes.func,
    cta: PropTypes.bool,
};

export default KbaErrorMsg;
