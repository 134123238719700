import React from 'react';
import Form from 'react-bootstrap/Form';
import { uniqBy } from 'lodash';

export default props => {
  const { items } = props;
  items &&
    props.placeholder &&
    items.unshift({ label: props.placeholder, value: '' });

  return (
    <div style={{ position: 'relative' }}>
      <Form.Control
        as="select"
        {...props}
      >
        {uniqBy(items, 'value').map((item, i) => (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        ))}
      </Form.Control>
    </div>
  );
};
