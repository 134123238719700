import { connect } from 'react-redux';
import { withFormik } from 'formik';
import validationSchema from './OnboardingForm.validation';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'utils/utilities';
import { createToken, verifyCaptcha } from 'actions/session';
import React from 'react';

const initialValues = {
    firstName: 'First',
    middleName: 'Middle',
    noMiddleName: false,
    lastName: 'Last',
    email: '',
    dob: '07/03/2003',
    accept: false,
    passcode: false,
    phoneNumber: '',
    countryCode: '1',
    // companyName: '',
    // jobTitle: '',
    captcha: '',
    verificationOption: 'EITHER',
    captchaRef: React.createRef(),
};

const mapDispatchToProps = (dispatch) => {
    return {
        createToken: (data) => dispatch(createToken(data)),
        verifyCaptcha: (data) => dispatch(verifyCaptcha(data)),
    };
};

const mapStateToProps = (state) => {
    return {};
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: () => initialValues,
        enableReinitialize: true,
        validationSchema,
        handleSubmit: (values, { setErrors, setSubmitting, props }) => {
            const d = new Date(values.dob);
            const dob = [
                ('0' + d.getDate()).slice(-2),
                ('0' + (d.getMonth() + 1)).slice(-2),
                d.getFullYear(),
            ].join('-');
            props
                .verifyCaptcha(values.captcha)
                .then((response) => {
                    response &&
                        props
                            .createToken({
                                email: values.email,
                                firstName: values.firstName,
                                middleName: values.middleName,
                                lastName: values.lastName,
                                dob: dob,
                                phone: !!values.phoneNumber
                                    ? `+${values.countryCode}${values.phoneNumber}`
                                    : null,
                                // companyName: values.companyName,
                                // title: values.jobTitle,
                                verificationOption: values.verificationOption,
                            })
                            .then((result) => {
                                setSubmitting(false);
                                props.setToken(result.data.token, {
                                    email: values.email,
                                    phone: `+${values.countryCode}${values.phoneNumber}`,
                                });
                            })
                            .catch((error) => {
                                toast.error(getErrorMessage(error));
                                setSubmitting(false);
                            });
                })
                .catch((error) => {
                    console.error(error);
                    values.captchaRef.current.reset();
                    setSubmitting(false);
                    setErrors({
                        captcha: 'Captcha verification failed. Please try again.',
                    });
                });
        },
        displayName: 'OnboardingForm',
    })
);
