import http from './http';

export const getBankConnectUrlAPI = (data, token) => {
    let config = {
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
        },
    };
    return http.post('/identity/bank/connect/url', data, config);
};

export const getFinicityResultAPI = (userAccessCode, token) => {
    let config = {
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
        },
    };
    return http.post(
        '/identity/bank/account/result',
        {
            userAccessCode,
        },
        config
    );
};
