import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles/reauthenticateScreens.module.css';
import Background from 'core-components/Background';
import { connect } from 'react-redux';
import { reAuthenticateSessionUpdate } from 'actions/reAuthenticate';
import { REAUTHENTICATE_STATUS } from 'utils/constants';
import AllAttemptsUsed from 'components/AllAttemptsUsed';
import { allAttemptsUsedVariants } from 'utils/constants/allAttemptsUsed';

function SelfieFailed({
    theme,
    reAuthenticateStatus,
    reAuthenticateSessionId,
    reAuthenticateSessionUpdate,
}) {
    // When selfie is failed, this screen will be shown to desktop and mobile, in desktop state is not updated due to session storage is used, so calling status api on first load.
    useEffect(() => {
        reAuthenticateSessionId && reAuthenticateSessionUpdate(reAuthenticateSessionId);
    }, [reAuthenticateSessionId, reAuthenticateSessionUpdate]);

    const selfieVerification = reAuthenticateStatus?.selfieVerification || {};

    const isReauthenticateFailed = selfieVerification?.status === REAUTHENTICATE_STATUS.FAILED;

    const handleRedirect = () => {
        window.location.href = reAuthenticateStatus.redirectUrl;
    };

    const displayRedirectBtn = reAuthenticateStatus?.redirectUrl?.length;

    return (
        <Background className={styles.containerWrapper}>
            <AllAttemptsUsed
                title="Selfie ReAuthentication Failed"
                titleAlignment="center"
                description={allAttemptsUsedVariants.CONTACT_ADMIN}
                showBtn={isReauthenticateFailed && displayRedirectBtn}
                btnText={`Continue to ${reAuthenticateStatus.companyName}`}
                onClick={handleRedirect}
                variant={theme?.button.variant}
            />
        </Background>
    );
}

SelfieFailed.propTypes = {
    theme: PropTypes.object,
    reAuthenticateStatus: PropTypes.object.isRequired,
    reAuthenticateSessionId: PropTypes.string.isRequired,
    reAuthenticateSessionUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        reAuthenticateSessionId: state?.reAuthenticate?.reAuthenticateSessionID,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        reAuthenticateSessionUpdate: (sessionID) =>
            dispatch(reAuthenticateSessionUpdate(sessionID)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfieFailed);
