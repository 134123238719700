import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'core-components/Button';
import styles from '../DeviceLinks.module.scss';
import Alert from 'react-bootstrap/Alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { setButtonVariant } from 'utils/utilities';

export default (props) => {
    const [copied, setCopied] = React.useState(false);

    if (copied) {
        setTimeout(() => setCopied(false), 2000);
    }
    return (
        <React.Fragment>
            <Card.Body style={{ padding: '24px 0px 0px' }} className={styles.body}>
                <h4 className={styles.PassportCardHeading}>Copy Link</h4>
                <p className={styles.PassportCardSubheading}>
                    Copy and open this link on your phone
                </p>

                <Alert.Link href={props.link} className={styles.link}>
                    {props.link}
                </Alert.Link>
            </Card.Body>

            <Card.Footer
                style={{
                    padding: '70px 0px 0px',
                    border: 'none',
                    background: 'none',
                }}
                className="text-muted"
            >
                <CopyToClipboard text={props.link} onCopy={() => setCopied(true)}>
                    <Button
                        disabled={copied}
                        block
                        variant={setButtonVariant(props.theme?.button.variant)}
                        className={styles.passportFooterBtn}
                    >
                        {copied ? 'Copied' : 'Copy Link'}
                    </Button>
                </CopyToClipboard>
                {props.verificationOption === 'OR' && (
                    <>
                        <Button
                            onClick={() =>
                                props.history.push({
                                    pathname: '/verification',
                                    state: { noDevice: true },
                                })
                            }
                            block
                            variant="link"
                        >
                            Don't have a valid ID or passport and a device?
                        </Button>
                    </>
                )}
            </Card.Footer>
        </React.Fragment>
    );
};
