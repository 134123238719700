import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Field, FieldArray, ErrorMessage } from 'formik';
import styles from '../VerificationQuestions.module.scss';
import ValidatedRadioFields from 'core-components/ValidatedRadioFields';

function RenderVerificationQue({ idmkbaResponse }) {
    return (
        <div>
            <FieldArray
                name="answers"
                render={(arrayHelpers) => (
                    <div>
                        {idmkbaResponse.kbaQuestion.map((question, index) => (
                            <div key={question.questionId}>
                                <Form.Label className={styles.question}>
                                    {question.question}
                                </Form.Label>
                                <span className={styles.questionError}>
                                    <ErrorMessage name={`answers[${index}]`} />
                                </span>
                                <Field
                                    component={ValidatedRadioFields}
                                    name={`answers.${index}`}
                                    options={question.options.map((option) => {
                                        return {
                                            value: option.id,
                                            label: option.option,
                                        };
                                    })}
                                />
                            </div>
                        ))}
                    </div>
                )}
            />
        </div>
    );
}

RenderVerificationQue.propTypes = {
    idmkbaResponse: PropTypes.object.isRequired,
};

export default RenderVerificationQue;
