/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import ValidatedFormInputField from 'core-components/ValidatedFormInputField';
import Background from 'core-components/Background';
import { Field } from 'formik';
import ValidatedPhoneNumber from 'core-components/ValidatedPhoneNumber';
import Button from 'core-components/Button';
import Checkbox from 'core-components/Checkbox';
import Card from 'react-bootstrap/Card';
import ValidatedRecaptcha from 'core-components/ValidatedRecaptcha';
import Media from 'react-bootstrap/Media';
import styles from './OnboardingForm.module.scss';
import { ReactComponent as Mail } from 'images/input_mail.svg';
import { useDispatch } from 'react-redux';
import { updateProfile } from 'actions/profile';

const OnboardingForm = (props) => {
    const { handleSubmit, isSubmitting, values } = props;
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(
            updateProfile({
                company: {
                    name: 'Authenticating.com',
                },
            })
        );
    }, []);

    return (
        <Background className={styles.onboardingCard}>
            <Card.Header className={styles.cardheader}>
                <Media>
                    <Media.Body>
                        <Card.Title className={styles.title}>
                            Initiate Identity Authentication
                        </Card.Title>
                    </Media.Body>
                </Media>
            </Card.Header>
            <p className={styles.cardDescription}>
                This is only meant to be a live demonstration of Medallion™. Enter the mobile number
                for who is to be verified, and an email address for where the results should be
                sent.
            </p>
            <Form onSubmit={handleSubmit}>
                <Card.Body className={styles.cardBody}>
                    <Field
                        component={ValidatedFormInputField}
                        type="text"
                        variant="outlined"
                        StartAdornmentIcon={Mail}
                        name="email"
                        placeholder="your@email.com"
                        label="Email Address for One-time Passcode and Results *"
                        className={styles.input}
                    />
                    <Field
                        name="phoneNumber"
                        component={ValidatedPhoneNumber}
                        label="Phone Number for Person to be Verified *"
                        block
                        className={styles.input}
                    />
                    <hr />
                    <Field
                        component={Checkbox}
                        custom
                        name="accept"
                        className={styles.checkBox}
                        label={
                            <div style={{ fontSize: '14px', color: '#374151' }}>
                                <span>I agree to </span>
                                <a
                                    href={'https://authenticating.com'}
                                    target="_blank"
                                    style={{ fontWeight: '500', textDecoration: 'underline' }}
                                >
                                    Authenticating.com
                                </a>
                                <span>
                                    's{' '}
                                    <a
                                        href={'https://authenticating.com/terms-of-use.html'}
                                        target="_blank"
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href={'https://authenticating.com/privacy-policy.html'}
                                        target="_blank"
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Privacy Policy
                                    </a>
                                    .
                                </span>
                            </div>
                        }
                    />
                    <Field
                        component={Checkbox}
                        custom
                        name="passcode"
                        className={styles.checkBox}
                        label={
                            <div style={{ fontSize: '14px', color: '#374151' }}>
                                <span>
                                    I authorize{' '}
                                    <a
                                        href={'https://authenticating.com/'}
                                        target="_blank"
                                        style={{
                                            fontWeight: '500',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Authenticating.com
                                    </a>{' '}
                                    to email me a one-time passcode for initiating this verification
                                    request (as well as the verification results) and to send a
                                    tokenized link by mobile text message to the phone number
                                    provided above to facilitate this verification request.
                                </span>
                            </div>
                        }
                    />
                    <Field
                        render={(props) => (
                            <ValidatedRecaptcha {...props} ref={values.captchaRef} />
                        )}
                        name="captcha"
                    />
                </Card.Body>
                <Card.Footer bsPrefix={styles.cardFooter}>
                    <Button
                        block
                        className={styles.proceedBtn}
                        disabled={!props.values.passcode || !props.values.accept || isSubmitting}
                        variant="primary"
                        type="submit"
                    >
                        Proceed
                    </Button>
                </Card.Footer>
            </Form>
        </Background>
    );
};

OnboardingForm.propTypes = {
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    values: PropTypes.object,
};

export default OnboardingForm;
