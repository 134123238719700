import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { startSessionTimer, resetSessionTimer } from 'actions/general';
import moment from 'moment';
import styles from './SessionTimer.module.css';
import { useHistory, useLocation } from 'react-router';
import Loader from 'core-components/Loader';
import SandClock from 'assets/images/sandclock.gif';
import { REAUTHENTICATE_ROUTE, REAUTHENTICATE_ROUTES } from 'utils/constants';
import { convertSingleToDoubleDigit } from 'utils/utilities';

function SessionTimer({ sessionTimer }) {
    const history = useHistory();
    const location = useLocation();

    const isReAuthenticateRoute = location?.pathname?.includes(REAUTHENTICATE_ROUTE);

    const [currentTime, setCurrentTime] = useState(moment());
    const timeBetween = sessionTimer && moment.duration(moment(sessionTimer).diff(currentTime));

    useEffect(() => {
        if (sessionTimer) {
            const interval = setInterval(() => {
                setCurrentTime(moment());
            }, 1000);

            if (timeBetween.get('minutes') === 0 && timeBetween.get('seconds') === 0) {
                clearInterval(interval);
                resetSessionTimer();
                if (isReAuthenticateRoute) {
                    history.push(REAUTHENTICATE_ROUTES.SELFIE_TIMEOUT);
                } else {
                    history.push('/mobile/idTimeout');
                }
            }

            return () => clearInterval(interval);
        }
    }, [timeBetween, sessionTimer, history, isReAuthenticateRoute]);

    return sessionTimer ? (
        <div className={styles.count_down_wrapper}>
            <img src={SandClock} alt="sand clock gif" className={styles.clock_img} />
            <p className={styles.count_down_value}>
                {convertSingleToDoubleDigit(timeBetween.get('minutes'))} :{' '}
                {convertSingleToDoubleDigit(timeBetween.get('seconds'))}
            </p>
        </div>
    ) : (
        <Loader />
    );
}

SessionTimer.propTypes = {
    sessionTimer: PropTypes.any,
};

function mapStateToProps(state) {
    return {
        sessionTimer: state.general?.sessionTimer,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        startSessionTimer: (data) => dispatch(startSessionTimer(data)),
        resetSessionTimer: () => dispatch(resetSessionTimer()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionTimer);
