import React, { useState, Fragment, useEffect } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Field, FieldArray } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
// components
import styles from './ProfessionalLicense.module.scss';
import Button from 'core-components/Button';
import ValidatedDate from 'core-components/ValidatedDate';
import ValidatedFormInputField from 'core-components/ValidatedFormInputField';
import ValidatedDropdown from 'core-components/ValidatedDropdown';
import Checkbox from 'core-components/Checkbox';
import Loader from 'core-components/Loader';
import Background from 'core-components/Background';
import Image from 'core-components/Image';
import CreatableAsyncAll from 'core-components/CreatableSelect/AsyncSelect';
import DeleteWarningPopup from 'core-components/DeleteWarningPopup';
import states from 'utils/constants/states';
import country from 'utils/constants/country';
import { emptyLicenseFormValues, dateMask } from 'utils/constants';
// assets
import { ReactComponent as Delete } from 'images/Close.svg';

export default ({
    show,
    handleShow,
    handleClose,
    profile,
    renderLastStep,
    renderNextStep,
    isLastPoint,
    isPreviousFormSubmitted,
    ...props
}) => {
    const { status, handleSubmit, isSubmitting, values, verificationStep, location, history } =
        props;

    const fetchTitle = async (inputValue, professionalLicense) => {
        const { data } = await props.getTitle(
            profile.company.user.userDetails.uuid,
            professionalLicense.state,
            inputValue
        );
        return data;
    };

    const [collapse, setCollapse] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const isProfessionalLicenseRecorded =
        profile?.company?.user?.verificationDetails?.isProfessionalLicenseRecorded;

    useEffect(() => {
        isProfessionalLicenseRecorded && renderNextStep();
    }, [isProfessionalLicenseRecorded, renderNextStep]);

    useEffect(() => {
        isLastPoint && renderLastStep();
    }, [isLastPoint, renderLastStep]);

    const deleteProfessionalLicense = (professionalLicense, pop) => {
        const isFormEdited =
            professionalLicense.organizationName ||
            professionalLicense.certificationNumber ||
            professionalLicense.end ||
            professionalLicense.start ||
            professionalLicense.state ||
            professionalLicense.title ||
            professionalLicense.isCurrent;

        if (isFormEdited) {
            setShowModal(true);
        } else {
            setCollapse(collapse - 1);
            pop();
        }
    };

    const handleOpenForm = (index) => setCollapse(index);

    const handleDeleteForm = (e, data, pop) => {
        e.stopPropagation();
        deleteProfessionalLicense(data, pop);
    };

    const handleSkip = () => {
        saveFormToLocal();
        renderNextStep();
    };

    const saveFormToLocal = () => {
        sessionStorage.setItem('professionalLicense', JSON.stringify(values.professionLisences));
    };

    const displayBackButton =
        location.pathname !== profile?.defaultRoute[2] &&
        !isPreviousFormSubmitted(location.pathname);

    return (
        <>
            <Background className={styles.wrapper}>
                <Card.Header className={styles.licenseHeader}>
                    {displayBackButton && (
                        <Alert.Link
                            className={styles.backArrow}
                            onClick={() => {
                                saveFormToLocal();
                                history.goBack();
                            }}
                        >
                            <Image name="Back.svg" />
                        </Alert.Link>
                    )}

                    <div>
                        <Card.Title className={styles.heading}>
                            Professional License Verification
                        </Card.Title>
                        <Card.Text className={styles.subHeading}>{verificationStep}</Card.Text>
                    </div>
                </Card.Header>

                <Form onSubmit={handleSubmit}>
                    <FieldArray
                        name="professionLisences"
                        render={({ pop, push, form }) => (
                            <>
                                {status ? (
                                    <Loader />
                                ) : (
                                    <>
                                        {values.professionLisences.map(
                                            (professionalLicense, index, professionLisences) => (
                                                <Fragment key={index}>
                                                    <DeleteWarningPopup
                                                        showModal={showModal}
                                                        setShowModal={setShowModal}
                                                        collapse={collapse}
                                                        setCollapse={setCollapse}
                                                        data={professionLisences}
                                                        pop={pop}
                                                        isSubmitting={isSubmitting}
                                                        name="Professional License"
                                                    />
                                                    <div
                                                        className={cn(
                                                            styles.sectionHeader,
                                                            collapse !== index &&
                                                                styles.closeSectionHeader
                                                        )}
                                                        onClick={() => handleOpenForm(index)}
                                                    >
                                                        <p className={styles.formTitle}>
                                                            Professional License {index + 1}
                                                        </p>
                                                        {professionLisences?.length - 1 === index &&
                                                            index !== 0 &&
                                                            collapse ===
                                                                professionLisences?.length - 1 && (
                                                                <Button
                                                                    className={styles.deleteBtn}
                                                                    onClick={(e) =>
                                                                        handleDeleteForm(
                                                                            e,
                                                                            professionalLicense,
                                                                            pop
                                                                        )
                                                                    }
                                                                >
                                                                    <Delete />
                                                                </Button>
                                                            )}
                                                    </div>
                                                    <div>
                                                        <Collapse in={index === collapse}>
                                                            <Card.Body
                                                                className={styles.cardBody}
                                                                key={index}
                                                            >
                                                                <Form.Row>
                                                                    <Col xs="12">
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            License Organization
                                                                        </Form.Label>
                                                                        <Field
                                                                            placeholder="Organization name"
                                                                            component={
                                                                                ValidatedFormInputField
                                                                            }
                                                                            name={`professionLisences.${index}.organizationName`}
                                                                            type="text"
                                                                            variant="outlined"
                                                                            defaultValue={
                                                                                professionLisences[
                                                                                    index
                                                                                ].organizationName
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col xs="12">
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            Country
                                                                        </Form.Label>
                                                                        <Field
                                                                            component={
                                                                                ValidatedDropdown
                                                                            }
                                                                            name={`professionLisences.${index}.country`}
                                                                            items={country}
                                                                            disabled={true}
                                                                        />
                                                                    </Col>
                                                                    <Col xs="12">
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            State
                                                                        </Form.Label>
                                                                        <Field
                                                                            placeholder="State name"
                                                                            component={
                                                                                ValidatedDropdown
                                                                            }
                                                                            name={`professionLisences.${index}.state`}
                                                                            items={states}
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        xs={12}
                                                                        className={
                                                                            styles.searchField
                                                                        }
                                                                    >
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            License or Certification
                                                                            Title
                                                                        </Form.Label>
                                                                        <Field
                                                                            component={
                                                                                CreatableAsyncAll
                                                                            }
                                                                            asyncMethod={(
                                                                                inputValue
                                                                            ) =>
                                                                                fetchTitle(
                                                                                    inputValue,
                                                                                    professionalLicense
                                                                                )
                                                                            }
                                                                            isDisabled={
                                                                                !professionalLicense.state
                                                                            }
                                                                            name={`professionLisences.${index}.title`}
                                                                            placeholder="License or certification title"
                                                                        />
                                                                    </Col>
                                                                    <Col
                                                                        xs={12}
                                                                        className={
                                                                            styles.searchField
                                                                        }
                                                                    >
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            License or Certification
                                                                            Number
                                                                        </Form.Label>
                                                                        <Field
                                                                            component={
                                                                                ValidatedFormInputField
                                                                            }
                                                                            type="text"
                                                                            variant="outlined"
                                                                            name={`professionLisences.${index}.certificationNumber`}
                                                                            placeholder="License or certification number"
                                                                            defaultValue={
                                                                                professionLisences[
                                                                                    index
                                                                                ]
                                                                                    .certificationNumber
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Form.Row>

                                                                <Form.Row>
                                                                    <Col xs="12">
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            Start and End Date{' '}
                                                                            <span>
                                                                                (must be exact month
                                                                                and year)
                                                                            </span>
                                                                            *
                                                                        </Form.Label>
                                                                    </Col>
                                                                </Form.Row>

                                                                <Form.Row
                                                                    className={styles.dateRow}
                                                                >
                                                                    <Col md="6" xs="6">
                                                                        <Field
                                                                            component={
                                                                                ValidatedDate
                                                                            }
                                                                            labelClass={
                                                                                styles.formLabel
                                                                            }
                                                                            name={`professionLisences.${index}.start`}
                                                                            label="Start"
                                                                            maxDate={moment().toDate()}
                                                                            showMonthYearPicker
                                                                            dateFormat="MM/yyyy"
                                                                            example="MM/YYYY"
                                                                            mask={dateMask}
                                                                        />
                                                                    </Col>
                                                                    <Col md="6" xs="6">
                                                                        <Field
                                                                            component={
                                                                                ValidatedDate
                                                                            }
                                                                            labelClass={
                                                                                styles.formLabel
                                                                            }
                                                                            disabled={
                                                                                professionalLicense.isCurrent
                                                                            }
                                                                            name={`professionLisences.${index}.end`}
                                                                            minDate={moment(
                                                                                professionalLicense.start
                                                                            ).toDate()}
                                                                            maxDate={moment().toDate()}
                                                                            label="End"
                                                                            showMonthYearPicker
                                                                            dateFormat="MM/yyyy"
                                                                            example="MM/YYYY"
                                                                            mask={dateMask}
                                                                        />
                                                                    </Col>
                                                                    <Field
                                                                        component={Checkbox}
                                                                        custom
                                                                        name={`professionLisences.${index}.isCurrent`}
                                                                        className={styles.checkBox}
                                                                        label="Current"
                                                                    />
                                                                </Form.Row>
                                                            </Card.Body>
                                                        </Collapse>
                                                    </div>
                                                </Fragment>
                                            )
                                        )}
                                    </>
                                )}

                                <Card.Footer className={styles.licenseFooter}>
                                    <Button
                                        variant="outline-primary"
                                        disabled={isSubmitting}
                                        className={styles.footerBtn}
                                        onClick={() => {
                                            saveFormToLocal();
                                            push(emptyLicenseFormValues);
                                            setCollapse(collapse + 1);
                                        }}
                                    >
                                        Add Professional License
                                    </Button>
                                    <Button
                                        variant="primary"
                                        className={styles.footerBtn}
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Done
                                    </Button>
                                </Card.Footer>
                            </>
                        )}
                    />
                </Form>
            </Background>
            <div className={styles.skipBtnContainer}>
                <Button
                    variant="link"
                    disabled={isSubmitting}
                    onClick={handleSkip}
                    className={styles.skipBtn}
                >
                    Skip
                </Button>
            </div>
        </>
    );
};
