import { connect } from 'react-redux';
import { getProfile } from 'actions/profile';
import { setStatus } from 'actions/status';
import { resetKBA } from 'actions/identity';
import { resetDocumentAttempt } from 'actions/common';
import {
    actions as finicityResultActions,
    selectors as resultSelectors,
    getFincityResult,
} from 'actions/bank';

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        session: state.session,
        identity: state.identity,
        common: state.common,
        verifications: state.profile?.workflow?.idVerifications,
        verificationConfiguration: state.general.verificationConfiguration,
        isPollingActive: resultSelectors.isPollingActive(state),
        lastPoint: resultSelectors.getLastPoint(state),
        count: resultSelectors.getCount(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
        setStatus: (status) => dispatch(setStatus(status)),
        resetKBA: () => dispatch(resetKBA()),
        resetAttempt: () => dispatch(resetDocumentAttempt()),
        getFincityResult: (uac) => dispatch(getFincityResult(uac)),
        stopPolling: () => dispatch(finicityResultActions.stop()),
        resetPolling: () => dispatch(finicityResultActions.reset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps);
