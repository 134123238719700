import FaceMatchService from 'services/api/faceMatch';
import { trackEvent, trackEventDuration } from 'api/productAnalytics';
import { FACE_MATCH_THRESHOLD_SCORE } from 'utils/constants/selfie';
import { eventNames, INSTRUCTION_TYPES, VERIFICATION_FLOWS } from 'utils/constants';
import moment from 'moment';

const useNormalFlow = ({
    data,
    selfie,
    setLoading,
    canRetry,
    tries,
    dispatch,
    history,
    uac,
    livenessCheckEnabled,
    instanceID,
    processID,
    setShowInstructions,
    liveness,
    lastUsedInstruction,
}) => {
    const processNormalFlowSelfie = async () => {
        try {
            if (data !== null) {
                if (data.Photo.split(',')[1] !== undefined) {
                    if (selfie !== null) {
                        const res = await FaceMatchService.processFaceMatch({
                            Data: {
                                ImageOne: data.Photo.split(',')[1],
                                ImageTwo: selfie,
                            },
                            Settings: {
                                SubscriptionId: process.env.REACT_APP_SUBSCRIPTION_ID,
                            },
                        });

                        if (setShowInstructions) {
                            dispatch(setShowInstructions(false));
                        }

                        setLoading(false);

                        const livenessData = liveness?.LivenessResult ?? {};

                        trackEvent({
                            event: eventNames.captureSelfie,
                            uac: uac,
                            uniqueOnly: false,
                            otherValues: {
                                'Completion Time': moment().format('YYYY-MM-DD HH:mm:ss'),
                                'Liveness Score': livenessData.Score,
                                'Face Match Score': res.Score,
                                'Liveness Assessment': livenessData.LivenessAssessment,
                                'Face Matched': res.IsMatch,
                                'Verification Flow': VERIFICATION_FLOWS.NORMAL,
                                'Selfie Instruction Used':
                                    lastUsedInstruction === INSTRUCTION_TYPES.SELFIE,
                                Attempts: tries + 1,
                            },
                        });

                        if (res.Score >= FACE_MATCH_THRESHOLD_SCORE || !canRetry(tries + 1)) {
                            dispatch({
                                payload: res.Score,
                                type: '@@acuant/ADD_FACE_MATCH_DATA',
                            });
                            history.push('/mobile/results/default', {
                                selfie: selfie,
                            });
                        }
                    }
                } else {
                    history.push('/mobile/results/default');
                }
            }
        } catch (err) {
            if (setShowInstructions) {
                dispatch(setShowInstructions(false));
            }
            throw new Error(err);
        }
    };

    const initNormalFlow = () => {
        //if liveness check is not enabled, redirect to the results screen
        if (!livenessCheckEnabled) {
            history.push('/mobile/results/default');
        } else {
            processID(instanceID);
        }
        trackEventDuration({
            event: eventNames.captureSelfie,
            uac: uac,
        });
    };

    return {
        initNormalFlow,
        processNormalFlowSelfie,
    };
};

export default useNormalFlow;
