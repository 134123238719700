export const FACE_MATCH_THRESHOLD_SCORE = 80;

export const TRIES_THRESHOLD = 1;

export const faceDetectionStates = {
    FACE_NOT_FOUND: 'FACE NOT FOUND',
    TOO_MANY_FACES: 'TOO MANY FACES',
    FACE_ANGLE_TOO_LARGE: 'FACE ANGLE TOO LARGE',
    PROBABILITY_TOO_SMALL: 'PROBABILITY TOO SMALL',
    FACE_TOO_SMALL: 'FACE TOO SMALL',
    FACE_CLOSE_TO_BORDER: 'TOO CLOSE TO THE FRAME',
};

export const customSelfieErr = {
    FaceTooSmall: 'Your face appears too small in the picture.',
};
