/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
// styles
import styles from './VerificationQuestions.module.scss';
// components
import Background from 'core-components/Background';
import Button from 'core-components/Button';
import Loader from 'core-components/Loader';
import Image from 'core-components/Image';
import CountDown from 'core-components/CountDown';
import { extractIdVerification, setButtonVariant } from 'utils/utilities';
import { trackEventDuration } from 'api/productAnalytics';
import { eventNames } from 'utils/constants';
import { useBeforeUnload } from 'hooks/useBeforeUnload';
import KbaQuestions from './components/KbaQuestions';

const VerificationQuestions = (props) => {
    const {
        handleShow,
        handleSubmit,
        isSubmitting,
        profile,
        kba: { loading, idmkbaResponse, error },
        companyUUID,
    } = props;

    const [countDown, setCountDown] = useState('');

    useBeforeUnload();

    const isError =
        error || !(idmkbaResponse?.kbaQuestion && idmkbaResponse.kbaQuestion.length > 0);

    const {
        KBA: {
            metaData: { allAttemptsUsed },
        },
    } = extractIdVerification(props);

    const isTimeOut = countDown === 'time out';

    const handleTimeOut = () => {
        setCountDown('time out');
    };

    useEffect(() => {
        if (!profile.company) return;

        !allAttemptsUsed && props?.getKBAQuestions(profile.company.user.userDetails.uuid);

        allAttemptsUsed && props.history.replace('/verification');
    }, []);

    useEffect(() => {
        if (error === '417') {
            props.history.replace('/verification', {
                redirectTo: props.location.pathname + props.location.search,
            });
        }
    }, [error]);

    useEffect(() => {
        if (!loading) {
            const { idmkbaResponse } = props.kba;
            if (idmkbaResponse?.kbaQuestion?.length > 0) {
                trackEventDuration({
                    event: eventNames.kba,
                    uac: props.userAccessCode,
                });
                setCountDown('start');
            }
        }
    }, [loading]);

    const specificUUID = [
        '9c40d0b2-5e45-4b3e-b8cd-492098997340',
        '19a89196-c407-451d-8e93-7d9affc44535',
        '7219f2d7-3da8-4e7e-8655-ec1376f8a247',
    ].includes(companyUUID);

    const initialMinute = specificUUID ? 2 : 6;

    return (
        <Background className={styles.wrapper} wrapper={loading && styles.wrapperLoader}>
            <Card.Header as="h5" className={styles.cardHeader}>
                {!loading && (
                    <Alert.Link className={styles.backArrow} onClick={() => window.history.back()}>
                        <Image name="Back.svg" />
                    </Alert.Link>
                )}
                Knowledge Authentication Quiz
                {!loading && (
                    <CountDown
                        initialMinute={initialMinute}
                        handleTimeOut={handleTimeOut}
                        status={countDown}
                    />
                )}
            </Card.Header>
            <Form onSubmit={handleSubmit}>
                <Card.Body className={styles.cardBody}>
                    <div>
                        {loading ? (
                            <Loader />
                        ) : (
                            <KbaQuestions
                                error={error}
                                idmkbaResponse={idmkbaResponse}
                                handleShow={handleShow}
                                isTimeOut={isTimeOut}
                            />
                        )}
                    </div>
                </Card.Body>
                <Card.Footer
                    style={{ background: 'none', border: 'none', padding: '0px 44px 44px' }}
                    className="text-muted"
                >
                    {isError || isTimeOut ? (
                        <Button
                            block
                            variant={setButtonVariant(profile.company?.theme.button.variant)}
                            onClick={() => props.history.goBack()}
                            className={styles.footerBtn}
                        >
                            Go Back
                        </Button>
                    ) : (
                        <Button
                            block
                            disabled={isSubmitting}
                            variant={setButtonVariant(profile.company?.theme.button.variant)}
                            type="submit"
                            className={styles.footerBtn}
                        >
                            Submit Answers
                        </Button>
                    )}
                </Card.Footer>
            </Form>
        </Background>
    );
};

VerificationQuestions.propTypes = {
    handleShow: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    profile: PropTypes.object,
    kba: PropTypes.object,
    companyUUID: PropTypes.string,
    getKBAQuestions: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    userAccessCode: PropTypes.string,
};

export default VerificationQuestions;
