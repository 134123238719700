import {
    UPDATE_SHOW_INSTRUCTIONS,
    SET_INSTRUCTION_TYPE,
    SET_LAST_USED_INSTRUCTION_TYPE,
} from 'actions';

const INITIAL_STATE = {
    showInstructions: false,
    instructionType: null,
    lastUsedInstructionType: null,
};

export function InstructionsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_SHOW_INSTRUCTIONS:
            return {
                ...state,
                showInstructions: action.payload,
            };
        case SET_INSTRUCTION_TYPE:
            return {
                ...state,
                instructionType: action.payload,
            };

        case SET_LAST_USED_INSTRUCTION_TYPE:
            return {
                ...state,
                lastUsedInstructionType: action.payload,
            };
        default:
            return state;
    }
}
