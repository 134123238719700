import React from 'react';
import ContentArea from '../ContentArea';
import ScrollToTop from './ScrollToTop';

export default (props) => {
    return (
        <React.Fragment>
            <ScrollToTop>
                <ContentArea />
            </ScrollToTop>
        </React.Fragment>
    );
};
