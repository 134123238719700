import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './index.module.css';
import Carousel from './carousal/carousel-body';
import Footer from './carousal/carousal-footer';
import { useDispatch, useSelector } from 'react-redux';
import {
    setShowInstructions,
    setInstructionType,
    setLastUsedInstructionType,
} from 'actions/instructions';
import { ReactComponent as CloseIcon } from '../../images/icon-cross.svg';
import { IconButton } from '@material-ui/core';

const Instructions = ({ handleEndProcess, data, endBtnText }) => {
    const { instructionType } = useSelector((state) => state.instructions);

    const dispatch = useDispatch();
    const [activeSlide, setActiveSlide] = useState(0);
    const [slideDirection, setSlideDirection] = useState('left');
    const touchStartX = useRef(null);
    const touchStartY = useRef(null);

    const isLastSlideActive = activeSlide === data.length - 1;

    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
        touchStartY.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
        if (!touchStartX.current || !touchStartY.current) return;

        const currentX = event.touches[0].clientX;
        const currentY = event.touches[0].clientY;
        const deltaX = currentX - touchStartX.current;
        const deltaY = currentY - touchStartY.current;

        // If horizontal swipe
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            event.preventDefault(); // Prevent vertical scroll

            if (deltaX > 0) {
                setSlideDirection('right');
                setActiveSlide((prevSlide) => (prevSlide === 0 ? prevSlide : prevSlide - 1));
            } else {
                setSlideDirection('left');
                setActiveSlide((prevSlide) =>
                    prevSlide === data.length - 1 ? prevSlide : prevSlide + 1
                );
            }
        }

        // Reset touchStartX and touchStartY
        touchStartX.current = null;
        touchStartY.current = null;
    };

    const handleTouchEnd = () => {
        touchStartX.current = null;
        touchStartY.current = null;
    };

    const onClickNext = () => {
        setSlideDirection('left');
        setActiveSlide((prevState) => prevState + 1);
    };

    const closeInstructions = () => {
        dispatch(setShowInstructions(false));
        dispatch(setInstructionType(null));
        dispatch(setLastUsedInstructionType(instructionType));
    };

    const onClickClose = () => {
        closeInstructions();
    };

    const onClickSkip = () => {
        closeInstructions();
    };
    const onDotClick = (index) => {
        setSlideDirection('left');
        setActiveSlide(index);
    };

    return (
        <div
            className={classes.container}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <IconButton className={classes.crossIcon} onClick={onClickClose}>
                <CloseIcon />
            </IconButton>
            <Carousel activeSlide={activeSlide} slides={data} slideDirection={slideDirection} />
            <Footer
                activeSlide={activeSlide}
                isLastSlide={isLastSlideActive}
                itemsLength={data.length}
                onClickNext={onClickNext}
                onClickSkip={onClickSkip}
                onEndProcessClick={() => handleEndProcess()}
                onDotClick={onDotClick}
                instructionType={instructionType}
            />
        </div>
    );
};

Instructions.propTypes = {
    handleEndProcess: PropTypes.func,
    data: PropTypes.array,
};

export default Instructions;
