import { SET_DOCUMENT_ATTEMPT, RESET_DOCUMENT_ATTEMPT } from 'actions';

export function setDocumentAttempt(data) {
    return {
        type: SET_DOCUMENT_ATTEMPT,
        data,
    };
}

export function resetDocumentAttempt() {
    return {
        type: RESET_DOCUMENT_ATTEMPT,
    };
}
