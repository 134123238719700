import React from 'react';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './ValidatedRecaptcha.module.scss';
import PropTypes from 'prop-types';

const ValidatedRecaptcha = React.forwardRef(
    ({ field, form: { touched, errors, setFieldValue }, label, ...props }, ref) => {
        return (
            <Form.Group controlId={field.name}>
                <ReCAPTCHA
                    sitekey="6LfBFuYZAAAAAKAgAZk75IUQ6OKCfbCFCEb1_nT8"
                    onChange={(response) => {
                        setFieldValue(field.name, response);
                    }}
                    ref={ref}
                />
                {errors.captcha && touched.captcha && (
                    <p className={styles.error}>{errors.captcha}</p>
                )}
            </Form.Group>
        );
    }
);

ValidatedRecaptcha.propTypes = {
    field: PropTypes.object,
    form: PropTypes.object,
    ref: PropTypes.any,
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
    label: PropTypes.string,
};

export default ValidatedRecaptcha;
