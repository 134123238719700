import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import validationSchema from './Email.validation';
import { sendCommunication } from 'actions/communication';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'utils/utilities';

const initialValues = {
    email: '',
};

const mapStateToProps = (state) => {
    return {
        communication: state.communication,
        reAuthenticateSessionId: state.reAuthenticate?.reAuthenticateSessionID,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendCommunication: (type, data, sessionID) =>
            dispatch(sendCommunication(type, data, sessionID)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: () => initialValues,
        enableReinitialize: true,
        validationSchema,
        handleSubmit: (values, { setStatus, setSubmitting, props }) => {
            const { isReauthenticate, reAuthenticateSessionId } = props;
            const type = isReauthenticate ? 'REAUTHENTICATE_EMAIL' : 'EMAIL';
            props
                .sendCommunication(type, values, reAuthenticateSessionId)
                .then(() => {
                    setSubmitting(false);
                    // props.changeToNextRoute(1, props.history)
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error));
                    setSubmitting(false);
                });
        },
        displayName: 'EmailForm',
    })
);
