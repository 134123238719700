import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';
import cn from 'classnames';

// components
import Background from 'core-components/Background';
import styles from './KbaResult.module.scss';
import { getTestResult } from 'actions/profile';
import Loader from 'core-components/Loader';
import Button from 'core-components/Button';
import { setButtonVariant, getExtendedInputsArray } from 'utils/utilities';
import { identityProofKey } from 'utils/constants';
import ContinueVerification from 'components/ContinueVerification';

function KbaResult({
    profile,
    result,
    getTestResult,
    history,
    verificationConfiguration,
    kba,
    ...props
}) {
    useEffect(() => {
        profile?.company && getTestResult(profile.company?.user?.userDetails?.uuid);
    }, [profile.company, getTestResult]);

    const extendedInputs = profile?.company?.user?.extendedInputs;
    const isDocumentVerified = profile?.company?.user?.verificationDetails?.isDocumentValid;
    const isBankEnabled = profile?.workflow?.idVerifications?.bank?.enabled;
    const isGovIDEnabled = profile?.workflow?.idVerifications?.govtId?.enabled;

    // condition for bank available or not
    const isBankVerified = profile?.company?.user?.verificationDetails?.isBankOwnerMatched;
    const currentBankAttempts = profile?.company?.user?.verificationDetails?.bankAttempts;
    const totalBankAttempts = profile?.workflow?.idVerifications?.bank?.attempts;
    const isBankAttemptsUsed = currentBankAttempts >= totalBankAttempts;
    const isBankAvailable = isBankEnabled && !isBankAttemptsUsed && !isBankVerified;

    const extendedChecksArray = getExtendedInputsArray(extendedInputs);

    const descText = ' You will need to continue to complete the remaining verifications.';

    const quizOnlyMethod = verificationConfiguration === 'SINGLE' && kba?.enabled;

    const noVerificationLeft =
        (verificationConfiguration === 'OR' || quizOnlyMethod) && extendedChecksArray.length > 0
            ? descText
            : null;

    const kbaDescription =
        verificationConfiguration === 'AND' &&
        (!isDocumentVerified || isBankAvailable || extendedChecksArray.length > 0)
            ? descText
            : noVerificationLeft;

    const isDocumentAvailable = isGovIDEnabled && !isDocumentVerified;

    const showKbaResult = kba?.metadata?.showResult;

    const verificationLeft =
        verificationConfiguration === 'AND' && (isDocumentAvailable || isBankAvailable);

    const noVerificationAndEEPLeft = !verificationLeft && extendedChecksArray?.length === 0;

    const handleNextStep = () => {
        if (verificationLeft) {
            history.push('/verification');
        } else {
            history.push(profile?.defaultRoute[2]);
        }
    };

    useEffect(() => {
        if (!profile?.workflow) return;

        if (!showKbaResult && noVerificationAndEEPLeft) {
            history.push('/thank-you');
        }
    }, [profile, showKbaResult, noVerificationAndEEPLeft, history]);

    if (!result) {
        return <Loader />;
    }

    return (
        <Background className={styles.kbaWrapper}>
            <Card.Header className={styles.cardHeader}>
                Congratulations! You have been verified.
            </Card.Header>
            {showKbaResult ? (
                <Card.Body className={styles.cardBody}>
                    <div className={styles.messageBox}>
                        {kbaDescription && (
                            <p className={styles.displayMessage}>{kbaDescription}</p>
                        )}
                    </div>
                    <div className={styles.answerContainer}>
                        {result.identityProof &&
                            Object.entries(result.identityProof).map(([key, value]) => (
                                <div className={styles.answers} key={key}>
                                    <p>{identityProofKey[key]}</p>
                                    {value === true ? (
                                        <div className={cn(styles.answerBox, styles.trueValue)}>
                                            Passed
                                        </div>
                                    ) : (
                                        <div className={styles.answerBox}>{value}</div>
                                    )}
                                </div>
                            ))}
                    </div>
                    <div className={styles.btnContainer}>
                        <Button
                            block
                            className={styles.nextStepBtn}
                            variant={setButtonVariant(profile.company?.theme.button.variant)}
                            onClick={handleNextStep}
                        >
                            Continue
                        </Button>
                    </div>
                </Card.Body>
            ) : (
                <ContinueVerification
                    continueBtnEnabled={true}
                    handleNextStep={handleNextStep}
                    resultDescription={kbaDescription}
                />
            )}
        </Background>
    );
}

KbaResult.propTypes = {
    profile: PropTypes.object,
    history: PropTypes.any,
    kba: PropTypes.object,
    result: PropTypes.object,
    getTestResult: PropTypes.func,
    verificationConfiguration: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        result: state.result,
        verificationConfiguration: state.general.verificationConfiguration,
        kba: state?.profile?.workflow?.idVerifications?.kba ?? {},
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTestResult: (userAccessCode) => dispatch(getTestResult(userAccessCode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KbaResult);
