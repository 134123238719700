import axios from 'axios';

const http = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        Accept: 'application/json',
    },
    proxy: {
        port: 4444,
    },
    maxContentLength: 1024 * 1024 * 20,
});

export const createTokenAPI = (data) => {
    return http.post('/verification/self/onboarding/demo/jwt', data);
};

export const twoFactorAuthAPI = (token) => {
    let config = {
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
        },
    };
    return http.get('/verification/self/two-fa', config);
};

export const verifyTwoFactorAuthAPI = (code, token) => {
    let config = {
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
        },
    };
    return http.post('/verification/self/two-fa/confirm', { code: code }, config);
};

export const verifyCaptchaAPI = (data) => {
    return http.post('/verification/self/captcha/verify', data).then((response) => {
        if (!response.data.success) {
            throw new Error('Verification Failed');
        }
        return response.data.success;
    });
};
