// import { combineReducers } from 'redux'
import profileReducer from './profile-reducer';
import sessionReducer from './session-reducer';
import identityReducer from './identity-reducer';
import resultReducer from './result-reducer';
import { configReducer } from './config-reducer';
import { processedDataReducer } from './processed-data-reducer';
import { idPropertiesReducer } from './id-properties-reducer';
import { capturedReducer } from './captured-reducer';
import communicationReducer from './communication-reducer';
import { imageReducer } from './images-reducer';
import commonReducer from './common-reducer';
import { generalReducer } from './general-reducer';
import { reAuthenticateReducer } from './reAuthenticate';
import { InstructionsReducer } from './instructions';

export default {
    profile: profileReducer,
    session: sessionReducer,
    identity: identityReducer,
    result: resultReducer,
    config: configReducer,
    processedData: processedDataReducer,
    idProperties: idPropertiesReducer,
    captureProperties: capturedReducer,
    communication: communicationReducer,
    images: imageReducer,
    common: commonReducer,
    general: generalReducer,
    reAuthenticate: reAuthenticateReducer,
    instructions: InstructionsReducer,
};

// export default combineReducers(reducers)
