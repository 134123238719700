import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export const formValuesToAPI = (formValues) => {
    return formValues.professionLisences.map((license) => {
        return {
            license_title: license.title,
            license_organization: license.organizationName,
            license_number: license.certificationNumber,
            startDate: moment(license.start).format('DD-MM-YYYY'),
            endDate: license.isCurrent
                ? moment(new Date()).format('DD-MM-YYYY')
                : moment(license.end).format('DD-MM-YYYY'),
            state: license.state,
            country: license.country,
            manualVerification: false,
            uniqueIdentifier: uuidv4(),
        };
    });
};

export const reduxToFormValues = (reduxValues) => {
    return {
        ...reduxValues,
        dob: moment(reduxValues.dateOfBirth, 'YYYY-MM-DD'),
        ssn: '',
    };
};
