import { connect } from 'react-redux';
import { withFormik } from 'formik';
import validationSchema from './VerificationQuestions.validation';
import { compose } from 'redux';
import { getKBAQuestions, saveKBAAnswers } from 'actions/identity';
import { formValuesToAPI } from './VerificationQuestions.mapper';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'utils/utilities';
import { trackEvent } from 'api/productAnalytics';
import { eventNames } from 'utils/constants';
import moment from 'moment';

const initialValues = {
    answers: [],
};

const mapDispatchToProps = (dispatch) => {
    return {
        getKBAQuestions: (userAccessCode) => dispatch(getKBAQuestions(userAccessCode)),
        saveKBAAnswers: (userAccessCode, data) => dispatch(saveKBAAnswers(userAccessCode, data)),
    };
};

const mapStateToProps = (state) => {
    return {
        kba: state.identity.kba,
        profile: state.profile,
        companyUUID: state.profile.company?.uuid,
        userAccessCode: state.profile.company?.user?.userDetails.uuid,
        verificationConfiguration: state.general.verificationConfiguration,
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: (props) =>
            props?.kba?.idmkbaResponse?.kbaQuestion
                ? { answers: props.kba.idmkbaResponse.kbaQuestion.map(() => '') }
                : initialValues,
        enableReinitialize: true,
        validationSchema,
        handleSubmit: async (values, { setStatus, setSubmitting, props }) => {
            trackEvent({
                event: eventNames.kba,
                uac: props.userAccessCode,
                uniqueOnly: false,
                otherValues: {
                    'Completion Time': moment().format('YYYY-MM-DD HH:mm:ss'),
                    'Verification Type': 'KBA',
                },
            });

            const verificationMethod = props?.verificationConfiguration;
            const isDocumentVerified =
                props?.profile?.company?.user?.verificationDetails?.isDocumentValid;
            const kbaAttemptsUsed =
                props?.profile?.workflow?.idVerifications?.kba?.attempts -
                    props?.profile?.company?.user?.verificationDetails?.kbaAttempts >
                1;
            const bothEitherMethod = verificationMethod === 'AND' || verificationMethod === 'OR';
            const singleMethod =
                verificationMethod === 'SINGLE' &&
                props?.profile?.workflow?.idVerifications?.kba?.enabled;

            // condition for bank available or not
            const isBankVerified =
                props?.profile?.company?.user?.verificationDetails?.isBankOwnerMatched;
            const currentBankAttempts =
                props?.profile?.company?.user?.verificationDetails?.bankAttempts;
            const totalBankAttempts = props?.profile?.workflow?.idVerifications?.bank?.attempts;
            const isBankAttemptsUsed = currentBankAttempts >= totalBankAttempts;
            const isBankAvailable = !isBankAttemptsUsed && !isBankVerified;

            const checkForKbaAttempts = () => {
                if (!kbaAttemptsUsed) {
                    props.history.push('kba/failed');
                } else {
                    props.history.push('/verification');
                }
            };

            const handleKbaErrorStep = () => {
                if (bothEitherMethod && (isDocumentVerified || isBankAvailable)) {
                    props.history.push('/verification');
                } else if (bothEitherMethod && !isDocumentVerified && !isBankAvailable) {
                    checkForKbaAttempts();
                } else if (singleMethod) {
                    checkForKbaAttempts();
                }
            };

            const { saveKBAAnswers, getProfile, webhook, history, kba } = props;

            try {
                if (kba.errorMsg) return history.goBack();

                const kbaResponse = await saveKBAAnswers(
                    formValuesToAPI(
                        props.profile.company.user.userDetails.uuid,
                        props.kba.idmSessionId,
                        values
                    )
                );

                getProfile();

                setSubmitting(false);

                if (!kbaResponse?.data?.success) {
                    webhook(false, 'KBA');
                    handleKbaErrorStep();
                    trackEvent({
                        event: eventNames.KbaFailed,
                        uac: props.userAccessCode,
                        uniqueOnly: false,
                        otherValues: {
                            Score:
                                (Number(kbaResponse.data?.correct_answers) /
                                    Number(kbaResponse.data?.total_questions)) *
                                100,
                        },
                    });

                    return toast.error('Your answers were incorrect.');
                }

                trackEvent({
                    event: eventNames.completed,
                    uac: props.userAccessCode,
                    uniqueOnly: false,
                    otherValues: {
                        'Completion Time': moment().format('YYYY-MM-DD HH:mm:ss'),
                        'Verification Type': 'KBA',
                    },
                });

                toast.info(
                    'You have successfully verified your identity using knowledge based authentication.'
                );

                webhook(true, 'KBA');

                return history.push('kba/result');
            } catch (error) {
                webhook(false, 'KBA');

                history.goBack();

                setSubmitting(false);

                toast.error(getErrorMessage(error));
            }
        },
        displayName: 'VerificationQuestionsForm',
    })
);
