import React from 'react';
import PropTypes from 'prop-types';

import Illustration from 'assets/images/illustration2@3x.png';
import styles from './SelfiePage.module.css';
import Image from 'core-components/Image';

const CardBodyContent = ({ showErrorContent, errMsg }) => {
    return showErrorContent ? (
        <>
            <Image
                name="warning-fill.svg"
                svg="true"
                alt="warning-fill.svg"
                className={styles.errIcon}
            />
            <p className={styles.errMsg}>{errMsg}</p>
        </>
    ) : (
        <>
            <p className={styles.description}>
                Take a selfie image using the front camera of your device.
            </p>
            <img
                width="100%"
                alt="selfie illustration"
                className={styles.capture}
                src={Illustration}
            />
        </>
    );
};

CardBodyContent.propTypes = {
    showErrorContent: PropTypes.bool,
    errMsg: PropTypes.string,
};

export default CardBodyContent;
