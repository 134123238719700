import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from '../DeviceLinks.module.scss';
import Button from 'core-components/Button';
import Image from 'core-components/Image';

function TimeUpModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={styles.timeUpModal}
        >
            <Modal.Header className={styles.modalHeader}>
                <Modal.Title className={styles.modalTitle}>Still there?</Modal.Title>
                <Image name="Close.svg" onClick={props.onHide} className={styles.closeBtn} />
            </Modal.Header>

            <Modal.Body className={styles.modalBodyText}>
                Please confirm, if you are still available. Else close the window.
            </Modal.Body>

            <Modal.Footer className={styles.modalFooter}>
                <Button
                    className={styles.modalFooterBtn}
                    variant="outline-primary"
                    onClick={() => {
                        props.onHide();
                    }}
                >
                    Close window
                </Button>
                <Button onClick={props.onHide} className={styles.modalFooterBtn}>
                    Yes, I’m here
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TimeUpModal;
