import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { processID, increaseTries } from './actions/processDataActions';
import Processing from './Processing';

import SelfiePage from 'core-components/SelfiePage';
import SessionTimer from 'core-components/SessionTimer';
import { startSessionTimer, resetSessionTimer } from 'actions/general';
import styles from './screens.module.scss';

import { getDeviceInfoAction, updateReauthenticateStatus } from 'actions/reAuthenticate';

import useCaptureSelfie from 'hooks/useCaptureSelfie';
import { setShowInstructions } from 'actions/instructions';

const CaptureSelfie = (props) => {
    const { loading, openFrontCamera } = useCaptureSelfie(props);

    if (loading) {
        return <Processing />;
    }

    return (
        <Fragment>
            <SelfiePage handleOnClick={openFrontCamera} {...props} />
            <div className={styles.sessionTimerBox}>
                <SessionTimer />
            </div>
        </Fragment>
    );
};

CaptureSelfie.propTypes = {
    isDeviceInfoSent: PropTypes.bool,
    getDeviceInfoAction: PropTypes.func,
    reAuthenticateSessionId: PropTypes.string,
    sessionTimer: PropTypes.any,
    startSessionTimer: PropTypes.func,
    verificationFlow: PropTypes.string,
    dispatch: PropTypes.func,
    tries: PropTypes.number,
    resultData: PropTypes.object,
    increaseTries: PropTypes.func,
    workflow: PropTypes.object,
    processedData: PropTypes.object,
    history: PropTypes.object,
    reauthenticateSelfieAttempts: PropTypes.number,
    sessionStatus: PropTypes.object,
    updateReauthenticateStatus: PropTypes.func,
    livenessCheckEnabled: PropTypes.bool,
    instanceID: PropTypes.string,
    processID: PropTypes.func,
    uac: PropTypes.string,
    showSelfieInstructions: PropTypes.bool,
    selfieErrorMsg: PropTypes.string,
    setShowInstructions: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        instanceID: state?.config?.instanceID,
        tries: state?.processedData?.tries,
        faceMatch: state?.processedData?.faceMatch,
        resultData: state?.processedData?.result,
        liveness: state?.processedData?.liveness,
        livenessCheckEnabled: state?.profile?.company?.livenessCheckEnabled,
        processedData: state?.processedData,
        workflow: state?.profile?.workflow?.idVerifications?.govtId?.metadata,
        uac: state?.profile?.company?.user?.userDetails.uuid,
        sessionTimer: state?.general?.sessionTimer,
        reAuthenticateSessionId: state?.reAuthenticate?.reAuthenticateSessionID,
        reauthenticateSelfieAttempts:
            state?.reAuthenticate?.reauthenticateSessionStatus?.selfieVerification?.attempts || 0,

        sessionStatus: state?.reAuthenticate?.reauthenticateSessionStatus,
        isDeviceInfoSent: state?.reAuthenticate?.isDeviceInfoSent,
        showInstructions: state?.instructions?.showInstructions,
        selfieErrorMsg: state?.processedData?.selfieErrorMsg,
        lastUsedInstruction: state?.instructions?.lastUsedInstructionType,
    };
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators(
        {
            processID,
            increaseTries,
            startSessionTimer,
            resetSessionTimer,
            updateReauthenticateStatus,
            getDeviceInfoAction,
            setShowInstructions,
        },
        dispatch
    );
    return { ...actions, dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureSelfie);
