// imports
import React, { Suspense, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Card from 'react-bootstrap/Card';
// components
import NotFound from 'components/NotFound';
import Lander from 'components/Lander';
import Home from 'components/Home';
import Onboarding from 'components/Onboarding';
import ApplyBranding from 'components/ApplyBranding';
import UnsupportedBrowser from 'components/UnsupportedBrowser';
import PrivateRoute from 'core-components/PrivateRoute';
import { RedirectRoute } from 'core-components/RedirectRoute';
import Footer from 'core-components/Footer';
import Background from 'core-components/Background';
import Loader from 'core-components/Loader';
import Navbar from 'core-components/Navbar';
import Button from 'core-components/Button';
// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChrome, faSafari } from '@fortawesome/free-brands-svg-icons';
import { REAUTHENTICATE_ROUTE } from 'utils/constants';
import SessionExpired from 'components/SessionExpired';

const Mobile = lazy(() => import('components/Mobile'));

const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

const ContentArea = ({ profile, reauthenticateSessionStatus, ...props }) => {
    const [unsupportedBrowser, setUnsupportedBrowser] = useState(false);

    let primary = '#154683';
    let secondary = '#1ba1db';
    let overlayColor = '#FFFFFF';
    let modalBackgroundColor = '#FFFFFF';
    let modalBorderRadius = '0px';
    let fontFamily = 'Rubik';
    let buttonBorderRadius = '6px';
    let navbarColor = '#FFFFFF';
    let headerColor = '#EFF5FD';

    const isReAuthenticateRoute = props?.pathname?.includes(REAUTHENTICATE_ROUTE);
    const reauthenticateTheme = reauthenticateSessionStatus?.theme;
    const isMedallionLoading = profile && !profile.loading && profile?.company?.theme;
    const isThemeLoading = isMedallionLoading || isReAuthenticateRoute;
    const globalTheme = profile?.company?.theme || reauthenticateTheme;

    if (isThemeLoading) {
        primary = globalTheme?.primaryColor || '#154683';
        secondary = globalTheme?.secondaryColor || '#1ba1db';
        overlayColor = globalTheme?.overlayColor || '#FFFFFF';
        modalBackgroundColor = globalTheme?.modalBackgroundColor || '#FFFFFF';
        modalBorderRadius = globalTheme?.modalBorderRadius || '0px';
        fontFamily = globalTheme?.fontFamily || 'Rubik';
        buttonBorderRadius = globalTheme?.button.borderRadius || '6px';
        navbarColor = globalTheme?.navbarColor || '#FFFFFF';
        headerColor = globalTheme?.headerColor || '#EFF5FD';
    }

    const applyColors = {
        primary: primary,
        secondary: secondary,
        overlayColor: overlayColor,
        modalBackgroundColor: modalBackgroundColor,
        navbarColor: navbarColor,
        headerColor: headerColor,
    };

    return (
        <div>
            <ApplyBranding
                colors={applyColors}
                modalBorderRadius={modalBorderRadius}
                fontFamily={fontFamily}
                buttonBorderRadius={buttonBorderRadius}
            />
            {unsupportedBrowser ? (
                <UnsupportedBrowser
                    image="browsers.svg"
                    heading={
                        <div>
                            <p>We're sorry, but your browser isn't fully supported.</p>
                            <hr />
                            <p>
                                <b>Currently supported browsers</b>
                            </p>
                            <p>
                                <a
                                    href="https://www.google.com/chrome/"
                                    style={{ marginRight: '1rem' }}
                                >
                                    <FontAwesomeIcon icon={faChrome} size="2x" />
                                </a>
                                <a href="https://www.apple.com/in/safari/">
                                    <FontAwesomeIcon icon={faSafari} size="2x" />
                                </a>
                            </p>
                        </div>
                    }
                >
                    <Card.Footer className="text-muted">
                        <Button
                            block
                            variant="primary"
                            onClick={() => {
                                setUnsupportedBrowser(false);
                            }}
                        >
                            Continue Anyway
                        </Button>
                    </Card.Footer>
                </UnsupportedBrowser>
            ) : (
                <Suspense
                    fallback={
                        <Background>
                            <Card.Body>
                                <Loader />
                            </Card.Body>
                        </Background>
                    }
                >
                    {!inIframe() && <Navbar />}
                    <Switch>
                        <Route path={'/welcome'} component={Lander} {...props} exact />
                        <Route path={'/onboarding'} component={Onboarding} {...props} exact />
                        <Route path={'/onboarding-button'} {...props} exact>
                            <RedirectRoute pathname="/onboarding" />
                        </Route>
                        <Route
                            exact
                            path={'/session-expired'}
                            component={SessionExpired}
                            {...props}
                        />
                        <PrivateRoute
                            path={'/mobile'}
                            session={props.session}
                            render={(rest) =>
                                isMobile ? (
                                    <Mobile {...rest} routerHistory={rest.history} />
                                ) : (
                                    <NotFound heading="This link can only be opened on a mobile device.">
                                        <Card.Footer className="text-muted">
                                            <Button
                                                block
                                                variant="primary"
                                                onClick={() => {
                                                    rest.history.replace(
                                                        '/verification/links?bypass=true'
                                                    );
                                                }}
                                            >
                                                Go Back
                                            </Button>
                                        </Card.Footer>
                                    </NotFound>
                                )
                            }
                            onMount={true}
                            {...props}
                        />
                        <PrivateRoute
                            path={'/'}
                            component={Home}
                            {...props}
                            // passed this prop for reauthenticate session only 👇
                            reauthenticateSession={props.reAuthenticateSessionID}
                        />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </Suspense>
            )}

            {props.pathname !== '/mobile/capture/camera' && !inIframe() && <Footer />}
        </div>
    );
};

ContentArea.propTypes = {
    profile: PropTypes.object,
    reauthenticateSessionStatus: PropTypes.object,
    pathname: PropTypes.string,
    reAuthenticateSessionID: PropTypes.string,
    session: PropTypes.any,
};

export default ContentArea;
