import React, { useEffect } from 'react';
import { useState } from 'react';
import './CountDown.scss';
function CountDown({ initialMinute = 0, initialSecond = 0, status, handleTimeOut }) {
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSecond);
    useEffect(() => {
        if (status === 'start') {
            let myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(myInterval);
                        handleTimeOut();
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
            }, 1000);
            return () => {
                clearInterval(myInterval);
            };
        }
    });
    return (
        <>
            {status !== '' && (
                <div className="count_down_wrapper">
                    <p className="count_down_value">
                        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                </div>
            )}
        </>
    );
}

export default CountDown;
