import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Background from 'core-components/Background';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import styles from './ThankYou.module.scss';
import { modifyThankYouPageContent, setButtonVariant } from 'utils/utilities';
import { ReactComponent as CheckCircle } from 'images/filled-check-circle.svg';
import { AUTHENTICATE_CONTACT } from 'utils/constants';

const TIMEOUT = 30;

// This function is to determine whether the current web page is being displayed within an iframe or not. The function returns true if the page is inside an iframe and false if it's not.
const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
}

const ThankYou = ({ profile, ...props }) => {
    const [counter, setCounter] = useState(0);

    const company = profile?.company ?? {};

    const showReturnButton = company?.redirectUrl?.length > 0;

    const displayCompanyName = company?.name ?? AUTHENTICATE_CONTACT.NAME;

    const { defaultBtnText, description, defaultText } = modifyThankYouPageContent({
        companyName: displayCompanyName,
        companyUUID: company.uuid,
    });

    useInterval(() => {
        setCounter(counter + 1);
    }, 1000);

    const close = (redirectUrl) => {
        if (typeof redirectUrl === 'string') {
            if (
                redirectUrl.toString().startsWith('http://') ||
                redirectUrl.toString().startsWith('https://')
            ) {
                window.parent.location.href = redirectUrl;
            } else {
                window.parent.location.href = '//' + redirectUrl;
            }
        }
        sessionStorage.clear();
    };

    if (inIframe() && counter - TIMEOUT === 0) {
        close(props);
    }

    const redirectUrl = company?.redirectUrl;

    const handleRedirect = () => {
        close(redirectUrl);
    };

    const closeWithTimer = TIMEOUT - counter >= 0 ? `CLOSE (${TIMEOUT - counter})` : 'CLOSE';
    const returnBtnText = inIframe() ? closeWithTimer : defaultBtnText;

    return (
        <Background className={styles.thankCardWrapper}>
            {inIframe() && showReturnButton && TIMEOUT - counter >= 0 && (
                <Alert variant="info" className={styles.timerBox}>
                    Redirecting in {TIMEOUT - counter}
                </Alert>
            )}
            <Card.Header className={styles.cardHeader}></Card.Header>
            <Card.Body className={styles.cardBody}>
                <CheckCircle style={{ color: '#52C41A' }} />
                {defaultText && <p className={styles.cardHeading}>{defaultText}</p>}
                {description && <p className={styles.cardSubHeading}>{description}</p>}
                {showReturnButton ? (
                    <Button
                        block
                        variant={setButtonVariant(company?.theme.button.variant)}
                        className={styles.footerBtn}
                        onClick={handleRedirect}
                    >
                        {returnBtnText}
                    </Button>
                ) : null}
            </Card.Body>
        </Background>
    );
};

ThankYou.propTypes = {
    profile: PropTypes.object,
};

export default ThankYou;
