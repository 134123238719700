import {
    RESET_ALL_REDUCERS,
    SAVE_ID_ERROR_MESSAGES,
    CLEAR_ID_ERROR_MESSAGES,
    SET_VERIFICATION_CONFIGURATION,
    CLEAR_VERIFICATION_CONFIGURATION,
    UPDATE_PROFILE,
    START_SESSION_TIMER,
    RESET_SESSION_TIMER,
} from 'actions';

const INITIAL_STATE = {
    idErrorMsg: undefined,
    verificationConfiguration: undefined,
    sessionTimer: undefined,
};

export function generalReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case RESET_ALL_REDUCERS:
            return { ...INITIAL_STATE };
        case SAVE_ID_ERROR_MESSAGES:
            return {
                ...state,
                idErrorMsg: action.data,
            };
        case CLEAR_ID_ERROR_MESSAGES:
            return {
                ...state,
                idErrorMsg: undefined,
            };
        case UPDATE_PROFILE:
            const { workflow } = action?.data;

            const idVerification = workflow?.id_verifications;
            const enabledVerifications = [];
            const mandatoryVerifications = [];

            for (const key in idVerification) {
                const check = idVerification[key];
                if (check.enabled) {
                    enabledVerifications.push(check.verification_type);
                    if (check.mandatory) {
                        mandatoryVerifications.push(check.verification_type);
                    }
                }
            }

            const allOrAnyMethod =
                mandatoryVerifications.length === enabledVerifications.length ? 'AND' : 'OR';

            const verificationConfiguration =
                enabledVerifications.length === 1 ? 'SINGLE' : allOrAnyMethod;

            return {
                ...state,
                verificationConfiguration: verificationConfiguration,
            };
        case SET_VERIFICATION_CONFIGURATION:
            return {
                ...state,
                verificationConfiguration: action.data,
            };
        case CLEAR_VERIFICATION_CONFIGURATION:
            return {
                ...state,
                verificationConfiguration: undefined,
            };
        case START_SESSION_TIMER:
            return {
                ...state,
                sessionTimer: action.data,
            };

        case RESET_SESSION_TIMER:
            return {
                ...state,
                sessionTimer: undefined,
            };
        default:
            return state;
    }
}
