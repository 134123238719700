import React from 'react';
import Form from 'react-bootstrap/Form';
import styles from './Checkbox.module.scss';
import cn from 'classnames';
import { ErrorMessage } from 'formik';

export default ({ field, form, ...props }) => {
  return (
    <>
      <Form.Check
        id={field.name}
        {...field}
        {...props}
        type="checkbox"
        checked={field.value}
        onKeyPress={e => {
          if (e.which === 13) {
            form.setFieldValue(field.name, !field.value)
          }
        }}
        className={cn(styles.checkbox, props.className)}
        isInvalid={
          !props.label &&
          form &&
          form.touched[field.name] &&
          !!form.errors[field.name]
        }
      />
      {props.label && (
        <p style={{ color: '#DC3545', marginTop: '.25rem', fontSize: '80%' }}>
          <ErrorMessage name={field.name} />
        </p>
      )}
    </>
  );
};
