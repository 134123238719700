import { REMOVE_LAST_IMAGE, RESET_ALL_REDUCERS } from 'actions';

export function imageReducer(state = [], action) {
    switch (action.type) {
        case '@@acuant/ADD_CAPTURED_IMAGE':
            return [...state, action.data];
        case RESET_ALL_REDUCERS:
        case '@@acuant/RESET_PROCESSED_DATA':
            return [];
        case REMOVE_LAST_IMAGE:
            const current = state;
            current.pop();
            return current;
        default:
            return state;
    }
}
