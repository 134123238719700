import { useEffect } from 'react';

export function useBeforeUnload() {
    useEffect(() => {
        function handleOnBeforeUnload(event) {
            event.preventDefault();
            event.returnValue = true;
        }
        window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true });

        return () => {
            window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true });
        };
    }, []);
}
