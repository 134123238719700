import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';
const LinkToInstructionScreen = ({ handleOnClick, buttonText, ...props }) => {
    return (
        <button className={styles.instructionsLink} onClick={handleOnClick}>
            {buttonText}
        </button>
    );
};

LinkToInstructionScreen.propTypes = {
    handleOnClick: PropTypes.func,
    buttonText: PropTypes.string,
};

export default LinkToInstructionScreen;
