import React from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'core-components/Button'
import styles from './OnboardingSuccess.module.scss'
import Alert from 'react-bootstrap/Alert'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Background from 'core-components/Background'

export default (props) => {
  const [copied, setCopied] = React.useState(false)

  if (copied) {
    setTimeout(() => setCopied(false), 2000)
  }
  return (
    <>
      <Background className={styles.sucessFormWarpper}>
        <Card.Header
          as="h5"
          className={styles.cardHeader}
        >
          Verification Initiated
        </Card.Header>
        <Card.Body className={styles.cardbody}>
          {/* <div className={styles.cardTitle}>
            <p>
              <b>We have initiated the process.</b>
              <br />
              <b>Please follow these instructions.</b>
            </p>
          </div> */}

          <ul>
            <li>
              We have successfully sent a link to the phone number.{' '}
              {/* <b>{`${props.metadata.phone}`}</b> */}
            </li>
            <br />
            <li>
              If the link was not received, please share the link below.
            </li>
            <br />
            <li>
              Open the link below to verify yourself.
            </li>
            <br />
            <li>
              The results will be emailed to <b>{`${props.metadata.email}`}</b>
            </li>
          </ul>
          <div className={styles.copyLink}>
            <Alert.Link href={props.link}>{props.link}</Alert.Link>
          </div>
        </Card.Body>
        <Card.Footer className={styles.cardFooter}>
          <CopyToClipboard text={props.link} onCopy={() => setCopied(true)}>
            <Button disabled={copied} block className={styles.footerBtn} variant="primary">
              {copied ? 'Copied' : 'Copy Link'}
            </Button>
          </CopyToClipboard>
        </Card.Footer>
      </Background>
    </>
  )
}
