import React from 'react';
import Form from 'react-bootstrap/Form';
import styles from './Input.module.scss';
import cn from 'classnames';

export default React.forwardRef((props, ref) => {
  return (
    <Form.Control
      {...props}
      className={cn(styles.input, props.className)}
      ref={ref}
    />
  );
});
