import React from 'react';
import Form from 'react-bootstrap/Form';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getIn } from 'formik';
import './ValidatedForminputField.scss';
import ErrorWithInfoIcon from '../Error/ErrorWithInfoIcon';
import PropTypes from 'prop-types';

const useStyle = makeStyles(() => ({
    cssOutlinedInput: {
        padding: '9.5px 14px 9.5px 12px',
    },

    disabled: {
        background: '#F9FAFB',
    },
}));

const ValidatedFormInputField = React.forwardRef(
    (
        {
            field,
            form,
            label,
            regex,
            max,
            handleChange,
            StartAdornmentIcon,
            EndAdornmentIcon,
            ...props
        },
        ref
    ) => {
        const classes = useStyle();
        const { touched, errors, values } = form;

        return (
            <Form.Group className={props.className}>
                {label && <Form.Label>{label}</Form.Label>}
                <TextField
                    {...props}
                    fullWidth
                    value={values[field.name]}
                    error={
                        Boolean(getIn(errors, field.name)) && Boolean(getIn(touched, field.name))
                    }
                    FormHelperTextProps={{
                        component: () => (
                            <ErrorWithInfoIcon>{getIn(errors, field.name)}</ErrorWithInfoIcon>
                        ),
                    }}
                    helperText={
                        Boolean(getIn(errors, field.name)) &&
                        Boolean(getIn(touched, field.name)) &&
                        getIn(errors, field.name)
                    }
                    onChange={(event) => {
                        if (props.onChange) {
                            props.onChange(event);
                        } else {
                            form.setFieldValue(
                                field.name,
                                event.target.value.replace(regex, '').slice(0, max)
                            );
                            handleChange &&
                                handleChange(event.target.value.replace(regex, '').slice(0, max));
                        }
                    }}
                    ref={ref}
                    inputProps={{
                        maxLength: props.maxLength,
                    }}
                    InputProps={{
                        classes: {
                            input: classes.cssOutlinedInput,
                            disabled: classes.disabled,
                        },

                        startAdornment: StartAdornmentIcon && <StartAdornmentIcon />,
                        endAdornment: EndAdornmentIcon && <EndAdornmentIcon />,
                    }}
                />
            </Form.Group>
        );
    }
);

ValidatedFormInputField.propTypes = {
    field: PropTypes.object,
    form: PropTypes.object,
    label: PropTypes.string,
    regex: PropTypes.string,
    max: PropTypes.number,
    StartAdornmentIcon: PropTypes.element,
    EndAdornmentIcon: PropTypes.element,
};

export default ValidatedFormInputField;
