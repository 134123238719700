import { RESET_ALL_REDUCERS } from 'actions';

const INITIAL_STATE = {
    faceMatch: null,
    result: null,
    tries: 0,
    failureTries: 0,
    autoCaptureFailureCount: 0,
    liveness: null,
    selfieErrorMsg: null,
};

export function processedDataReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case RESET_ALL_REDUCERS:
            return { ...INITIAL_STATE };
        case '@@acuant/ADD_ID_RESULT_DATA':
            return {
                ...state,
                result: action.payload,
            };
        case '@@acuant/ADD_FACE_MATCH_DATA':
            return {
                ...state,
                faceMatch: action.payload,
            };
        case '@@acuant/ADD_FACE_LIVENESS_DATA':
            return {
                ...state,
                liveness: action.payload,
                selfieErrorMsg: null,
            };
        case '@@acuant/RESET_PROCESSED_DATA':
            return {
                ...INITIAL_STATE,
                failureTries: state.failureTries,
                // we need this state to persist even if we are resetting the flow.
                autoCaptureFailureCount: state.autoCaptureFailureCount,
            };
        case '@@acuant/INCREASE_TRIES':
            return {
                ...state,
                tries: state.tries + 1,
            };
        case '@@acuant/INCREASE_FAILURE_TRIES':
            return {
                ...state,
                failureTries: state.failureTries + 1,
            };
        case '@@acuant/AUTO_CAPTURE_FAILURE_COUNT_INCREASE':
            return {
                ...state,
                autoCaptureFailureCount: state.autoCaptureFailureCount
                    ? state.autoCaptureFailureCount + 1
                    : 1,
            };
        case '@@acuant/AUTO_CAPTURE_FAILURE_COUNT_RESET':
            return {
                ...state,
                autoCaptureFailureCount: 0,
            };

        case '@@acuant/UPDATE_SELFIE_ERROR_MSG':
            return {
                ...state,
                selfieErrorMsg: action.payload,
            };
        default:
            return state;
    }
}
