// imports
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { map, startCase } from 'lodash';
// components
import styles from './Dashboard.module.scss';
import Loader from 'core-components/Loader';
import Background from 'core-components/Background';
import Button from 'core-components/Button';
import Image from 'core-components/Image';
import { isReAuthenticateRoute, setButtonVariant } from 'utils/utilities';
import ValidatedRecaptcha from 'core-components/ValidatedRecaptcha';
import { Field } from 'formik';
// assets
import AuthenticateLogo from 'components/AuthenticateLogo';
import { ReactComponent as AuthenticateRgb } from 'assets/images/authenticate_logo_rgb.svg';
import { MEDALLION_LINK_ERR_MSG } from 'utils/constants';

function Dashboard({
    session,
    profile,
    getProfile,
    renderNextStep,
    setConsent,
    isLastPoint,
    verificationConfiguration,
    updateReauthenticateStatus,
    reAuthenticateSessionID,
    ...props
}) {
    const location = useLocation();

    const reauthenticateRoute = isReAuthenticateRoute(location?.pathname);

    useEffect(() => {
        session?.token && getProfile();
    }, [getProfile, session?.token]);

    const isDocumentVerified =
        !profile.loading && profile?.company?.user?.verificationDetails?.isDocumentVerified === 1;
    const isKbaVerified =
        !profile.loading && profile?.company?.user?.verificationDetails?.isKbaVerified === 1;
    const isBankVerified =
        !profile.loading && profile?.company?.user?.verificationDetails?.isBankOwnerMatched;

    if (verificationConfiguration === 'AND') {
        if (isDocumentVerified && isKbaVerified && isBankVerified && isLastPoint) {
            props.history.push('/thank-you');
        }
    } else if ((isDocumentVerified || isKbaVerified || isBankVerified) && isLastPoint) {
        props.history.push('/thank-you');
    }

    const extendedInputs = profile?.company?.user?.extendedInputs;

    function getEnabledExtendedInputs() {
        const keyWithTrueValues = [];
        for (const key in extendedInputs) {
            if (extendedInputs.hasOwnProperty(key) && extendedInputs[key] === true) {
                keyWithTrueValues.push(key);
            }
        }
        return keyWithTrueValues;
    }

    const extendedChecksList = getEnabledExtendedInputs();

    let verificationInfoText = 'Your identity will be verified.';

    if (extendedChecksList.length > 1) {
        const formattedArray = map(extendedChecksList, startCase);
        const lastItem = formattedArray.pop();
        const formattedString = `Your Identity, ${formattedArray.join(
            ', '
        )} and ${lastItem} will be verified.`;
        verificationInfoText = formattedString;
    } else if (extendedChecksList.length === 1) {
        verificationInfoText = `Your Identity and ${startCase(
            extendedChecksList
        )} will be verified.`;
    }

    const themeButton = profile?.company?.theme?.button.variant;

    const dashboardTitle = reauthenticateRoute ? 'ReAuthenticate™' : 'Identity Authentication';

    const dashboardDesc = reauthenticateRoute
        ? 'You will be ReAuthenticated using your selfie.'
        : verificationInfoText;

    // Load component =  if Medallion session -> is profile loading | Reauthenticate session -> don't require profile to load
    const isLoading = reauthenticateRoute
        ? false
        : props.isSubmitting || profile.loading || !profile.company;
    const isWrapperLoading = reauthenticateRoute ? true : !profile.loading && profile.company;

    if (isLoading) {
        return (
            <Background className={styles.verifyWelcome}>
                <Loader />
            </Background>
        );
    }

    const redirectScreen =
        profile?.error === MEDALLION_LINK_ERR_MSG.EXPIRED
            ? '/session-expired?session=expired'
            : '/session-expired?session=invalid';

    return !profile.error ? (
        <Background className={styles.verifyWelcome}>
            {isWrapperLoading && (
                <Card.Header bsPrefix={styles.cardHeader}>
                    <p className={styles.heading}>{dashboardTitle}</p>
                </Card.Header>
            )}
            <div className={styles.body}>
                <Card.Body>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div>
                            <Container>
                                <Row className={styles.row}>
                                    <Col xs={1} className={styles.iconCol}>
                                        <Image name="identity-gray.svg" />
                                    </Col>
                                    <Col xs={11} className={styles.content}>
                                        {dashboardDesc}
                                    </Col>
                                </Row>
                                <Row className={styles.row}>
                                    <Col xs={1} className={styles.iconCol}>
                                        <Image name="secure-shield-gray.svg" />
                                    </Col>
                                    <Col xs={11} className={styles.content}>
                                        We use machine learning, computer vision, and AI for a
                                        forensic identity authentication.
                                    </Col>
                                </Row>
                                <Row className={styles.row}>
                                    <Col xs={1} className={styles.iconCol}>
                                        <Image name="digital-lock-gray.svg" />
                                    </Col>
                                    <Col xs={11} className={styles.content}>
                                        Your information will only be used by{' '}
                                        <a
                                            href="https://authenticating.com/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={styles.link}
                                        >
                                            Authenticate.com
                                        </a>{' '}
                                        for verification purposes.
                                    </Col>
                                </Row>
                                <Row className={styles.row}>
                                    <Col xs={1} className={styles.iconCol}>
                                        <Image name="quick-process-gray.svg" />
                                    </Col>
                                    <Col xs={11} className={styles.content}>
                                        This process takes only a couple of minutes.
                                    </Col>
                                </Row>
                            </Container>

                            {!reauthenticateRoute && (
                                <Form.Check
                                    className={styles.formCheck}
                                    id="agree"
                                    type="checkbox"
                                    custom
                                    onChange={props.handleChange}
                                    checked={props.values.agree}
                                    label={
                                        <p className={styles.checkLabel}>
                                            I agree to{' '}
                                            <a
                                                href="https://authenticating.com"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={styles.link}
                                            >
                                                Authenticate.com
                                            </a>
                                            's{' '}
                                            <a
                                                href="https://authenticating.com/terms-of-use.html"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={styles.link}
                                            >
                                                Terms of Use
                                            </a>{' '}
                                            and{' '}
                                            <a
                                                href="https://authenticating.com/privacy-policy.html"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={styles.link}
                                            >
                                                Privacy Policy
                                            </a>
                                            {/* */}.
                                        </p>
                                    }
                                />
                            )}

                            <Field
                                render={(fieldProps) => (
                                    <ValidatedRecaptcha
                                        {...fieldProps}
                                        ref={props.values.captchaRef}
                                    />
                                )}
                                name="captcha"
                            />
                        </div>
                    )}
                </Card.Body>
                {!profile.loading && (
                    <>
                        <Card.Footer bsPrefix={styles.cardFooter}>
                            <Button
                                disabled={!props.isValid || !props.dirty}
                                block
                                className={styles.startBtn}
                                variant={setButtonVariant(themeButton)}
                                onClick={props.handleSubmit}
                            >
                                {reauthenticateRoute ? 'ReAuthenticate™' : <AuthenticateLogo />}
                            </Button>
                        </Card.Footer>

                        {reauthenticateRoute && (
                            <div className={styles.brandingLogo}>
                                POWERED BY <AuthenticateRgb />
                            </div>
                        )}
                    </>
                )}
            </div>
        </Background>
    ) : (
        <Redirect to={redirectScreen} />
    );
}

Dashboard.propTypes = {
    profile: PropTypes.object,
    history: PropTypes.object,
    session: PropTypes.object,
    getProfile: PropTypes.func,
    renderNextStep: PropTypes.func,
    setConsent: PropTypes.func,
    isLastPoint: PropTypes.any,
    verificationConfiguration: PropTypes.string,
    updateReauthenticateStatus: PropTypes.func,
    reAuthenticateSessionID: PropTypes.string,
    props: PropTypes.object,
};

export default Dashboard;
