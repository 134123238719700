import React from 'react';
import Card from 'react-bootstrap/Card';
import Background from 'core-components/Background';
import styles from './FailedScreens.module.scss';

function KbaFailed({ profile }) {
    const changeDescription = profile?.company?.uuid === '27feebcb-295c-46f5-8e6b-6cc5af742b69';

    const description = changeDescription ? (
        <>
            Thank you for your interest in Constellation. <br /> <br />
            Unfortunately, we were not able to verify your identity. We apologize for the
            inconvenience, but we are unable to complete your enrollment.
        </>
    ) : (
        'Unable to verify identity using Knowledge Authentication Quiz. All attempts have been used. Please contact your company administrator for next steps.'
    );

    return (
        <Background className={styles.kbaWrapper}>
            <Card.Header className={styles.cardHeader}>
                Knowledge Authentication Quiz failed
            </Card.Header>
            <Card.Body className={styles.cardBody}>
                <div className={styles.messageBox}>
                    <p className={styles.displayMessage}>Your answers were not correct</p>
                    <p className={styles.description}>{description}</p>
                </div>
            </Card.Body>
        </Background>
    );
}

export default KbaFailed;
