import {
    updateReauthenticateAdditionalVerification,
    setHideCodeResendLink,
    reAuthenticateSessionUpdate,
} from 'actions/reAuthenticate';
import { reauthenticateVerifyCodeAPI } from 'api/reAuthenticate';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as yup from 'yup';

const validationSchema = yup.object({
    code: yup.string('Enter your code').required('Verification code is required').length(6),
});

const initialValues = {
    code: '',
};

const mapStateToProps = (state) => {
    return {
        reAuthenticateStatus: state.reAuthenticate?.reauthenticateSessionStatus || {},
        reAuthenticateSessionId: state.reAuthenticate.reAuthenticateSessionID,
        hideCodeResendLink: state.reAuthenticate?.hideCodeResendLink,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdditionalVerification: (data) =>
            dispatch(updateReauthenticateAdditionalVerification(data)),
        setHideCodeResendLink: (data) => dispatch(setHideCodeResendLink(data)),
        reAuthenticateSessionUpdate: (sessionID) =>
            dispatch(reAuthenticateSessionUpdate(sessionID)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: () => initialValues,
        enableReinitialize: true,
        validationSchema,
        handleSubmit: async (values, { setFieldError, setSubmitting, props }) => {
            let additionalVerification;

            const {
                updateAdditionalVerification,
                reAuthenticateSessionId,
                reAuthenticateSessionUpdate,
            } = props;

            try {
                setSubmitting(true);

                const response = await reauthenticateVerifyCodeAPI(reAuthenticateSessionId, values);

                additionalVerification = response.data.additionalVerification;

                updateAdditionalVerification(additionalVerification);

                setSubmitting(false);
            } catch (error) {
                const responseData = error?.response?.data;

                additionalVerification = responseData.additionalVerification;

                updateAdditionalVerification(additionalVerification);
                reAuthenticateSessionUpdate(reAuthenticateSessionId);

                setFieldError('code', responseData.errorMessage);
                setSubmitting(false);
            }
        },
        displayName: 'ValidationCode',
    })
);
