import React from 'react';
import './style.css';
import PropTypes from 'prop-types';
const Loader = ({ height = 100, width = 100, radius = 0, ...prop }) => {
    return (
        <div
            className="skeleton-card"
            style={{
                borderRadius: `${radius}px`,
                overflow: 'hidden',
            }}
            {...prop}
        >
            <div className="wave"></div>
            <div style={{ padding: '20px', height: `${height}px`, width: `${width}px` }}>
                <div className="wave"></div>
            </div>
        </div>
    );
};

Loader.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    radius: PropTypes.number,
};
export default Loader;
