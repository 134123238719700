import React from 'react';
import PropTypes from 'prop-types';

import styles from './VerificationCard.module.scss';
import Card from 'react-bootstrap/Card';
import { ReactComponent as PassportIcon } from 'images/Passport.svg';
import { ReactComponent as QuizIcon } from 'images/Quiz.svg';
import { ReactComponent as BankIcon } from 'images/Bank.svg';
import Button from 'core-components/Button';
import cn from 'classnames';

function VerificationCard({
    handleOnClick,
    id,
    getAttemptText,
    confirmUserInformation,
    handleShow,
    isLastTry,
    KbaError,
    isDocumentVerified,
    isDocumentCardEnabled,
    isKBAVerified,
    isKbaCardEnabled,
    isBankVerified,
    isBankCardEnabled,
}) {
    const isKbaError = confirmUserInformation && !KbaError;

    const isCardCompleted = (isCardVerified) => {
        return isCardVerified ? styles.cardItemCompleted : styles.cardItemDisabled;
    };

    //For cards = if User info needs confirmation and it is not due to the KBA Error, disable the card
    const getCardClassName = (isCardEnabled, isCardVerified) => {
        return isKbaError
            ? styles.cardItemDisabled
            : cn(
                  isCardEnabled && !isCardVerified && styles.cardItem,
                  !isCardEnabled && isCardCompleted(isCardVerified)
              );
    };

    const cardProperties = {
        documentCard: {
            icon: <PassportIcon />,
            className: getCardClassName(isDocumentCardEnabled, isDocumentVerified),
            title: 'Passport or Government-Issued ID',
            subTitle: null,
            isLastTry: isLastTry,
            userFormCondition: isKbaError,
            isVerified: isDocumentVerified,
        },
        kbaCard: {
            icon: <QuizIcon />,
            className: getCardClassName(isKbaCardEnabled, isKBAVerified),
            title: 'Knowledge Authentication Quiz',
            subTitle: 'This option is only available to persons found in US commercial databases.',
            isLastTry: false,
            userFormCondition: confirmUserInformation,
            isVerified: isKBAVerified,
        },
        bankCard: {
            icon: <BankIcon />,
            className: getCardClassName(isBankCardEnabled, isBankVerified),
            title: 'Financial Account Ownership',
            subTitle: 'This option is only available to persons with US financial accounts',
            isLastTry: false,
            userFormCondition: isKbaError,
            isVerified: isBankVerified,
        },
    };

    return (
        <Card
            className={cardProperties[id].className}
            onClick={handleOnClick}
            id={id}
            style={{ margin: 0 }}
        >
            <div className={styles.cardIcon}>{cardProperties[id].icon}</div>

            <Card.Body className={styles.cardBody}>
                <Card.Title className={styles.cardHeading}>{cardProperties[id].title}</Card.Title>
                {!cardProperties[id].isVerified && cardProperties[id].subTitle && (
                    <Card.Text className={styles.cardSubheading}>
                        {cardProperties[id].subTitle}
                    </Card.Text>
                )}

                {cardProperties[id].isLastTry && (
                    <Card.Text className={styles.lastTry}>
                        Last try failed. Please try again with a valid ID.
                    </Card.Text>
                )}

                <div className={styles.attemptText}>{getAttemptText()}</div>
            </Card.Body>

            {cardProperties[id].userFormCondition && (
                <Button onClick={handleShow} block variant="link">
                    Confirm User Information to Proceed
                </Button>
            )}
        </Card>
    );
}

VerificationCard.propTypes = {
    handleOnClick: PropTypes.func,
    id: PropTypes.string,
    getAttemptText: PropTypes.func,
    confirmUserInformation: PropTypes.func,
    handleShow: PropTypes.func,
    isLastTry: PropTypes.bool,
    KbaError: PropTypes.bool,
    isDocumentVerified: PropTypes.bool,
    isDocumentCardEnabled: PropTypes.bool,
    isKBAVerified: PropTypes.bool,
    isKbaCardEnabled: PropTypes.bool,
    isBankVerified: PropTypes.bool,
    isBankCardEnabled: PropTypes.bool,
};

export default VerificationCard;
