import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
//assets
import styles from './ContinueVerification.module.scss';
import IdCardLoading from 'assets/images/id_card_loading.gif';
import { setButtonVariant } from 'utils/utilities';
import Button from 'core-components/Button';
import { connect } from 'react-redux';

const REMAINING_VERIFICATION = 'Please complete the remaining verifications.';

function ContinueVerification({ handleNextStep, profile }) {
    return (
        <Card.Body className={styles.cardBody}>
            <div className={styles.emptyResultWrapper}>
                <img src={IdCardLoading} alt="id card loading gif" />
                <p className={styles.resultDescription}>{REMAINING_VERIFICATION}</p>
                <Button
                    block
                    className={styles.submitBtn}
                    onClick={handleNextStep}
                    variant={setButtonVariant(profile?.company?.theme.button.variant)}
                >
                    Continue
                </Button>
            </div>
        </Card.Body>
    );
}

ContinueVerification.propTypes = {
    handleNextStep: PropTypes.func,
    profile: PropTypes.any,
};

function mapStateToProps(state) {
    return {
        profile: state.profile,
    };
}

export default connect(mapStateToProps, {})(ContinueVerification);
