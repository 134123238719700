import ApiService from '../../services/api/api';
import { history } from 'store';
import MedicScanService from '../../services/api/medicScan';
import { datadogRum } from '@datadog/browser-rum';
import { checkIdErrorStatus, fillMissingProperties } from 'utils/utilities';
import { trackIdCaptureEvent } from 'api/productAnalytics';
import { ID_AUTHENTICATION_TYPES } from 'utils/constants';
import { saveIdErrorMessages } from 'actions/general';

/**
 * @description Returns the country code from an Acuant result object
 */
const getDocumentIssuerCountry = (acuantResult) => {
    const classificationDetails = acuantResult.Classification?.ClassificationDetails;
    let countryCode = null;
    if (classificationDetails.Front) {
        countryCode = classificationDetails.Front.CountryCode;
    } else if (classificationDetails.Back) {
        countryCode = classificationDetails.Back.CountryCode;
    }

    return { countryCode };
};

export function processID(instanceID) {
    return (dispatch, state) => {
        const company = state()?.profile?.company;

        const instruction = state()?.instructions;

        ApiService.getResults(instanceID)
            .then(async (res) => {
                var documentObj = res;
                var base64FaceReformattedImage = null;
                var base64SignatureReformattedImage = null;
                let dataObject = {};
                if (documentObj.Fields.length > 0) {
                    /**
                     * Pass processed data to our data object
                     */

                    dataObject['countryCode'] = getDocumentIssuerCountry(documentObj).countryCode;

                    documentObj.Fields.map((field) => {
                        return (dataObject[field.Name] = field.Value);
                    });
                    if (!dataObject['First Name']) {
                        dataObject['First Name'] = dataObject['Given Name'];
                    }

                    dataObject['front'] = documentObj.Images.map((image) => image.Uri).filter(
                        (url) => url.includes('side=Front')
                    )[0];

                    dataObject['back'] = documentObj.Images.map((image) => image.Uri).filter(
                        (url) => url.includes('side=Back')
                    )[0];

                    let type = res.Result;
                    let idAuthentication = null;

                    const { UNKNOWN, ATTENTION, CAUTION, FAILED, PASSED, SKIPPED } =
                        ID_AUTHENTICATION_TYPES;

                    switch (type) {
                        case 0:
                            idAuthentication = UNKNOWN;
                            break;
                        case 1:
                            idAuthentication = PASSED;
                            break;
                        case 2:
                            idAuthentication = FAILED;
                            break;
                        case 3:
                            idAuthentication = SKIPPED;
                            break;
                        case 4:
                            idAuthentication = CAUTION;
                            break;
                        case 5:
                            idAuthentication = ATTENTION;
                            break;
                        default:
                            idAuthentication = UNKNOWN;
                            break;
                    }

                    dataObject['Authentication'] = idAuthentication;
                    /**
                     * Get face image from Acuant Service
                     * Get signature image from Acuant Service
                     * Initialize Photo & Signature with empty strings otherwise it will try to access the photo on the
                     * Acuant servers
                     *
                     * We need async / await if in case something happens with the Photo / Signature. We'll want to
                     * show the results no matter the results
                     */
                    dataObject['Photo'] = '';
                    dataObject['Signature'] = '';

                    let chunk = 5000;
                    try {
                        const faceImageResult = await ApiService.getFaceImage(instanceID);
                        let faceImageResultArray = new Uint8Array(faceImageResult);
                        let rawFaceImage = '';
                        let faceImageResultSubArray,
                            chunk = 5000;
                        for (let i = 0, j = faceImageResultArray.length; i < j; i += chunk) {
                            faceImageResultSubArray = faceImageResultArray.subarray(i, i + chunk);
                            rawFaceImage += String.fromCharCode.apply(
                                null,
                                faceImageResultSubArray
                            );
                        }
                        base64FaceReformattedImage = btoa(rawFaceImage);
                        dataObject[
                            'Photo'
                        ] = `data:image/jpeg;base64,${base64FaceReformattedImage}`;
                    } catch (err) {
                        datadogRum.addError(err);
                    }

                    try {
                        const signatureImageResult = await ApiService.getSignatureImage(instanceID);
                        let signatureImageResultArray = new Uint8Array(signatureImageResult);
                        let rawSignatureImage = '';
                        let signatureImageResultSubArray;
                        for (let i = 0, j = signatureImageResultArray.length; i < j; i += chunk) {
                            signatureImageResultSubArray = signatureImageResultArray.subarray(
                                i,
                                i + chunk
                            );
                            rawSignatureImage += String.fromCharCode.apply(
                                null,
                                signatureImageResultSubArray
                            );
                        }

                        base64SignatureReformattedImage = btoa(rawSignatureImage);

                        dataObject[
                            'Signature'
                        ] = `data:image/jpeg;base64,${base64SignatureReformattedImage}`;
                    } catch (err) {
                        datadogRum.addError(err);
                    }

                    const idErrorStatus = checkIdErrorStatus({
                        authenticationType: idAuthentication,
                        acuantResponse: res,
                    });

                    if (idErrorStatus.error) {
                        datadogRum.addError(new Error('DocumentError: Document expired'), {
                            acuantSdkError: false,
                            extra: {
                                ...idErrorStatus.properties,
                            },
                        });

                        dispatch(saveIdErrorMessages(idErrorStatus.message));
                    }

                    dispatch({
                        payload: fillMissingProperties(dataObject),
                        type: '@@acuant/ADD_ID_RESULT_DATA',
                    });

                    trackIdCaptureEvent({
                        company: company,
                        instruction: instruction,
                        errorMessage: idErrorStatus.error ? idErrorStatus.message : null,
                        authenticationStatus: idAuthentication,
                        idType: dataObject?.['Document Class Name'],
                        captureStage: 'processID',
                    });

                    if (['Unknown', 'Failed'].includes(idAuthentication))
                        return history.push('/mobile/error/default');
                } else {
                    datadogRum.addError(new Error('AcuantError: No Document Fields'), {
                        acuantSdkError: true,
                    });
                    history.push('/mobile/error/default');

                    trackIdCaptureEvent({
                        company: company,
                        instruction: instruction,
                        errorMessage: 'No Document Fields',
                        captureStage: 'processID',
                    });
                }
            })
            .catch((err) => {
                datadogRum.addError(err);
                history.push('/mobile/error/default');

                trackIdCaptureEvent({
                    company: company,
                    instruction: instruction,
                    errorMessage: err?.message,
                    captureStage: 'processID',
                });
            });
    };
}

export function processMedicard(data) {
    return (dispatch) => {
        MedicScanService.getMedicScanResults({
            instanceID: data.instanceID,
            subscriptionID: data.subscriptionID,
        })
            .then(async (res) => {
                let documentObj = res;
                if (documentObj.FrontImage) {
                    documentObj.FrontImage = `data:image/jpeg;base64,${documentObj.FrontImage}`;
                }
                if (documentObj.BackImage) {
                    documentObj.BackImage = `data:image/jpeg;base64,${documentObj.BackImage}`;
                }
                dispatch({ payload: documentObj, type: '@@acuant/ADD_ID_RESULT_DATA' });
            })
            .catch((err) => {
                datadogRum.addError(err);
                history.push('/mobile/error/default');
            });
    };
}

export function resetProcessedData() {
    return {
        type: '@@acuant/RESET_PROCESSED_DATA',
    };
}

export function increaseTries() {
    return {
        type: '@@acuant/INCREASE_TRIES',
    };
}

export function increaseFailureTries() {
    return {
        type: '@@acuant/INCREASE_FAILURE_TRIES',
    };
}

export function increaseAutoCaptureFailureCount() {
    return {
        type: '@@acuant/AUTO_CAPTURE_FAILURE_COUNT_INCREASE',
    };
}

export function resetAutoCaptureFailureCount() {
    return {
        type: '@@acuant/AUTO_CAPTURE_FAILURE_COUNT_RESET',
    };
}
