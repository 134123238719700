/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'core-components/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { Field } from 'formik';
import styles from './UserDetails.module.scss';
import moment from 'moment';
// components
import ValidatedDate from 'core-components/ValidatedDate';
import ValidatedFormInputField from 'core-components/ValidatedFormInputField';
import ValidatedDropdown from 'core-components/ValidatedDropdown';
import Checkbox from 'core-components/Checkbox';
import Image from 'core-components/Image';
import states from 'utils/constants/states';
import { eventNames } from 'utils/constants';
import { countryNames } from 'utils/constants/country';
import { trackEvent, trackEventDuration } from 'api/productAnalytics';

export default ({
    show,
    handleShow,
    handleClose,
    confirmUserInformationForAdditionalChecks,
    confirmUserInformation,
    form,
    ...props
}) => {
    const { status, handleSubmit, isSubmitting } = props;
    const countryList = useMemo(
        () => countryNames.map((name) => ({ label: name, value: name })),
        countryNames
    );

    useEffect(() => {
        const isFormCompleted = status && status.status === 'COMPLETED';
        if (isFormCompleted) {
            trackEvent({
                event: eventNames.confirmUserInfo,
                uac: props.user.uuid,
                otherValues: {
                    'Completion Time': moment().format('YYYY-MM-DD HH:mm:ss'),
                },
            });
            props.onSuccess();
        }
    }, [status]);

    useEffect(() => {
        if (props.user) {
            trackEventDuration({
                event: eventNames.confirmUserInfo,
                uac: props.user.uuid,
            });
        }
    }, []);

    const conditionallyRequired =
        confirmUserInformationForAdditionalChecks &&
        props.values.country === 'United States of America'
            ? '*'
            : '';

    const maxDate = new Date(moment().subtract(18, 'years').format());
    const isSSNRequired = confirmUserInformationForAdditionalChecks ? null : '*';
    const isMiddleNameRequired = props.values.acceptMiddleName ? null : '*';

    const handleCheckbox = () => {
        if (!props.values.acceptMiddleName) {
            props.setFieldValue('middleName', '');
        }
        props.setFieldValue('acceptMiddleName', !props.values.acceptMiddleName);
    };

    return (
        <Modal show={show} onHide={handleClose} className={styles.userDetailsModal}>
            <Modal.Header className={styles.userDetailHeader}>
                <Modal.Title className={styles.headerTxt}>Confirm User Information</Modal.Title>
                <Image name="Close.svg" onClick={handleClose} className={styles.closeBtn} />
            </Modal.Header>

            <Form onSubmit={handleSubmit} className={styles.userDetailForm}>
                <Modal.Body className={styles.cardBody}>
                    <div className={styles.descriptionBox}>
                        {!confirmUserInformationForAdditionalChecks ? (
                            <>
                                <p className={styles.description}>
                                    Please confirm your information below to generate a personal,
                                    knowledge based authenticating quiz.
                                </p>
                                <p className={styles.description}>
                                    This option is only available to individuals in the US with a
                                    valid SSN.
                                </p>
                            </>
                        ) : (
                            <>
                                <p className={styles.description}>
                                    Please confirm your information below before continuing.
                                </p>
                                <p className={styles.description}>
                                    The SSN option is only available to individuals in the US with a
                                    valid SSN.
                                </p>
                            </>
                        )}
                    </div>
                    <p className={styles.description}>* is a required field</p>
                    <p className={styles.sectionHeader}>Personal Information</p>

                    <Form.Row>
                        <Col md="4" xs="6">
                            <Form.Label className={styles.formLabel}>First Name*</Form.Label>
                            <Field
                                type="text"
                                name="firstName"
                                variant="outlined"
                                component={ValidatedFormInputField}
                                placeholder="First Name"
                                confirmUserInformation={confirmUserInformation}
                                source={props.source}
                            />
                        </Col>
                        <Col md="4" xs="6">
                            <Form.Label className={styles.formLabel}>
                                Middle Name{isMiddleNameRequired}
                            </Form.Label>
                            <Field
                                type="text"
                                variant="outlined"
                                name="middleName"
                                placeholder="Middle Name"
                                component={ValidatedFormInputField}
                                confirmUserInformation={confirmUserInformation}
                                disabled={props.values.acceptMiddleName}
                                source={props.source}
                            />
                        </Col>
                        <Col md="4" xs="6">
                            <Form.Label className={styles.formLabel}>Last Name*</Form.Label>
                            <Field
                                type="text"
                                name="lastName"
                                variant="outlined"
                                placeholder="Last Name"
                                component={ValidatedFormInputField}
                                confirmUserInformation={confirmUserInformation}
                                source={props.source}
                            />
                        </Col>
                    </Form.Row>

                    <Field
                        component={Checkbox}
                        custom
                        name="acceptMiddleName"
                        className={styles.checkBox}
                        onChange={handleCheckbox}
                        label="I don't have a middle name"
                    />

                    <Form.Row>
                        <Col md="4" xs="6">
                            <Form.Label className={styles.formLabel}>Date of Birth*</Form.Label>
                            <Field
                                maxDate={maxDate}
                                component={ValidatedDate}
                                name="dob"
                                dateFormat="MM/dd/yyyy"
                                example="MM/DD/YYYY"
                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                source={props.source}
                            />
                        </Col>
                    </Form.Row>

                    <p className={styles.sectionHeader}>Address</p>

                    <Form.Row>
                        <Col md={6} xs={6}>
                            <Form.Label className={styles.formLabel}>
                                House No.{conditionallyRequired}
                            </Form.Label>
                            <Field
                                component={ValidatedFormInputField}
                                type="text"
                                variant="outlined"
                                name="houseNumber"
                                placeholder="House Number"
                                confirmUserInformation={confirmUserInformation}
                                source={props.source}
                            />
                        </Col>
                        <Col md={6} xs={6}>
                            <Form.Label className={styles.formLabel}>
                                Street Name{conditionallyRequired}
                            </Form.Label>
                            <Field
                                component={ValidatedFormInputField}
                                type="text"
                                variant="outlined"
                                name="streetName"
                                placeholder="Street Name"
                                confirmUserInformation={confirmUserInformation}
                                source={props.source}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col md={6} xs={6}>
                            <Form.Label className={styles.formLabel}>
                                City{conditionallyRequired}
                            </Form.Label>
                            <Field
                                component={ValidatedFormInputField}
                                type="text"
                                variant="outlined"
                                name="city"
                                placeholder="City Name"
                                confirmUserInformation={confirmUserInformation}
                                source={props.source}
                            />
                        </Col>
                        <Col md={6} xs={6}>
                            <Form.Label className={styles.formLabel}>
                                ZIP Code{conditionallyRequired}
                            </Form.Label>
                            <Field
                                component={ValidatedFormInputField}
                                type="text"
                                variant="outlined"
                                name="zipCode"
                                placeholder="Zip Code"
                                autoComplete="new-password"
                                confirmUserInformation={confirmUserInformation}
                                source={props.source}
                            />
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Col xs="12">
                            <Form.Label className={styles.formLabel}>
                                Country{conditionallyRequired}
                            </Form.Label>
                            <Field
                                placeholder="Country Name"
                                component={ValidatedDropdown}
                                name="country"
                                items={countryList}
                                onChange={(event) => {
                                    props.setFieldValue('country', event.target.value);
                                    props.setFieldValue('state', '');
                                }}
                                confirmUserInformation={confirmUserInformation}
                                source={props.source}
                            />
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Col xs="12">
                            <Form.Label className={styles.formLabel}>
                                State{conditionallyRequired}
                            </Form.Label>
                            <Field
                                placeholder="Select State"
                                component={ValidatedDropdown}
                                name="state"
                                items={states}
                                disabled={
                                    !props.values ||
                                    props.values['country'] !== 'United States of America'
                                }
                                confirmUserInformation={confirmUserInformation}
                                source={props.source}
                            />
                        </Col>
                    </Form.Row>

                    <Form.Label className={styles.formLabel}>
                        Last 4 of SSN{isSSNRequired}
                    </Form.Label>
                    <Form.Row>
                        <Col md={10} xs={12}>
                            <div className={styles.ssnField}>
                                <span className={styles.prefixText}>XXX-XX</span>
                                <Field
                                    component={ValidatedFormInputField}
                                    type="password"
                                    name="ssn"
                                    variant="outlined"
                                    placeholder="XXXX"
                                    maxLength={4}
                                    autoComplete="new-password"
                                    confirmUserInformation={confirmUserInformation}
                                    source={props.source}
                                    className={styles.ssnInput}
                                />
                            </div>
                        </Col>
                    </Form.Row>
                    <Form.Label className={styles.formLabel}>Confirm SSN{isSSNRequired}</Form.Label>
                    <Form.Row>
                        <Col md={10} xs={12}>
                            <div className={styles.ssnField}>
                                <span className={styles.prefixText}>XXX-XX</span>
                                <Field
                                    component={ValidatedFormInputField}
                                    type="password"
                                    name="confirmSSN"
                                    variant="outlined"
                                    placeholder="XXXX"
                                    maxLength={4}
                                    autoComplete="new-password"
                                    confirmUserInformation={confirmUserInformation}
                                    source={props.source}
                                    className={styles.ssnInput}
                                />
                            </div>
                        </Col>
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer className={styles.userDetailFooter}>
                    <Button
                        variant="outline-primary"
                        className={styles.footerBtn}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="primary"
                        disabled={isSubmitting}
                        className={styles.footerBtn}
                        type="submit"
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
