import _ from 'lodash';
import { toast } from 'react-toastify';
import { datadogRum } from '@datadog/browser-rum';
import {
    COMPANY_ACCESS_CODES,
    DOCUMENT_ERROR_MESSAGES,
    ID_AUTHENTICATION_TYPES,
    INSTRUCTION_TYPES,
    REAUTHENTICATE_ROUTE,
} from './constants';
import { allAttemptsUsedVariants } from './constants/allAttemptsUsed';

export const toCamelCase = (snake_case_object) => {
    const camelCaseObject = {};
    _.forEach(snake_case_object, function (value, key) {
        if (_.isArray(value)) {
            value = value.map((v) => {
                return toCamelCase(v);
            });
        } else if (_.isObject(value)) {
            value = toCamelCase(value);
        }
        camelCaseObject[_.camelCase(key)] = value;
    });
    return camelCaseObject;
};

export const isFileImage = (file) => {
    return file && file['type'].split('/')[0] === 'image';
};

export const getErrorMessage = (error) => {
    if (error?.response?.data) {
        const errorObject =
            error.response.data.message ||
            error.response.data.errorMsg ||
            error.response.data.errorMessage ||
            'Something went wrong.';

        switch (typeof errorObject) {
            case 'object':
                return errorObject.errorMessage || JSON.stringify(errorObject);
            case 'string':
                return errorObject;
            default:
                return 'Something went wrong.';
        }
    }
    return 'Something went wrong.';
};

export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

/**
 * @description Checks if all ID Verification methods are left.
 *  Returns true if all verifications are completed AND passed based on the configuration
 *
 *  Caveats: -> Returns false if all methods are verified but EEP is not yet completed.
 *          -> Returns fales in the AND configuration if ATLEAST any one method is not verified.
 *          -> Returns false in the OR configuration if all methods are not verified.
 */
export const isVerificationCompleted = ({
    extendedInputs = {},
    kbaVerified,
    bankVerified,
    documentVerified,
    workflowIdVerifications = {},
}) => {
    let verificationCompleted = false;
    const isEEPEnabled = Object.values(extendedInputs).find((input) => input === true) ?? false;
    const currentVerificationStatus = {
        kbaVerified,
        bankVerified,
        documentVerified,
    };

    const { verificationOption: ignoreVerificationOption, ...idVerificationMethods } =
        workflowIdVerifications;
    // List of keys of enabled methods
    const enabledMethods = Object.keys(idVerificationMethods);

    // According to the current setup, if 1 method is mandatory, all verifications will be
    // mandatory
    const attemptAll = workflowIdVerifications[enabledMethods[0]]?.mandatory ?? false;

    const methodNameToVariableMap = {
        kba: 'kbaVerified',
        govtId: 'documentVerified',
        bank: 'bankVerified',
    };
    if (attemptAll) {
        // Documentation link for every -> https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every
        // verificationCompleted is true only if all values are true.
        verificationCompleted = enabledMethods.every((method) => {
            return currentVerificationStatus[methodNameToVariableMap[method]];
        });
    } else {
        // If any one method is verified, return true.
        verificationCompleted = enabledMethods.some((method) => {
            return currentVerificationStatus[methodNameToVariableMap[method]];
        });
    }

    return { isIdentityVerificationCompleted: verificationCompleted, isEEPEnabled };
};

export const extractHostname = (url) => {
    let hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf('//') > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
};

export const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;
    });
};

export const setButtonVariant = (themeButtonVariant) => {
    return themeButtonVariant === 'outlined' ? 'outline-primary' : 'primary';
};

export const deviceDetector = () => {
    let hasTouchScreen = false;
    if ('maxTouchPoints' in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
        const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
        if (mQ && mQ.media === '(pointer:coarse)') {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            let UA = navigator.userAgent;
            hasTouchScreen =
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
    }
    return !!hasTouchScreen;
};

export const parseArray = (value) => {
    try {
        if (!value) return [];

        let parseValue = value;

        if (`${value}`.includes(':')) {
            const splitArrayFromObject = value.split(':');
            parseValue = splitArrayFromObject?.length ? splitArrayFromObject[1] : '[]';
        }

        return JSON.parse(parseValue);
    } catch (error) {
        return [];
    }
};

export const removeSpecialCharacters = (value) => {
    const string = value.replace(/[^a-zA-Z ]/g, '');
    return string.trim();
};

export const extractAcuantResult = ({
    requiredObjectKeys = [],
    listOfResults = [],
    specificIdentifierKey = 'Name',
    specificValueKey = 'Disposition', //Key of the specific property value want to access from acuant object
    terminateLoopMatchFound = false,
}) => {
    const resultLists = [];
    for (const object of listOfResults) {
        if (requiredObjectKeys.includes(object?.[specificIdentifierKey])) {
            resultLists.push(object?.[specificValueKey]);

            if (terminateLoopMatchFound) break;
        }
    }

    return resultLists;
};

export const ignoreFalsyValues = (props) => {
    return Object.fromEntries(
        Object.entries(props).filter(([key, value]) => !['', null, undefined].includes(value))
    );
};

export const fillMissingProperties = (objectToCheck) => {
    const REQUIRED_KEYS = [
        'First Name',
        'Full Name',
        'Middle Name',
        'Address',
        'Address City',
        'Address Postal Code',
        'Address State',
        'Birth Date',
        'Document Class Name',
        'Expiration Date',
        'Issuing State Name',
        'Issuing State Code',
        'Issue Date',
        'Surname',
        'License Class',
    ];

    const missingProperties = {};

    const existingKeys = Object.keys(objectToCheck);

    REQUIRED_KEYS.forEach((requiredKey) => {
        if (!existingKeys.includes(requiredKey)) {
            missingProperties[requiredKey] = null;
        }
    });

    return { ...objectToCheck, ...missingProperties };
};

export const verifyCountry = (allowedCountries, userCountry, history) => {
    if (!allowedCountries || !userCountry) return;

    if (allowedCountries === 'all') return;

    const countries = parseArray(allowedCountries);

    if (!countries?.includes(userCountry)) {
        return true; //country mismatch
    }
};

// get the list of extended inputs that has been enabled in Array
export const getExtendedInputsArray = (obj) => {
    const extendedChecks = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === true) {
            extendedChecks.push(key);
        }
    }
    return extendedChecks;
};

// functionality to redirect to success and error screen based on Finicity result
export const handleResultScreenRedirect = (
    profile,
    verificationConfiguration,
    lastPoint,
    history
) => {
    const { company, workflow } = profile;

    const bothEitherMethod =
        verificationConfiguration === 'AND' || verificationConfiguration === 'OR';
    const singleMethod =
        verificationConfiguration === 'SINGLE' && workflow?.idVerifications?.bank?.enabled;
    const bankAttemptsUsed = lastPoint?.data?.attempts >= workflow?.idVerifications?.bank?.attempts;
    const isDocumentVerifiedForThis = workflow?.idVerifications?.govtId?.enabled
        ? company?.user?.verificationDetails?.isDocumentValid
        : null;

    const currentKbaAttempts = company?.user?.verificationDetails?.kbaAttempts;
    const totalKbaAttempts = profile?.workflow?.idVerifications?.kba?.attempts;
    const isKbaAttemptsUsed = currentKbaAttempts >= totalKbaAttempts;
    const isKbaVerified = company?.user?.verificationDetails?.isKbaVerified === 1;
    const isKbaAvailable = !isKbaVerified && !isKbaAttemptsUsed;
    const isKBAVerifiedForThis = workflow?.idVerifications?.kba?.enabled ? isKbaAvailable : null;

    const allBankAttemptsUsed = () => {
        if (bankAttemptsUsed) {
            history.push('verification/bank/failed');
        } else {
            toast.error('Bank account details don’t match your details. Please try again.');
        }
    };

    if (!lastPoint) return null;

    if (lastPoint?.data?.is_owner_matched) {
        history.push('verification/bank/success');
    } else {
        if (bothEitherMethod && (isDocumentVerifiedForThis || isKBAVerifiedForThis)) {
            toast.error('Bank account details don’t match your details. Please try again.');
        } else if (bothEitherMethod && !isDocumentVerifiedForThis && !isKBAVerifiedForThis) {
            allBankAttemptsUsed();
        } else if (singleMethod) {
            allBankAttemptsUsed();
        }
    }
};

export const hideEmailPhoneInfo = (value) => {
    if (typeof value !== 'string') return;
    let firstChar = value.charAt(0);
    let replacedString = firstChar + value.substring(1, 5).replace(/./g, '*') + value.slice(5);
    return replacedString;
};

export const minimalToaster = (message) =>
    toast(message, {
        theme: 'dark',
        hideProgressBar: true,
    });

// function to find enabled check and if verified or completed return AND condition between the checks
export const filterActiveCards = (profile, govt_card, kba_card, bav_card) => {
    const { workflow } = profile;

    const card_condition = {
        govt_id: govt_card,
        kba: kba_card,
        bank: bav_card,
    };

    const idVerification = workflow?.idVerifications;
    const enabledCardCondition = [];

    for (const key in idVerification) {
        const check = idVerification[key];
        if (check.enabled) {
            enabledCardCondition.push(card_condition[key]);
        }
    }

    return enabledCardCondition.every(Boolean);
};

export const getReauthenticateAdditionalMethodData = (additionalVerification) => {
    if (!additionalVerification) return {};

    const verifyMethod = additionalVerification?.email ? 'Email' : 'Phone';

    const verifyMethodData =
        verifyMethod === 'Email' ? additionalVerification.email : additionalVerification?.phone;

    return { ...verifyMethodData, method: verifyMethod };
};

export const convertSingleToDoubleDigit = (num) => {
    return num.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
};

export const isReAuthenticateRoute = (pathname) => {
    return pathname?.includes(REAUTHENTICATE_ROUTE);
};

//check ID front/back sides have expired date
const anyOfIdSidesExpired = ({ acuantResponse }) => {
    let expired = false;

    try {
        // Acuant generates an array of objects named Fields, encompassing all details related to the user-uploaded document.
        // Within this array, we aim to gather objects associated with the expiration date.
        // Such objects have a property named "DataFieldReferences", housing IDs of expiration date objects from both document sides (front and back).
        // By examining the expiration date values within these objects, we can determine whether any side of the document exhibits an expired date.

        //collect expiration date object IDs of front and back sides of document
        const expireDateDataFieldIds = extractAcuantResult({
            specificValueKey: 'DataFieldReferences',
            requiredObjectKeys: ['Expiration Date'],
            listOfResults: acuantResponse.Fields,
            terminateLoopMatchFound: true,
        });

        //Collect expire dates belong to expiration object IDs
        const expirationDates = extractAcuantResult({
            specificValueKey: 'Value',
            specificIdentifierKey: 'Id',
            requiredObjectKeys: expireDateDataFieldIds[0],
            listOfResults: acuantResponse.DataFields,
        });

        expirationDates?.forEach((date) => {
            const expireDate = parseInt(
                date.substring(date.indexOf('(') + 1, date.indexOf(')') + 1)
            );

            if (new Date() > new Date(expireDate)) {
                expired = true;
            }
        });
    } catch (error) {
        datadogRum.addError(
            new Error('DocumentError: Failed to check expiration dates of the document'),
            {
                acuantSdkError: false,
                extra: { error },
            }
        );
    }

    return expired;
};

const getAcuantPropertiesForLogging = (acuantResponse) => {
    try {
        const { Alerts, Result, Images } = acuantResponse ?? {};

        const data = {
            status: Result,
            alerts: Alerts,
        };

        if (Images?.length) {
            data.documentImages = Images.map((document) => {
                const properties = {
                    ...document,
                };

                delete properties?.Uri;

                return properties;
            });
        }

        return data;
    } catch (error) {}
};

export const checkIdErrorStatus = ({ authenticationType, acuantResponse }) => {
    const result = {
        error: false,
        message: [],
        properties: getAcuantPropertiesForLogging(acuantResponse) ?? {},
    };

    const { EXPIRED, EXPIRE_DATE_MISMATCH } = DOCUMENT_ERROR_MESSAGES;

    const errors = extractAcuantResult({
        listOfResults: acuantResponse?.Alerts,
        requiredObjectKeys: ['Document Expired', 'Expiration Date Crosscheck'],
    });

    const expiredDocument = errors?.includes(EXPIRED);

    const expireDateMismatchError = errors?.includes(EXPIRE_DATE_MISMATCH);

    const idSidesExpired = anyOfIdSidesExpired({ acuantResponse });

    if (authenticationType === ID_AUTHENTICATION_TYPES.ATTENTION && expiredDocument) {
        result.error = true;
        result.message = [EXPIRED];

        return result;
    }

    //user uploaded expired front and valid back images, or vice versa
    if (expireDateMismatchError && idSidesExpired) {
        result.error = true;
        result.message = [EXPIRED];
    }

    return result;
};

export const modifyThankYouPageContent = ({ companyUUID, companyName }) => {
    const result = {
        description: '',
        defaultText: 'You’re all done!',
        defaultBtnText: `Return To ${companyName}`,
    };

    if (companyUUID === COMPANY_ACCESS_CODES.CONSTELLATION) {
        result.description =
            'Thank you for submitting your information. We are processing your request.';
    }

    if (companyUUID === COMPANY_ACCESS_CODES.NEWLINE_AI) {
        result.description = 'Click on the button below to finish your application.';
        result.defaultBtnText = 'Return To Your Application';
        result.defaultText = '';
    }

    return result;
};

export const getAllAttemptsUsedText = (fromMobile, continueAlternateOptions) => {
    if (!fromMobile) {
        return allAttemptsUsedVariants.RETURN_TO_DESKTOP;
    }

    return continueAlternateOptions
        ? allAttemptsUsedVariants.ALTERNATE_VERIFICATION
        : allAttemptsUsedVariants.CONTACT_ADMIN;
};

// Computes each ID verification details.
// By doing this, we can avoid the need to repeatedly check the state.
export const extractIdVerification = (state) => {
    const defaultState = {
        verified: null,
        enabled: false, //presence of verification in the workflow
        metaData: { totalAttempts: 0, attemptsUsed: 0, allAttemptsUsed: false },
    };

    const idVerifications = state?.profile?.company?.user?.verificationDetails ?? {};

    const idVerificationWorkflowConfig = state?.profile?.workflow?.idVerifications ?? {};

    const result = {
        KBA: defaultState,
        //todo: add govt_id and BAV information
    };

    if (idVerificationWorkflowConfig?.kba) {
        result.KBA.metaData.attemptsUsed = parseInt(idVerifications.kbaAttempts);
        result.KBA.metaData.totalAttempts = idVerificationWorkflowConfig.kba.attempts;
        result.KBA.verified = idVerifications.isKbaVerified;
        result.KBA.enabled = true;
        result.KBA.metaData.allAttemptsUsed =
            parseInt(idVerifications.kbaAttempts) >= idVerificationWorkflowConfig.kba.attempts;
    }

    return result;
};

export const renderEndInstructionBtnText = (instructionType) => {
    switch (instructionType) {
        case INSTRUCTION_TYPES.ID:
            return 'Capture ID';
        case INSTRUCTION_TYPES.SELFIE:
            return 'Take Selfie';
        default:
            return 'Next';
    }
};
