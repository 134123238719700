import * as Yup from 'yup';

function equalTo(ref, msg) {
    return Yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg || 'Path must be the same as Reference',
        params: {
            reference: ref.path,
        },
        test: function (value) {
            return value === this.resolve(ref);
        },
    });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export default Yup.object().shape({
    firstName: Yup.string().required('Required'),
    middleName: Yup.string().when('noMiddleName', {
        is: false,
        then: Yup.string().required('Required'),
    }),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid Email').required('Required'),
    dob: Yup.string().nullable().required('Required'),
    accept: Yup.boolean().oneOf([true], '*Field must be checked'),
    phoneNumber: Yup.string().min(6, 'Invalid number').required('Required'),
    captcha: Yup.string().required('Required'),
    passcode: Yup.boolean().oneOf([true], '*Field must be checked'),
});
