import { connect } from 'react-redux'
import { updateProfile } from 'actions/profile'
import { getProfile } from 'actions/profile'

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        session: state.session,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (data) => dispatch(updateProfile(data)),
        getProfile: () => dispatch(getProfile()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)