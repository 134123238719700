import React from 'react'
import OnboardingForm from './OnboardingForm'
import OnboardingSuccess from './OnboardingSuccess'
import OTPForm from './OTPForm'

export default () => {
  const [token, setToken] = React.useState({ value: null, verified: false })

  let LINK = 'https://verify.authenticating.com'
  if (typeof window !== 'undefined') {
    LINK =
      window.location.protocol +
      '//' +
      window.location.host +
      '?token=' +
      token.value
  }

  if (token.verified) {
    return <OnboardingSuccess link={LINK} metadata={token} />
  }

  if (token.value) {
    return (
      <OTPForm
        metadata={token}
        token={token.value}
        reset={() => setToken({ value: null, verified: false })}
        setVerified={(verified) => setToken({ ...token, verified: verified })}
      />
    )
  }

  return (
    <OnboardingForm
      setToken={(token, metadata) =>
        setToken({ value: token, verified: false, ...metadata })
      }
    />
  )
}
