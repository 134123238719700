import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'utils/constants';

export const formValuesToAPI = (formValues) => {
    return {
        firstName: formValues.firstName,
        middleName: formValues.middleName,
        lastName: formValues.lastName,
        dob: moment(formValues.dob).format(DEFAULT_DATE_FORMAT),
        houseNumber: formValues.houseNumber,
        streetName: formValues.streetName,
        address: [formValues.houseNumber, formValues.streetName].filter(Boolean).join(', '),
        city: formValues.city,
        zipCode: formValues.zipCode,
        state: formValues.state,
        country: formValues.country,
        ssn: formValues.ssn, // BELOW 2 options to be removed once workflows are setup.
        customVerificationOptions: formValues.customVerificationOptions,
        token: formValues.customVerificationOptions ? formValues.token : null,
    };
};

export const reduxToFormValues = (reduxValues) => {
    return {
        ...reduxValues,
        dob: moment(reduxValues.dateOfBirth, 'YYYY-MM-DD'),
        ssn: '',
    };
};
