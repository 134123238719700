import { REQUEST_KBA, UPDATE_KBA, RESET_ALL_REDUCERS, RESET_KBA } from 'actions';

export default (state = {}, action) => {
    return {
        ...state,
        kba: kba(state.kba, action),
        // tries: tries(state.tries, action)
    };
};

const kba = (state = { loading: false }, action) => {
    switch (action.type) {
        case REQUEST_KBA:
            return { ...state, loading: true };
        case UPDATE_KBA:
            return { ...action.data, loading: false };
        case RESET_KBA:
        case RESET_ALL_REDUCERS:
            return { loading: false };
        default:
            return state;
    }
};

// const tries = (state = { kba: 0, passport: 0, license: 0 }, action) => {
//   switch (action.type) {
//     case RESET_TRIES:
//       return { kba: 0, passport: 0, license: 0 }
//     case UPDATE_TRIES:
//       const update = {};
//       update[action.data] = state[action.data] + 1;
//       return Object.assign({}, state, update);
//     default:
//       return state
//   }
// }
