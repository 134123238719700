import { connect } from 'react-redux'
import { withFormik } from 'formik'
import validationSchema from './OTPForm.validation'
import { compose } from 'redux'
import { toast } from 'react-toastify'
import { getErrorMessage } from 'utils/utilities'
import {
  twoFactorAuth,
  verifyTwoFactorAuth,
  updateSessionToken,
} from 'actions/session'

const initialValues = {
  value: '',
}

const mapDispatchToProps = (dispatch) => {
  return {
    twoFactorAuth: (token) => dispatch(twoFactorAuth(token)),
    verifyTwoFactorAuth: (code, token) =>
      dispatch(verifyTwoFactorAuth(code, token)),
    updateSessionToken: (token, redirect) =>
      dispatch(updateSessionToken(token, redirect)),
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => initialValues,
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, props }) => {
      props
        .verifyTwoFactorAuth(values.value, props.token)
        .then((result) => {
          setSubmitting(false)
          props.setVerified(true)
        })
        .catch((error) => {
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.errorMessage) ||
              getErrorMessage(error),
          )
          setSubmitting(false)
        })
    },
    displayName: 'OTPForm',
  }),
)
