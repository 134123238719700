import { toCamelCase } from 'utils/utilities';
import http from './http';

export const reauthenticateStatusAPI = (sessionUUID) => {
    let config = {
        headers: {
            'X-Reauthenticate-Session': sessionUUID,
        },
    };
    return http.get('/reauthenticate/session/status', config);
};

export const reauthenticateSelfieVerificationApi = (sessionUUID, data) => {
    let config = {
        headers: {
            'X-Reauthenticate-Session': sessionUUID,
        },
    };

    return http.post('/reauthenticate/session/selfie', toCamelCase(data), config);
};

export const updateReauthenticateStatusAPI = (data, sessionUUID) => {
    let config = {
        headers: {
            'X-Reauthenticate-Session': sessionUUID,
        },
    };
    return http.post('/reauthenticate/session/progress', { status: data }, config);
};

export const sendReauthenticateMessageAPI = (data, sessionUUID) => {
    let config = {
        headers: {
            'X-Reauthenticate-Session': sessionUUID,
        },
    };
    return http.post('/reauthenticate/session/request/phone', data, config);
};

export const sendReauthenticateEmailAPI = (data, sessionUUID) => {
    let config = {
        headers: {
            'X-Reauthenticate-Session': sessionUUID,
        },
    };
    return http.post('/reauthenticate/session/request/email', data, config);
};

export const reauthenticateVerifyCodeAPI = (sessionUUID, data) => {
    let config = {
        headers: {
            'X-Reauthenticate-Session': sessionUUID,
        },
    };

    return http.post('/reauthenticate/session/code', data, config);
};

export const reauthenticateCodeResendAPI = (sessionUUID) => {
    let config = {
        headers: {
            'X-Reauthenticate-Session': sessionUUID,
        },
    };

    return http.get('/reauthenticate/session/code', config);
};

export const getDeviceInfoAPI = (sessionUUID) => {
    let config = {
        headers: {
            'X-Reauthenticate-Session': sessionUUID,
        },
    };
    return http.get('/reauthenticate/session/information', config);
};
