import React from 'react'
import Image from 'core-components/Image'
import styles from './NotFound.module.scss'
import Background from 'core-components/Background'
import Card from 'react-bootstrap/Card'
import Navbar from 'core-components/Navbar'

export default (props) => {
  return (
    <>
      <Navbar />
      <Background className={styles.notFoundWrapper}>
        <Card.Body className={styles.body}>
          <Image
            className={styles.image}
            name={props.image || 'page-not-found.svg'}
          />
          <br />
          <h5>
            {props.heading || 'Page not found or this device not supported.'}
          </h5>
        </Card.Body>
        {props.children}
      </Background>
    </>
  )
}
