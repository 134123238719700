import React from 'react';
import PropTypes from 'prop-types';

import classes from './carousel.module.css';
import Item from '../../item';

const Carousel = ({ activeSlide, slides, slideDirection }) => {
    return (
        <div className={classes.carousel}>
            {slides.map((item) => (
                <Item
                    key={item.id}
                    item={item}
                    activeSlide={activeSlide}
                    slideDirection={slideDirection}
                    totalSlide={slides.length}
                />
            ))}
        </div>
    );
};

Carousel.propTypes = {
    activeSlide: PropTypes.number,
    slides: PropTypes.array,
    slideDirection: PropTypes.oneOf(['left', 'right']),
};

export default Carousel;
