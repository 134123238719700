import React from 'react';
import { connect } from 'react-redux';
import { getProfile, setConsent } from 'actions/profile';
import { updateReauthenticateStatus } from 'actions/reAuthenticate';
import { compose } from 'redux';
import { withFormik } from 'formik';
import validationSchema from './Dashboard.validation';
import { REAUTHENTICATE_ROUTE, REAUTHENTICATE_ROUTES, eventNames } from 'utils/constants';
import { isMobile } from 'react-device-detect';
import { trackEventDuration } from 'api/productAnalytics';
import { verifyCaptcha } from 'actions/session';
import { isReAuthenticateRoute } from 'utils/utilities';

const handleReauthenticateSubmit = ({
    history,
    updateReauthenticateStatus,
    reAuthenticateSessionID,
}) => {
    if (isMobile) {
        history.push(REAUTHENTICATE_ROUTES.CAPTURE_SELFIE);
        updateReauthenticateStatus('IN_PROGRESS', reAuthenticateSessionID);
    } else {
        history.push(`/${REAUTHENTICATE_ROUTE}/link`);
    }
};

const handleMedallionSubmit = async ({ renderNextStep, profile, setUserConsent }) => {
    try {
        const userDetails = profile?.company?.user?.userDetails;

        if (!userDetails) return;

        const userFullName = [
            userDetails?.firstName,
            userDetails?.middleName,
            userDetails?.lastName,
        ]
            .filter((x) => typeof x === 'string' && x.length > 0)
            .join(' ');

        trackEventDuration({
            event: eventNames.completed,
            uac: userDetails.uuid,
        });

        if (profile?.company?.user?.consentEntry) {
            return renderNextStep();
        }

        await setUserConsent(userDetails.uuid, userFullName);

        renderNextStep();
    } catch (error) {
        return Promise.reject(error);
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        session: state.session,
        verificationConfiguration: state.general.verificationConfiguration,
        reAuthenticateSessionID: state.reAuthenticate?.reAuthenticateSessionID,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
        setConsent: (userAccessCode, name) => dispatch(setConsent(userAccessCode, name)),
        updateReauthenticateStatus: (data, sessionUUID) =>
            dispatch(updateReauthenticateStatus(data, sessionUUID)),

        verifyCaptcha: (data) => dispatch(verifyCaptcha(data)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: (props) => {
            const initialValues = {
                agree: false,
                captchaRef: React.createRef(),
                captcha: '',
            };

            //For reauthenticate flow, user not required to give consent
            if (isReAuthenticateRoute(props.location?.pathname)) {
                initialValues.agree = true;
            }

            return initialValues;
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
            try {
                const {
                    history,
                    profile,
                    updateReauthenticateStatus,
                    reAuthenticateSessionID,
                    location,
                    setConsent,
                    renderNextStep,
                    verifyCaptcha,
                } = props;

                const reauthenticateRoute = isReAuthenticateRoute(location?.pathname);

                if (reauthenticateRoute) {
                    return handleReauthenticateSubmit({
                        history,
                        reAuthenticateSessionID,
                        updateReauthenticateStatus,
                    });
                }

                setSubmitting(true);

                const validCaptcha = await verifyCaptcha(values.captcha);

                if (validCaptcha) {
                    await handleMedallionSubmit({
                        profile,
                        renderNextStep,
                        setUserConsent: setConsent,
                    });
                }

                setSubmitting(false);
            } catch (error) {
                values.captchaRef.current.reset();
                setSubmitting(false);
                setErrors({
                    captcha: 'Captcha verification failed. Please try again.',
                });
            }
        },
    })
);
