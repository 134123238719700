import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import { Field, FieldArray } from 'formik';
import cn from 'classnames';
import Form from 'react-bootstrap/Form';
import Button from 'core-components/Button';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
// components
import styles from './EducationInformation.module.scss';
import ValidatedDate from 'core-components/ValidatedDate';
import ValidatedFormInputField from 'core-components/ValidatedFormInputField';
import ValidatedDropdown from 'core-components/ValidatedDropdown';
import Checkbox from 'core-components/Checkbox';
import Loader from 'core-components/Loader';
import Background from 'core-components/Background';
import ValidatedRadioFields from 'core-components/ValidatedRadioFields';
import CreatableAsyncAll from 'core-components/CreatableSelect/AsyncSelect';
import CreatableAll from 'core-components/CreatableSelect/creatableSelect';
import Image from 'core-components/Image';
import DeleteWarningPopup from 'core-components/DeleteWarningPopup';
import country from 'utils/constants/country';
import { emptyEductionFormValues, eductionFromOptions, dateMask } from 'utils/constants';
import { schoolTypes } from 'utils/constants/schoolTypes';
import states from 'utils/constants/states';
// assets
import { ReactComponent as Delete } from 'images/Close.svg';

export default ({
    show,
    handleShow,
    handleClose,
    profile,
    renderLastStep,
    renderNextStep,
    isLastPoint,
    isPreviousFormSubmitted,
    ...props
}) => {
    const { status, handleSubmit, isSubmitting, values, verificationStep, location, history } =
        props;

    const isEductionRecorded = profile?.company?.user?.verificationDetails?.isEductionRecorded;

    useEffect(() => {
        isEductionRecorded && renderNextStep();
    }, [isEductionRecorded, renderNextStep]);

    const [collapse, setCollapse] = useState(0);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        isLastPoint && renderLastStep();
    }, [isLastPoint, renderLastStep]);

    const fetchDegrees = async (inputValue) => {
        const { data } = await props.getDegree(profile.company.user.userDetails.uuid, inputValue);
        return data;
    };

    const fetchSchools = async (inputValue) => {
        const { data } = await props.getSchool(profile.company.user.userDetails.uuid, inputValue);
        return data.map((val) => {
            return {
                label: val.school_name,
                value: val.school_name,
                dates: {
                    lastUpdated: val.last_updated,
                    coverageStartDate: val.coverage_started,
                },
            };
        });
    };

    const deleteEducationForm = (education, pop) => {
        const isFormEdited =
            education.campusName ||
            education.city ||
            education.end ||
            education.start ||
            education.degree ||
            education.majors.length ||
            education.minors.length ||
            education.isCurrent ||
            education.schoolName ||
            education.schoolType ||
            education.state ||
            education.status ||
            education.zipCode;

        if (isFormEdited) {
            setShowModal(true);
        } else {
            setCollapse(collapse - 1);
            pop();
        }
    };

    const handleOpenForm = (index) => setCollapse(index);

    const handleDeleteForm = (e, data, pop) => {
        e.stopPropagation();
        deleteEducationForm(data, pop);
    };

    const handleSkip = () => {
        saveFormToLocal();
        renderNextStep();
    };

    const saveFormToLocal = () => {
        sessionStorage.setItem('educationForm', JSON.stringify(values.educationInfo));
    };

    const displayBackButton =
        location.pathname !== profile?.defaultRoute[2] &&
        !isPreviousFormSubmitted(location.pathname);

    return (
        <>
            <Background className={styles.wrapper}>
                <Card.Header className={styles.educationHeader}>
                    {displayBackButton && (
                        <Alert.Link
                            className={styles.backArrow}
                            onClick={() => {
                                saveFormToLocal();
                                history.goBack();
                            }}
                        >
                            <Image name="Back.svg" />
                        </Alert.Link>
                    )}

                    <div>
                        <Card.Title className={styles.heading}>Education Verification</Card.Title>
                        <Card.Text className={styles.subHeading}>{verificationStep}</Card.Text>
                    </div>
                </Card.Header>

                <Form onSubmit={handleSubmit}>
                    <FieldArray
                        name="educationInfo"
                        render={({ pop, push, form, ...rest }) => (
                            <>
                                {status ? (
                                    <Loader />
                                ) : (
                                    <>
                                        {values.educationInfo.map(
                                            (education, index, educationInfo) => (
                                                <Fragment key={index}>
                                                    <DeleteWarningPopup
                                                        name="Education"
                                                        showModal={showModal}
                                                        setShowModal={setShowModal}
                                                        collapse={collapse}
                                                        setCollapse={setCollapse}
                                                        data={educationInfo}
                                                        pop={pop}
                                                        isSubmitting={isSubmitting}
                                                    />
                                                    <div
                                                        className={cn(
                                                            styles.sectionHeader,
                                                            collapse !== index &&
                                                                styles.closeSectionHeader
                                                        )}
                                                        onClick={() => handleOpenForm(index)}
                                                    >
                                                        <p className={styles.formTitle}>
                                                            Education {index + 1}
                                                        </p>
                                                        {educationInfo?.length - 1 === index &&
                                                            index !== 0 &&
                                                            collapse ===
                                                                educationInfo?.length - 1 && (
                                                                <Button
                                                                    className={styles.deleteBtn}
                                                                    onClick={(e) =>
                                                                        handleDeleteForm(
                                                                            e,
                                                                            education,
                                                                            pop
                                                                        )
                                                                    }
                                                                >
                                                                    <Delete />
                                                                </Button>
                                                            )}
                                                    </div>
                                                    <div>
                                                        <Collapse in={index === collapse}>
                                                            <Card.Body
                                                                className={styles.cardBody}
                                                                key={index}
                                                            >
                                                                <Form.Row>
                                                                    <Col
                                                                        xs="12"
                                                                        className={
                                                                            styles.searchField
                                                                        }
                                                                    >
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            School Name *
                                                                        </Form.Label>
                                                                        <Field
                                                                            type="text"
                                                                            name={`educationInfo.${index}.schoolName`}
                                                                            variant="outlined"
                                                                            placeholder="School name"
                                                                            component={
                                                                                CreatableAsyncAll
                                                                            }
                                                                            asyncMethod={
                                                                                fetchSchools
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col xs="12">
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            Campus Name *
                                                                        </Form.Label>
                                                                        <Field
                                                                            type="text"
                                                                            name={`educationInfo.${index}.campusName`}
                                                                            variant="outlined"
                                                                            component={
                                                                                ValidatedFormInputField
                                                                            }
                                                                            placeholder="Institution campus name"
                                                                            defaultValue={
                                                                                educationInfo[index]
                                                                                    .campusName
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Form.Row>

                                                                <p
                                                                    className={
                                                                        styles.formSectionHeading
                                                                    }
                                                                >
                                                                    Address *
                                                                </p>

                                                                <Form.Row>
                                                                    <Col md="6" xs="12">
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            City
                                                                        </Form.Label>
                                                                        <Field
                                                                            component={
                                                                                ValidatedFormInputField
                                                                            }
                                                                            type="text"
                                                                            variant="outlined"
                                                                            name={`educationInfo.${index}.city`}
                                                                            placeholder="City name"
                                                                            defaultValue={
                                                                                educationInfo[index]
                                                                                    .city
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col md="6" xs="12">
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            ZIP Code
                                                                        </Form.Label>
                                                                        <Field
                                                                            type="text"
                                                                            component={
                                                                                ValidatedFormInputField
                                                                            }
                                                                            variant="outlined"
                                                                            name={`educationInfo.${index}.zipCode`}
                                                                            placeholder="ZIP code"
                                                                            defaultValue={
                                                                                educationInfo[index]
                                                                                    .zipCode
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Form.Row>
                                                                <Form.Row>
                                                                    <Col xs={12}>
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            Country
                                                                        </Form.Label>
                                                                        <Field
                                                                            component={
                                                                                ValidatedDropdown
                                                                            }
                                                                            name={`educationInfo.${index}.country`}
                                                                            items={country}
                                                                            disabled={true}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            State
                                                                        </Form.Label>
                                                                        <Field
                                                                            placeholder="State name"
                                                                            component={
                                                                                ValidatedDropdown
                                                                            }
                                                                            name={`educationInfo.${index}.state`}
                                                                            items={states}
                                                                        />
                                                                    </Col>
                                                                </Form.Row>

                                                                <Form.Row>
                                                                    <Col xs="12">
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            Start and End Date{' '}
                                                                            <span>
                                                                                (must be exact month
                                                                                and year)
                                                                            </span>
                                                                            *
                                                                        </Form.Label>
                                                                    </Col>
                                                                </Form.Row>

                                                                <Form.Row
                                                                    className={styles.dateRow}
                                                                >
                                                                    <Col md="6" xs="6">
                                                                        <Field
                                                                            component={
                                                                                ValidatedDate
                                                                            }
                                                                            labelClass={
                                                                                styles.formLabel
                                                                            }
                                                                            name={`educationInfo.${index}.start`}
                                                                            label="Start"
                                                                            maxDate={moment().toDate()}
                                                                            showMonthYearPicker
                                                                            dateFormat="MM/yyyy"
                                                                            example="MM/YYYY"
                                                                            mask={dateMask}
                                                                        />
                                                                    </Col>
                                                                    <Col md="6" xs="6">
                                                                        <Field
                                                                            component={
                                                                                ValidatedDate
                                                                            }
                                                                            labelClass={
                                                                                styles.formLabel
                                                                            }
                                                                            name={`educationInfo.${index}.end`}
                                                                            label="End"
                                                                            minDate={moment(
                                                                                education.start
                                                                            ).toDate()}
                                                                            maxDate={moment().toDate()}
                                                                            showMonthYearPicker
                                                                            dateFormat="MM/yyyy"
                                                                            example="MM/YYYY"
                                                                            mask={dateMask}
                                                                            disabled={
                                                                                education.isCurrent
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Field
                                                                        component={Checkbox}
                                                                        custom
                                                                        name={`educationInfo.${index}.isCurrent`}
                                                                        className={styles.checkBox}
                                                                        label="Current"
                                                                    />
                                                                </Form.Row>

                                                                <Form.Row>
                                                                    <Col
                                                                        xs="12"
                                                                        className={
                                                                            styles.searchField
                                                                        }
                                                                    >
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            Degree *
                                                                        </Form.Label>
                                                                        <Field
                                                                            placeholder="Select degree"
                                                                            name={`educationInfo.${index}.degree`}
                                                                            component={
                                                                                CreatableAsyncAll
                                                                            }
                                                                            asyncMethod={
                                                                                fetchDegrees
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Form.Row>

                                                                <Form.Row>
                                                                    <Col xs="12">
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            School Type *
                                                                        </Form.Label>
                                                                        <Field
                                                                            placeholder="Select school type"
                                                                            component={
                                                                                ValidatedDropdown
                                                                            }
                                                                            name={`educationInfo.${index}.schoolType`}
                                                                            items={schoolTypes}
                                                                        />
                                                                    </Col>
                                                                </Form.Row>

                                                                <Form.Row>
                                                                    <Col md="6" xs="12">
                                                                        <Field
                                                                            component={
                                                                                ValidatedRadioFields
                                                                            }
                                                                            name={`educationInfo.${index}.status`}
                                                                            options={
                                                                                eductionFromOptions.attending
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col md={6} xs={12}>
                                                                        <Field
                                                                            component={
                                                                                ValidatedRadioFields
                                                                            }
                                                                            name={`educationInfo.${index}.status`}
                                                                            options={
                                                                                eductionFromOptions.completed
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Form.Row>

                                                                <Form.Row>
                                                                    <Col
                                                                        xs="12"
                                                                        className={
                                                                            styles.searchField
                                                                        }
                                                                    >
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            Major{' '}
                                                                            <span>
                                                                                (Type and press
                                                                                Enter or Tab to add
                                                                                another major)
                                                                            </span>
                                                                        </Form.Label>
                                                                        <Field
                                                                            component={CreatableAll}
                                                                            isMulti={true}
                                                                            name={`educationInfo.${index}.majors`}
                                                                            errorName="Major"
                                                                            placeholder="Major"
                                                                            value={
                                                                                form.values?.educationInfo[
                                                                                    index
                                                                                ]?.majors?.map(
                                                                                    (major) => ({
                                                                                        label: major,
                                                                                        value: major,
                                                                                    })
                                                                                ) ?? []
                                                                            }
                                                                            onChange={(value) => {
                                                                                const reduced =
                                                                                    value.reduce(
                                                                                        (
                                                                                            acc,
                                                                                            curr
                                                                                        ) => [
                                                                                            ...acc,
                                                                                            curr.value,
                                                                                        ],
                                                                                        []
                                                                                    );

                                                                                form.setFieldValue(
                                                                                    `educationInfo.${index}.majors`,
                                                                                    reduced
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Form.Row>

                                                                <Form.Row>
                                                                    <Col xs="12">
                                                                        <Form.Label
                                                                            className={
                                                                                styles.formLabel
                                                                            }
                                                                        >
                                                                            Minor{' '}
                                                                            <span>
                                                                                (Type and press
                                                                                Enter or Tab to add
                                                                                another minor)
                                                                            </span>
                                                                        </Form.Label>
                                                                        <Field
                                                                            component={CreatableAll}
                                                                            isMulti={true}
                                                                            name={`educationInfo.${index}.minors`}
                                                                            errorName="Minor"
                                                                            placeholder="Minor"
                                                                            value={
                                                                                form.values?.educationInfo[
                                                                                    index
                                                                                ]?.minors?.map(
                                                                                    (minor) => ({
                                                                                        label: minor,
                                                                                        value: minor,
                                                                                    })
                                                                                ) ?? []
                                                                            }
                                                                            onChange={(value) => {
                                                                                const reduced =
                                                                                    value.reduce(
                                                                                        (
                                                                                            acc,
                                                                                            curr
                                                                                        ) => [
                                                                                            ...acc,
                                                                                            curr.value,
                                                                                        ],
                                                                                        []
                                                                                    );

                                                                                form.setFieldValue(
                                                                                    `educationInfo.${index}.minors`,
                                                                                    reduced
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Form.Row>
                                                            </Card.Body>
                                                        </Collapse>
                                                    </div>
                                                </Fragment>
                                            )
                                        )}
                                    </>
                                )}

                                <Card.Footer className={styles.educationFooter}>
                                    <Button
                                        variant="outline-primary"
                                        disabled={isSubmitting}
                                        className={styles.footerBtn}
                                        onClick={() => {
                                            saveFormToLocal();
                                            push(emptyEductionFormValues);
                                            setCollapse(collapse + 1);
                                        }}
                                    >
                                        Add Education
                                    </Button>
                                    <Button
                                        variant="primary"
                                        className={styles.footerBtn}
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Done
                                    </Button>
                                </Card.Footer>
                            </>
                        )}
                    />
                </Form>
            </Background>
            <div className={styles.skipBtnContainer}>
                <Button
                    variant="link"
                    disabled={isSubmitting}
                    onClick={handleSkip}
                    className={styles.skipBtn}
                >
                    Skip
                </Button>
            </div>
        </>
    );
};
