import {
    SAVE_ID_ERROR_MESSAGES,
    CLEAR_ID_ERROR_MESSAGES,
    SET_VERIFICATION_CONFIGURATION,
    CLEAR_VERIFICATION_CONFIGURATION,
    START_SESSION_TIMER,
    RESET_SESSION_TIMER,
} from 'actions';

// actions to store ID error message when its expired or not valid to use in error screens
export const saveIdErrorMessages = (data) => {
    return {
        type: SAVE_ID_ERROR_MESSAGES,
        data,
    };
};

export const clearIdErrorMessages = () => {
    return {
        type: CLEAR_ID_ERROR_MESSAGES,
    };
};

// actions for saving verification methods like, AND, OR, SINGLE method
export const setVerificationConfiguration = (data) => {
    return {
        type: SET_VERIFICATION_CONFIGURATION,
        data,
    };
};

export const clearVerificationConfiguration = () => {
    return {
        type: CLEAR_VERIFICATION_CONFIGURATION,
    };
};

// actions for ID session timeout

export const startSessionTimer = (data) => {
    return {
        type: START_SESSION_TIMER,
        data,
    };
};

export const resetSessionTimer = () => {
    return {
        type: RESET_SESSION_TIMER,
    };
};
