import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import AsyncSelect from 'react-select/async';
import { getIn } from 'formik';
import styles from './AsyncSelect.module.scss';

export default class CreatableAsyncAll extends Component {
    state = {
        loadingMsg: '',
        noOptionsMessage: 'Please start typing to get results',
        eduLen: null,
        plLen: null,
    };

    onBlur = () => {
        this.props.form.setFieldTouched(this.props.field.name, true);
    };

    promiseOptions = async (inputValue) => {
        if (inputValue.length >= 3) {
            this.setState({ loadingMsg: 'Loading ...', eduLen: inputValue.length });
            if (inputValue.length > 30) {
                this.setState({
                    noOptionsMessage: 'No options',
                    eduLen: inputValue.length,
                });
                return;
            } else if (inputValue.length < 3) {
                this.setState({
                    noOptionsMessage: 'Please type atleast 3 characters to search',
                    eduLen: inputValue.length,
                });
            }
            const result = await this.props.asyncMethod(inputValue);
            if (!result || !result.length) {
                this.setState({
                    noOptionsMessage: 'No Options',
                    eduLen: inputValue.length,
                });
            }
            return result;
        }
        this.setState({
            noOptionsMessage: 'Please type atleast 3 characters to search',
            eduLen: inputValue.length,
        });
    };

    promiseOptions2 = async (inputValue) => {
        const value = this.props.field.name.split('.').slice(1, 2).join('.');

        const state = this.props.form.values.licenseInfo[value].state;

        if (inputValue.length >= 1) {
            this.setState({ loadingMsg: 'Loading ...', plLen: inputValue.length });
            if (inputValue.length > 30) {
                this.setState({
                    noOptionsMessage: 'No Options',
                    plLen: inputValue.length,
                });
                return;
            }
            const result = await this.props.asyncMethod(inputValue, state);
            if (!result || !result.length) {
                this.setState({
                    noOptionsMessage: 'No Options',
                    plLen: inputValue.length,
                });
            }
            return result;
        }
        this.setState({
            plLen: inputValue.length,
            noOptionsMessage: 'Please type atleast 1 character to search',
        });
    };

    onChange = (value) => {
        this.props.form.setFieldValue(this.props.field.name, value?.value ?? null);
    };

    render() {
        const { form, label, orgState } = this.props;
        const { errors } = form;

        return (
            <>
                {label ? (
                    <div htmlFor="string" className="pr-2">
                        {label}
                    </div>
                ) : null}
                <AsyncSelect
                    {...this.props.field}
                    {...this.props}
                    value={
                        this.props.field.value
                            ? { label: this.props.field.value, value: this.props.field.value }
                            : null
                    }
                    onBlur={this.onBlur}
                    isClearable
                    className={styles.selectField}
                    styles={{ paddingLeft: '10px' }}
                    onChange={this.onChange}
                    isMulti={this.props.isMulti}
                    loadOptions={orgState ? this.promiseOptions2 : this.promiseOptions}
                    defaultOptions={this.props.defaultValue}
                    noOptionsMessage={() => this.state.noOptionsMessage}
                    isValidNewOption={() =>
                        this.state.eduLen
                            ? this.state.eduLen < 3
                                ? false
                                : true
                            : this.state.plLen < 1
                            ? false
                            : true
                    }
                />
                {getIn(errors, this.props.field.name) && (
                    <ErrorMessage
                        component="div"
                        name={this.props.field.name}
                        className={styles.inputFeedback}
                    />
                )}
            </>
        );
    }
}
