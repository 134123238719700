import React from 'react';
import PropTypes from 'prop-types';
import RenderVerificationQue from './RenderVerificationQue';
import KbaErrorMsg from './KbaErrorMsg';

function KbaQuestions({ idmkbaResponse, isTimeOut, error, handleShow }) {
    const isQuestionsAvailable = () =>
        idmkbaResponse?.kbaQuestion?.length > 0 ? (
            <RenderVerificationQue idmkbaResponse={idmkbaResponse} />
        ) : (
            <KbaErrorMsg errMsg="Verify Quiz is not available for you right now. Please try any other verification method." />
        );

    const isKbaTimedOut = () =>
        isTimeOut ? (
            <KbaErrorMsg errMsg="The time limit for this verification has been exceeded. Please try again!" />
        ) : (
            isQuestionsAvailable()
        );

    return error ? (
        <KbaErrorMsg
            errMsg="We need you to confirm your personal information before we can generate a quiz."
            cta={true}
            handleShow={handleShow}
        />
    ) : (
        isKbaTimedOut()
    );
}

KbaQuestions.propTypes = {
    handleShow: PropTypes.func,
    error: PropTypes.any,
    isTimeOut: PropTypes.bool,
    idmkbaResponse: PropTypes.object,
};

export default KbaQuestions;
