import { getFinicityResultAPI } from 'api/bank';
import { createPollingActions, getPollingState } from 'redux-polling';

export const getFincityResult = (userAccessCode) => (dispatch) => {
    return getFinicityResultAPI(userAccessCode).then(() => {
        dispatch(actions.reset());
        dispatch(actions.start(userAccessCode));
    });
};

// Actions

const pollingInterval = 5000;
const historyLength = 1;

export const actions = createPollingActions(
    'finicity-result',
    (userAccessCode) => getFinicityResultAPI(userAccessCode),
    pollingInterval,
    historyLength
);

// Selectors

const isPollingActive = (state) => getPollingState(state, 'finicity-result').isActive;
const getPointHistory = (state) => getPollingState(state, 'finicity-result').history;
const getLastPoint = (state) => getPollingState(state, 'finicity-result').lastEntry;
const getCount = (state) => getPollingState(state, 'finicity-result').count;

export const selectors = {
    isPollingActive,
    getPointHistory,
    getLastPoint,
    getCount,
};
