import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reduxPollingMiddleware } from 'redux-polling';
import { reduxPollingNamespace, reduxPollingReducer } from 'redux-polling';

// ...

export const history = createBrowserHistory({
    basename: process.env.REACT_APP_BASENAME,
});

const config = {
    key: 'authenticating',
    storage: storageSession,
    blacklist: [
        'router',
        'REDUX_POLLING',
        'config',
        'processedData',
        'idProperties',
        'captureProperties',
        'images',
        'identity',
    ],
};

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        [reduxPollingNamespace]: reduxPollingReducer,
        ...rootReducer,
    });

// const loggerMiddleWare = (store) => (next) => (action) => {
//   console.log('[LOG] Action triggered', action)
//   next(action)
// }

export default function configureStore() {
    let store = createStore(
        persistReducer(config, createRootReducer(history)),
        composeWithDevTools(
            applyMiddleware(
                thunk,
                // loggerMiddleWare,
                routerMiddleware(history),
                reduxPollingMiddleware
            )
        )
    );
    let persistor = persistStore(store);
    window.store = store;
    return { persistor, store };
}
