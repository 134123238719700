import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import styles from './screens.module.scss';

import cn from 'classnames';

export default class Processing extends Component {
    render() {
        return (
            <div className={styles.processsingWrapper}>
                <Card.Header
                    as="h5"
                    className={cn(styles.scanCardHeader, styles.processingCardHeader)}
                >
                    Analyzing
                </Card.Header>
                <Card.Body className={styles.processingCardBody}>
                    <div className="analyzing_container">
                        <figure className="analyzing_animation_zone">
                            <img
                                width="100%"
                                alt="idscango"
                                src={
                                    this.props.orientation
                                        ? require('../assets/images/card_back@2x.png')
                                        : require('../assets/images/id_front@2x.png')
                                }
                            />
                        </figure>
                    </div>
                </Card.Body>
            </div>
        );
    }
}
