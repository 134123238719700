import axios from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { loggerConfig } from 'config';

const http = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        Accept: 'application/json',
    },
    proxy: {
        port: 4444,
    },
    maxContentLength: 1024 * 1024 * 20,
});

http.interceptors.request.use(function (config) {
    if (window.store) {
        const state = window.store.getState();
        const token = state && state.session.token;
        const jwt = state && state.profile.company && state.profile.company.jwt;

        config.headers.Authorization = token ? `Bearer ${jwt || token}` : '';
    }
    return config;
});

http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (loggerConfig.LOGGING_ENABLED) {
            datadogRum.addError(error);
        }

        return Promise.reject(error);
    }
);

export default http;
