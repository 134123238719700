import {
    REQUEST_PROFILE,
    UPDATE_PROFILE,
    UPDATE_TOKEN,
    UPDATE_PROFILE_ERROR,
    RESET_ALL_REDUCERS,
} from 'actions';
import { toCamelCase } from 'utils/utilities';
import { loggerConfig } from 'config';
import { datadogRum } from '@datadog/browser-rum';
import { hotjar } from 'react-hotjar';

export default (state = {}, action) => {
    return {
        ...root(state, action),
        // tries: tries(state.tries, action),
    };
};

const root = (state = { loading: false }, action) => {
    switch (action.type) {
        case UPDATE_TOKEN:
            return { loading: false };
        case REQUEST_PROFILE:
            return { ...state, loading: true };
        case UPDATE_PROFILE:
            const { integrationSettings, ...rest } = action?.data ? action.data : {};
            const payload = { integrationSettings, ...toCamelCase(rest) };
            const defaultRoute = ['/', '/verification', '/thank-you'];

            if (payload?.company?.user?.extendedInputs) {
                if (payload.company.user.extendedInputs.education) {
                    defaultRoute.splice(defaultRoute.length - 1, 0, '/education');

                    if (payload.company.user.extendedInputs.employment) {
                        defaultRoute.splice(defaultRoute.length - 1, 0, '/employment');
                    }
                }
                if (payload.company.user.extendedInputs.professionalLicense) {
                    defaultRoute.splice(defaultRoute.length - 1, 0, '/professional-license');
                }
            }

            if (
                loggerConfig.LOGGING_ENABLED &&
                !state?.company?.user &&
                payload?.company?.user?.userDetails
            ) {
                try {
                    const { uuid, firstName } = payload.company.user.userDetails;
                    datadogRum.setUser({
                        id: uuid,
                        name: firstName,
                        companyUUID: payload.company.uuid,
                    });
                } catch (error) {
                    datadogRum.addError(error);
                }
            }

            if (process.env.REACT_APP_HJID && payload?.company?.user?.userDetails) {
                try {
                    const { uuid, firstName } = payload.company.user.userDetails;
                    hotjar.identify(uuid, { firstName, companyUUID: payload.company.uuid });
                } catch (error) {
                    datadogRum.addError(error);
                }
            }

            return {
                ...payload,
                loading: false,
                defaultRoute,
            };
        case UPDATE_PROFILE_ERROR:
            return {
                error: action.data?.errorMessage,
                loading: false,
            };
        case RESET_ALL_REDUCERS:
            return {
                loading: false,
            };
        default:
            return state;
    }
};
