import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import { ErrorMessage } from 'formik';
import { getIn } from 'formik';
import styles from './AsyncSelect.module.scss';

const components = {
    DropdownIndicator: null,
};

const colourStyles = {
    multiValueLabel: (styles) => ({
        ...styles,
    }),
    multiValueRemove: (styles) => ({
        ...styles,

        margin: '2px',
        ':hover': {
            backgroundColor: 'transparent',
            color: 'black',
        },
    }),
};

export default class CreatableMulti extends Component {
    onChange = (newValue) => {
        if (this.props.onChange) {
            this.props.onChange(newValue);
            return;
        }

        this.props.form.setFieldValue(this.props.field.name, newValue);
    };

    onBlur = () => {
        this.props.form.setFieldTouched(this.props.field.name, true);
    };

    render() {
        const { form, label } = this.props;
        const { touched, errors } = form;

        const errorName = this.props.errorName ? this.props.errorName : this.props.field.name;

        return (
            <>
                {label ? (
                    <label htmlFor="string" className="create-select">
                        {label}
                    </label>
                ) : null}
                <CreatableSelect
                    {...this.props.field}
                    {...this.props}
                    // value={
                    //     this.props.field.value && typeof this.props.field.value === 'string'
                    //         ? this.props.field.value
                    //               .split(',')
                    //               .map((item) => ({ label: item, value: item }))
                    //         : this.props.field.value
                    // }
                    className="selectField"
                    components={components}
                    isMulti={this.props.isMulti}
                    onChange={this.onChange}
                    noOptionsMessage={() => null}
                    onBlur={this.onBlur}
                    styles={colourStyles}
                />
                {Boolean(getIn(errors, this.props.field.name)) &&
                    Boolean(getIn(touched, this.props.field.name)) && (
                        <ErrorMessage
                            component="div"
                            name={this.props.field.name}
                            className={styles.inputFeedback}
                        >
                            {(msg) => (
                                <div className={styles.inputFeedback}>
                                    *At least 1 {errorName} is required
                                </div>
                            )}
                        </ErrorMessage>
                    )}
            </>
        );
    }
}
