import {
    getProfileAPI,
    setConsentAPI,
    getResultAPI,
    webhookAPI,
    updateProfileAPI,
    getDegreeAPI,
    getSchoolAPI,
    getTitleAPI,
    employmentVerificationAPI,
    educationVerificationAPI,
    professionalLicenseVerificationAPI,
} from '../api/profile';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'utils/utilities';
import {
    UPDATE_PROFILE,
    REQUEST_PROFILE,
    REQUEST_RESULT,
    UPDATE_RESULT,
    UPDATE_PROFILE_ERROR,
} from '.';
import apiDispatcher from './apiDispatcher';
import { MEDALLION_LINK_ERR_MSG } from 'utils/constants';

export const updateProfile = (data) => {
    return {
        type: UPDATE_PROFILE,
        data,
    };
};

export const updateResult = (data) => {
    return {
        type: UPDATE_RESULT,
        data,
    };
};

export const getProfile = () => async (dispatch) => {
    const result = await apiDispatcher({
        axiosMethod: getProfileAPI,
        action: {
            inProgressType: REQUEST_PROFILE,
            successType: UPDATE_PROFILE,
            failureType: UPDATE_PROFILE_ERROR,
        },
    })(dispatch);

    if (result.isErr()) {
        if (getErrorMessage(result.error) === MEDALLION_LINK_ERR_MSG.EXPIRED) {
            window.location.href = '/session-expired?session=expired';
        } else {
            window.location.href = '/session-expired?session=invalid';
        }
    }
};

export const setConsent = (userAccessCode, name) => () => {
    return setConsentAPI(userAccessCode, name);
};

export const getTestResult = (userAccessCode) => (dispatch) => {
    dispatch({ type: REQUEST_RESULT });
    return getResultAPI(userAccessCode)
        .then((response) => {
            dispatch(updateResult(response.data));
            return response.data;
        })
        .catch((error) => {
            dispatch(updateResult({ error: getErrorMessage(error) }));
            toast.error(getErrorMessage(error));
        });
};

export const webhook = (status, method) => () => {
    return webhookAPI(status, method);
};

export const updateUserProfile = (data) => () => {
    return updateProfileAPI(data);
};

export const getDegree = (userAccessCode, value) => () => {
    return getDegreeAPI(userAccessCode, value);
};

export const getSchool = (userAccessCode, value) => () => {
    return getSchoolAPI(userAccessCode, value);
};

export const getTitle = (userAccessCode, state, value) => () => {
    return getTitleAPI(userAccessCode, state, value);
};
export const employmentVerification = (userAccessCode, data) => () => {
    return employmentVerificationAPI(userAccessCode, data);
};

export const educationVerification = (userAccessCode, data) => () => {
    return educationVerificationAPI(userAccessCode, data);
};

export const professionalLicenseVerification = (userAccessCode, data) => () => {
    return professionalLicenseVerificationAPI(userAccessCode, data);
};
