import * as Yup from 'yup';

function equalTo(ref, msg) {
    return Yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg || 'Path must be the same as Reference',
        params: {
            reference: ref.path,
        },
        test: function (value) {
            return value === this.resolve(ref);
        },
    });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export default Yup.object().shape({
    employers: Yup.array().of(
        Yup.object().shape({
            companyName: Yup.string().min(3, 'Too Short').max(70, 'Too Long').required('Required'),
            houseNumber: Yup.string().required('Required'),
            street: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            zipCode: Yup.number().required('Required').typeError('Only numbers'),
            employerPhoneNumber: Yup.string().required('Required'),
            start: Yup.string().required('Required'),
            isCurrent: Yup.boolean(),
            end: Yup.string().when('isCurrent', {
                is: false,
                then: Yup.string().required('Required'),
            }),
            jobTitle: Yup.string().required('Required'),
            jobType: Yup.string().required('Required'),
            reason: Yup.string().required('Required'),
            contactName: Yup.string().required('Required'),
            contactEmail: Yup.string().email('Invalid Email').required('Required'),
            contactNumber: Yup.string().required('Required'),
        })
    ),
});
