import CorrectSelfie1 from '../../images/correct-selfie-1.svg';
import CorrectSelfie2 from '../../images/correct-selfie-2.svg';
import InCorrectSelfie1 from '../../images/wrong-selfie-1.svg';
import InCorrectSelfie2 from '../../images/wrong-selfie-2.svg';
import InCorrectSelfie3 from '../../images/wrong-selfie-3.svg';
import InCorrectSelfie4 from '../../images/wrong-selfie-4.svg';
import InCorrectSelfie5 from '../../images/wrong-selfie-5.svg';
export const data = [
    {
        id: 0,
        img1: CorrectSelfie1,
        img2: InCorrectSelfie1,
        title: 'Avoid Black Light',
        description: 'Ensure that there is no black light present while capturing your face image.',
    },
    {
        id: 1,
        img1: CorrectSelfie1,
        img2: InCorrectSelfie2,
        title: 'Remove Accessories',
        description:
            'Please refrain from wearing hats, glasses, face masks, or headphones during the capture process.',
    },
    {
        id: 2,
        img1: CorrectSelfie1,
        img2: InCorrectSelfie3,
        title: 'Ensure Proper Lighting',
        description: 'Make sure your face is fully lit to ensure optimal image quality.',
    },
    {
        id: 3,
        img1: CorrectSelfie2,
        img2: InCorrectSelfie4,
        title: 'Background Consideration',
        description:
            'Avoid using a plain white background for the capture to ensure accurate results.',
    },
    {
        id: 4,
        img1: CorrectSelfie1,
        img2: InCorrectSelfie5,
        title: 'Frame Fit',
        description: 'Please ensure your face fits in the outline shown in the screen.',
    },
];
