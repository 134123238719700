import { connect } from 'react-redux';
import { withFormik } from 'formik';
import validationSchema from './UserDetails.validation';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { getErrorMessage, ignoreFalsyValues } from 'utils/utilities';
import { updateUserProfile } from 'actions/profile';
import { sessionUserProfileSaved } from 'actions/session';
import { formValuesToAPI } from './UserDetails.mapper';
import { mergeWith } from 'lodash';
import moment from 'moment';

const initialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    dob: null,
    houseNumber: '',
    streetName: '',
    city: '',
    state: '',
    zipCode: '',
    ssn: '',
    confirmSSN: '',
    acceptMiddleName: false,
    country: 'United States of America',
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserProfile: (data) => dispatch(updateUserProfile(data)),
        sessionUserProfileSaved: () => dispatch(sessionUserProfileSaved()),
    };
};

const mapStateToProps = (state) => {
    return {
        // used to let the backend know that the user confirmed the information
        // for that jwt link. Can be removed once workflows are used.
        jwtToken: state.profile?.company?.jwt,
        customVerificationOptions: state.profile.company?.userInformationStatus
            ? { userInformationStatus: state.profile.company.userInformationStatus }
            : null,
        user:
            state.profile.company &&
            state.profile.company.user &&
            state.profile.company.user.userDetails,
        source: state.profile?.source,
        // reduxToFormValues(state.profile.company.user.userDetails),
        // employee: state.employee
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: (props) => {
            const user = {
                ...props.user,
                dob: props.user?.dateOfBirth ? moment(props.user.dateOfBirth, 'YYYY-MM-DD') : null,
            };
            const emptyFields = {
                ...user,
                state: '',
                firstName: '',
                middleName: '',
                lastName: '',
                houseNumber: '',
                streetName: '',
                city: '',
                zipCode: '',
                dob: null,
            };

            const values = mergeWith(
                {},
                initialValues,
                props.source === 'api' ? emptyFields : user,
                (a, b, key) => (b === null ? a : b)
            );
            if (props.customVerificationOptions) {
                values.token = props.jwtToken;
                values.customVerificationOptions = props.customVerificationOptions;
            }

            return values;
        },
        enableReinitialize: true,
        validationSchema: (props) => validationSchema(props),
        handleSubmit: (values, { setStatus, setSubmitting, props }) => {
            if (values.customVerificationOptions) {
                values.customVerificationOptions = {
                    ...values.customVerificationOptions,
                    userInformationStatus: 'confirmedViaUser',
                };
            }

            const submitValues = props.source === 'api' ? values : ignoreFalsyValues(values);

            props
                .updateUserProfile(formValuesToAPI(submitValues))
                .then((result) => {
                    props.sessionUserProfileSaved();
                    setSubmitting(false);
                    setStatus({ status: 'COMPLETED' });
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error));
                    setSubmitting(false);
                });
        },
        displayName: 'UserInfoForm',
    })
);
