import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'utils/utilities';
import { updateUserProfile, employmentVerification, getProfile } from 'actions/profile';
import { mergeWith } from 'lodash';
import validationSchema from './EmploymentInformation.validation';
import { formValuesToAPI } from './EmploymentInformation.mapper';
import { emptyEmployerFormValues } from 'utils/constants';

const initialValues = () => {
    const localForm = sessionStorage.getItem('employerForm');
    const localFormValues = JSON.parse(localForm);

    return { employers: localFormValues?.length ? localFormValues : [emptyEmployerFormValues] };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserProfile: (data) => dispatch(updateUserProfile(data)),
        employmentVerification: (userAccessCode, data) =>
            dispatch(employmentVerification(userAccessCode, data)),
        getProfile: () => dispatch(getProfile()),
    };
};

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        session: state.session,
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormik({
        mapPropsToValues: (props) =>
            mergeWith({}, initialValues(), props.user, (a, b) => (b === null ? a : b)),
        enableReinitialize: true,
        validationSchema,
        handleSubmit: (values, { setStatus, setSubmitting, props }) => {
            setStatus(true);
            props
                .employmentVerification(
                    props.profile.company.user.userDetails.uuid,
                    formValuesToAPI(values)
                )
                .then((result) => {
                    setSubmitting(false);
                    setStatus(false);
                    sessionStorage.removeItem('employerForm');
                    props.getProfile();
                    props.renderNextStep();
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error));
                    setStatus(false);
                    setSubmitting(false);
                });
        },
        displayName: 'employmentVerification',
    })
);
