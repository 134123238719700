/* eslint-disable react-hooks/exhaustive-deps */
import styles from './UnsupportedBrowser.module.scss'
import React, { useEffect } from 'react'
import Image from 'core-components/Image'
import Background from 'core-components/Background'
import Card from 'react-bootstrap/Card'
import _ from 'lodash'
import Loader from "core-components/Loader";

import Navbar from 'core-components/Navbar'

export default (props) => {
    useEffect(() => {
        if (_.isEmpty(props.session)) {
            props.updateProfile({
                company: {
                    name: 'Authenticating.com',
                }
            })
        } else {
            props.session && props.session.token && !props.profile.loading && props.getProfile();
        }
    }, [])
    return (
        <>
            <Navbar />
            <Background className={styles.notFoundWrapper}>
                {
                    props.profile.loading ?
                        <div className={styles.loaderWrapper}>
                            <Loader />
                        </div>
                        :
                        <>
                            <Card.Body className={styles.body}>
                                <Image
                                    className={styles.image}
                                    name={props.image}
                                />
                                <br />
                                <h5>
                                    {props.heading}
                                </h5>
                            </Card.Body>
                            {props.children}
                        </>

                }

            </Background>
        </>
    )
}