import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styles from './Loader.module.scss';
import cn from 'classnames'

export default props => {
  return (
    <div className={cn(styles.loader, props.className)}>
      <Spinner animation="border" {...props} />
    </div>
  );
};
