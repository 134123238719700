import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { datadogRum } from '@datadog/browser-rum';
import { loggerConfig } from 'config';
import { eventNames, INSTRUCTION_TYPES, VERIFICATION_FLOWS } from 'utils/constants';

import { TRIES_THRESHOLD, customSelfieErr, faceDetectionStates } from 'utils/constants/selfie';
import {
    getLivenessForCapturedImage,
    getLivenessToken,
    startFaceDetectionAndLiveness,
} from 'utils/acuantUtils';
import useNormalFlow from './useNormalFlow';
import useReauthenticateFlow from './useReauthenticateFlow';
import { trackEvent } from 'api/productAnalytics';

const useCaptureSelfie = (props) => {
    const {
        resultData,
        tries,
        dispatch,
        history,
        uac,
        livenessCheckEnabled,
        instanceID,
        processID,
        processedData,
        reAuthenticateSessionId,
        reauthenticateSelfieAttempts,
        sessionStatus,
        isDeviceInfoSent,
        verificationFlow,
        workflow,
        increaseTries,
        startSessionTimer,
        updateReauthenticateStatus,
        getDeviceInfoAction,
        setShowInstructions,
        liveness,
        lastUsedInstruction,
    } = props;

    const [loading, setLoading] = useState(false);
    const [selfie, setSelfie] = useState(null);

    const canRetry = (tries) => {
        const medallionFlowAttempts = workflow?.livenessCheck.attempts || TRIES_THRESHOLD;
        const reauthenticateFlowAttempts = sessionStatus?.allowedAttempts;

        const selfieAttempts =
            verificationFlow === VERIFICATION_FLOWS.NORMAL
                ? medallionFlowAttempts
                : reauthenticateFlowAttempts;

        return isMobile && tries < selfieAttempts;
    };

    const handleError = () => {
        increaseTries();
        setLoading(false);
        dispatch(setShowInstructions(false));
    };

    const { initNormalFlow, processNormalFlowSelfie } = useNormalFlow({
        data: resultData,
        selfie,
        setLoading,
        canRetry,
        tries,
        handleError,
        dispatch,
        history,
        uac,
        livenessCheckEnabled,
        instanceID,
        processID,
        setShowInstructions,
        liveness,
        lastUsedInstruction,
    });

    const { initReauthenticateFlow, processReauthenticateSelfie } = useReauthenticateFlow({
        selfie,
        processedData,
        reAuthenticateSessionId,
        history,
        dispatch,
        reauthenticateSelfieAttempts,
        sessionStatus,
        isDeviceInfoSent,
        startSessionTimer,
        updateReauthenticateStatus,
        getDeviceInfoAction,
        setShowInstructions,
        setLoading,
    });

    useEffect(() => {
        if (verificationFlow === VERIFICATION_FLOWS.NORMAL) {
            initNormalFlow();
        } else if (verificationFlow === VERIFICATION_FLOWS.REAUTHENTICATE) {
            initReauthenticateFlow();
        }
    }, []);

    useEffect(() => {
        if (selfie) {
            processSelfieAndRedirect();
        }
    }, [resultData, selfie]);

    const handleLivenessResult = (result, image) => {
        if (!result && loggerConfig.LOGGING_ENABLED) {
            datadogRum.addAction('Acuant Selfie Result Empty', {
                result,
                userAccessCode: uac,
                noOfTries: tries + 1,
            });
        }

        const attemptsByUser =
            verificationFlow === VERIFICATION_FLOWS.NORMAL ? tries : reauthenticateSelfieAttempts;

        //note: adding 1 because initial state is 0
        const canRetrySelfie = canRetry(attemptsByUser + 1);

        if (!result?.LivenessResult && canRetrySelfie) {
            let errMsg =
                customSelfieErr[result?.ErrorCode] ?? result?.Error ?? 'Face liveness failed.';

            trackEvent({
                event: eventNames.captureSelfie,
                uac: uac,
                uniqueOnly: false,
                otherValues: {
                    'Capture Error': errMsg,
                    'Verification Flow': VERIFICATION_FLOWS.NORMAL,
                    'Selfie Instruction Used': lastUsedInstruction === INSTRUCTION_TYPES.SELFIE,
                    Attempts: tries + 1,
                },
            });

            dispatch({
                payload: errMsg,
                type: '@@acuant/UPDATE_SELFIE_ERROR_MSG',
            });
            handleError();
        } else {
            dispatch({
                payload: result,
                type: '@@acuant/ADD_FACE_LIVENESS_DATA',
            });
            if (verificationFlow === VERIFICATION_FLOWS.NORMAL) {
                setSelfie(image);
            }
        }

        //note: need to improve the overall logic in future
        if (verificationFlow === VERIFICATION_FLOWS.REAUTHENTICATE) {
            setSelfie(image);
        }
    };

    const onCaptured = (image) => {
        setLoading(true);
        const params = {
            endpoint: process.env.REACT_APP_LIVENESS_ENDPOINT,
            token: getLivenessToken(),
            image: image,
            subscriptionId: process.env.REACT_APP_SUBSCRIPTION_ID,
        };

        getLivenessForCapturedImage(params, (result) => handleLivenessResult(result, image));
    };

    const openFrontCamera = () => {
        startFaceDetectionAndLiveness(
            {
                onCaptured: onCaptured,
                onError: (error) => {
                    //Error occurred. Camera permission not granted will
                    //manifest here with 1 as error code. Unexpected errors will have 2 as error code.
                    if (loggerConfig.LOGGING_ENABLED) {
                        datadogRum.addError(error, {
                            acuantSdkError: true,
                            extra: { errorCode: error.code },
                        });
                    }
                },
                //note: onDetection is a required function
                onDetection: (text) => {},
                //required
                onClosed: () => {},
            },
            faceDetectionStates
        );
    };

    const processSelfieAndRedirect = () => {
        if (verificationFlow === VERIFICATION_FLOWS.NORMAL) {
            processNormalFlowSelfie();
        } else if (verificationFlow === VERIFICATION_FLOWS.REAUTHENTICATE) {
            processReauthenticateSelfie();
        }
    };

    return {
        loading,
        openFrontCamera,
    };
};

export default useCaptureSelfie;
