import Alert from 'core-components/Alert';
import Button from 'core-components/Button';
import React from 'react';

const ContentSection = () => (
    <span style={{ fontFamily: 'inherit' }}>
        {'The app did not initialize properly, please refresh the app.'}
    </span>
);
const ActionSection = () => <Button onClick={() => window.location.reload()}>Refresh</Button>;

export default ({ onClose = () => {}, open = true }) => {
    return (
        <Alert
            title="Engine Initialization Failed"
            open={open}
            onClose={onClose}
            ContentSection={ContentSection}
            ActionSection={ActionSection}
        />
    );
};
