/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './Lander.module.scss';
import Image from 'core-components/Image';
import cn from 'classnames';

export default () => {
    return (
        <Container style={{ padding: '0' }}>
            <Row className={styles.row}>
                <Col xs={1} className={cn(styles.content, styles.mobileContent)}>
                    <Image name="Lock.svg" />
                </Col>
                <Col xs={11} className={styles.content}>
                    We use machine learning, computer vision and AI for a forensic identity
                    authentication.
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={1} className={cn(styles.content, styles.mobileContent)}>
                    <Image name="Shield.svg" />
                </Col>
                <Col xs={11} className={styles.content}>
                    Your information will only be used by{' '}
                    <a href={'https://authenticating.com/'} target="_blank" className={styles.link}>
                        Authenticating.com
                    </a>{' '}
                    for verification purposes.
                </Col>
            </Row>
            <Row className={styles.row}>
                <Col xs={1} className={cn(styles.content, styles.mobileContent)}>
                    <Image name="Clock.svg" />
                </Col>
                <Col xs={11} className={styles.content}>
                    This process takes only a couple of minutes.
                </Col>
            </Row>
        </Container>
    );
};
