import React from 'react';
import Button from 'react-bootstrap/Button';

export default (props) => {
    return (
        <Button {...props} variant={props.variant} disabled={props.disabled}>
            {props.children}
        </Button>
    );
};
