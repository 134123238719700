import { connect } from 'react-redux';
import { webhook, getProfile } from 'actions/profile';

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        session: state.session,
        identity: state.identity,
        kba: state.identity.kba,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        webhook: (status, method) => dispatch(webhook(status, method)),
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps);
