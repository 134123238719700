import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

// components
import Dashboard from 'components/Home/Dashboard';
import CaptureSelfie from 'screens/CaptureSelfie';
import {
    REAUTHENTICATE_STATUS,
    VERIFICATION_FLOWS,
    REAUTHENTICATE_ROUTES,
    REAUTHENTICATE_ROUTE,
} from 'utils/constants';
import { getReauthenticateAdditionalMethodData } from 'utils/utilities';
import { Helmet } from 'react-helmet';
import InitializationFailedAlert from 'components/Mobile/InitializationFailedAlert';
import AcuantSdkLoader from 'screens/AcuantSdkLoader';
import SelfieFailed from './SelfieFailed';
import VerifyEmailPhone from './VerifyEmailPhone';
import { isMobile } from 'react-device-detect';
import NotFound from 'components/NotFound';
import { Card } from 'react-bootstrap';
import Button from 'core-components/Button';
import DeviceLinks from 'components/Home/Verification/DeviceLinks';
import ReAuthenticationFailed from './ReAuthenticationFailed';
import ReAuthenticationSuccess from './ReAuthenticationSuccess';
import Background from 'core-components/Background';
import styles from './styles/reauthenticateScreens.module.css';
import IdSessionTimeOut from 'screens/IdSessionTimeOut';
import { loadAcuantSDKScript } from 'utils/acuantUtils';

function Reauthenticate({ match, reAuthenticateStatus, history, ...props }) {
    const [acuantProperties, setAcuantProperties] = useState({
        isAcuantSdkLoaded: false,
        initializeFailed: false,
        isInitialized: false,
        isIntializing: false,
    });

    const changeAcuantProperties = useCallback(
        (values) => {
            setAcuantProperties({
                ...acuantProperties,
                ...values,
            });
        },
        [acuantProperties]
    );

    const pathName = history.location.pathname;

    const loadAcuantSdk = useMemo(() => {
        return pathName === `${match.path}/capture/selfie` && !acuantProperties.isAcuantSdkLoaded;
    }, [pathName, match, acuantProperties]);

    const isSelfieFailed = useMemo(() => {
        const selfieVerification = reAuthenticateStatus.selfieVerification;

        const allowedAttempts = reAuthenticateStatus?.allowedAttempts;

        const attemptExceeded = selfieVerification?.attempts === allowedAttempts;

        const verificationFailed = selfieVerification?.status === REAUTHENTICATE_STATUS.FAILED;

        return attemptExceeded && verificationFailed;
    }, [reAuthenticateStatus]);

    const isVerifyCodePending = useMemo(() => {
        const selfieVerification = reAuthenticateStatus.selfieVerification;

        return selfieVerification?.status === REAUTHENTICATE_STATUS.COMPLETED; //check addition check code is pending;
    }, [reAuthenticateStatus]);

    const isVerifyCodeFailed = useMemo(() => {
        const additionalVerification = getReauthenticateAdditionalMethodData(
            reAuthenticateStatus.additionalVerification
        );

        const allowedAttempts = reAuthenticateStatus?.allowedAttempts;

        const attemptExceeded = additionalVerification?.attempts === allowedAttempts;

        const verificationFailed = additionalVerification?.status === REAUTHENTICATE_STATUS.FAILED;

        return attemptExceeded && verificationFailed;
    }, [reAuthenticateStatus]);

    const userReauthenticated = useMemo(() => {
        const additionalVerification = getReauthenticateAdditionalMethodData(
            reAuthenticateStatus.additionalVerification
        );

        const selfieVerified =
            reAuthenticateStatus.selfieVerification?.status === REAUTHENTICATE_STATUS.COMPLETED;

        const codeVerified = additionalVerification?.status === REAUTHENTICATE_STATUS.COMPLETED;

        return selfieVerified && codeVerified;
    }, [reAuthenticateStatus]);

    useEffect(() => {
        if (loadAcuantSdk) {
            loadAcuantSDKScript({
                ...acuantProperties,
                changeState: changeAcuantProperties,
            });
        }
    }, [changeAcuantProperties, loadAcuantSdk, acuantProperties]);

    useEffect(() => {
        if (isSelfieFailed) {
            history.push(REAUTHENTICATE_ROUTES.SELFIE_FAILURE);
        } else if (isVerifyCodeFailed) {
            history.push(REAUTHENTICATE_ROUTES.VERIFY_CODE_FAILURE);
        } else if (userReauthenticated) {
            history.push(REAUTHENTICATE_ROUTES.RESULT);
        } else if (isVerifyCodePending) {
            history.push(REAUTHENTICATE_ROUTES.VERIFY_CODE);
        }
    }, [isSelfieFailed, isVerifyCodePending, userReauthenticated, isVerifyCodeFailed, history]);

    if (loadAcuantSdk) {
        return <AcuantSdkLoader initializeFailed={acuantProperties.initializeFailed} />;
    }

    return (
        <Fragment>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <Switch>
                <Route
                    exact
                    path={`${match.path}`}
                    render={(props) => <Dashboard {...props} />}
                    {...props}
                />

                <Route
                    exact
                    path={`${match.path}/link`}
                    render={(props) => (
                        <DeviceLinks
                            {...props}
                            verificationFlow={VERIFICATION_FLOWS.REAUTHENTICATE}
                        />
                    )}
                    {...props}
                />

                {acuantProperties.isAcuantSdkLoaded && (
                    <Route
                        exact
                        path={`${match.path}/capture/selfie`}
                        render={(props) =>
                            isMobile ? (
                                <Background className={styles.containerWrapper}>
                                    <CaptureSelfie
                                        {...props}
                                        theme={reAuthenticateStatus?.theme}
                                        verificationFlow={VERIFICATION_FLOWS.REAUTHENTICATE}
                                    />
                                </Background>
                            ) : (
                                <NotFound heading="This link can only be opened on a mobile device.">
                                    <Card.Footer className="text-muted">
                                        <Button
                                            block
                                            variant="primary"
                                            onClick={() => {
                                                history.replace(`/${REAUTHENTICATE_ROUTE}`);
                                            }}
                                        >
                                            Go Back
                                        </Button>
                                    </Card.Footer>
                                </NotFound>
                            )
                        }
                        {...props}
                    />
                )}

                <Route
                    exact
                    path={`${match.path}/selfie/failure`}
                    render={(props) => (
                        <SelfieFailed {...props} reAuthenticateStatus={reAuthenticateStatus} />
                    )}
                    {...props}
                />
                <Route
                    exact
                    path={`${match.path}/verify/code`}
                    render={(props) => <VerifyEmailPhone {...props} />}
                    {...props}
                />

                <Route
                    exact
                    path={`${match.path}/code/failure`}
                    render={(props) => (
                        <ReAuthenticationFailed
                            {...props}
                            theme={reAuthenticateStatus?.theme}
                            reAuthenticateStatus={reAuthenticateStatus}
                        />
                    )}
                    {...props}
                />

                <Route
                    exact
                    path={`${match.path}/result`}
                    render={(props) => (
                        <ReAuthenticationSuccess
                            {...props}
                            theme={reAuthenticateStatus?.theme}
                            reAuthenticateStatus={reAuthenticateStatus}
                        />
                    )}
                    {...props}
                />

                <Route
                    exact
                    path={`${match.path}/selfie/timeout`}
                    render={(props) => (
                        <IdSessionTimeOut
                            {...props}
                            theme={reAuthenticateStatus?.theme}
                            verificationFlow={VERIFICATION_FLOWS.REAUTHENTICATE}
                        />
                    )}
                    {...props}
                />

                <Route path="*" component={NotFound} />
            </Switch>
            <InitializationFailedAlert open={acuantProperties.initializeFailed} />
        </Fragment>
    );
}

Reauthenticate.propTypes = {
    match: PropTypes.object,
    reAuthenticateStatus: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default Reauthenticate;
