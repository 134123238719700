import http from './http';
import { toCamelCase } from 'utils/utilities';

export const getProfileAPI = () => {
    return http.get('/verification/self/verify');
};

export const getResultAPI = (userAccessCode) => {
    return http.post('/user/getTestResult', { userAccessCode: userAccessCode });
};

export const setConsentAPI = (userAccessCode, name) => {
    return http.post('/user/consent', {
        userAccessCode: userAccessCode,
        isBackgroundDisclosureAccepted: 1,
        GLBPurposeAndDPPAPurpose: 1,
        FCRAPurpose: 1,
        fullName: name,
    });
};

export const webhookAPI = (status, method) => {
    return http.post('/verification/self/webhook', {
        status: status,
        method: method,
    });
};

export const dump = (data) => {
    return http.post('/verification/self/result/complete', data);
};

export const saveResult = (data) => {
    return http.post('/verification/self/result', toCamelCase(data));
};

export const updateProfileAPI = (data) => {
    return http.put('/user/update', toCamelCase(data));
};

export const getDegreeAPI = (userAccessCode, value) => {
    return http.post('/education/degree/names', {
        userAccessCode,
        degree_name: value,
    });
};

export const getSchoolAPI = (userAccessCode, value) => {
    return http.post('/education/school/names', {
        userAccessCode,
        school_name: value,
    });
};

export const getTitleAPI = (userAccessCode, state, value) => {
    return http.post('/identity/professional/license/names', {
        userAccessCode,
        state_abbr: state,
        license_name: value,
    });
};
export const employmentVerificationAPI = (userAccessCode, data) => {
    return http.post('/employment/verify', {
        userAccessCode,
        employmentList: data,
    });
};

export const educationVerificationAPI = (userAccessCode, data) => {
    return http.post('/education/verify', {
        userAccessCode,
        educationList: data,
    });
};

export const professionalLicenseVerificationAPI = (userAccessCode, data) => {
    return http.post('/identity/professional/license', {
        userAccessCode,
        licenseList: data,
    });
};
