import React from 'react';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import Loader from 'core-components/Loader';
import Background from 'core-components/Background';
import InitializationFailedAlert from 'components/Mobile/InitializationFailedAlert';
import styles from './acuantReactCamera.module.scss';
import PropTypes from 'prop-types';

function AcuantSdkLoader({ initializeFailed }) {
    return (
        <>
            <Background wrapper={styles.wrapper}>
                <Helmet>
                    <meta
                        name="viewport"
                        content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no"
                    />
                </Helmet>
                <Card.Body className={styles.gettingReadyCard}>
                    <Loader />
                    <br />
                    <Card.Title>Getting our servers ready</Card.Title>
                </Card.Body>
            </Background>
            <InitializationFailedAlert open={initializeFailed} />
        </>
    );
}

AcuantSdkLoader.propTypes = {
    initializeFailed: PropTypes.bool.isRequired,
};

export default AcuantSdkLoader;
