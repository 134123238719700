import React from 'react';
import styles from './styles/reauthenticateScreens.module.css';
import Card from 'react-bootstrap/Card';
import Background from 'core-components/Background';
import Button from 'core-components/Button';
import { getReauthenticateAdditionalMethodData, setButtonVariant } from 'utils/utilities';
import PropTypes from 'prop-types';

function ReAuthenticationFailed({ reAuthenticateStatus, ...props }) {
    const handleRedirect = () => {
        window.location.href = reAuthenticateStatus.redirectUrl;
    };

    const additionalVerification = getReauthenticateAdditionalMethodData(
        reAuthenticateStatus.additionalVerification
    );

    const displayRedirectBtn = reAuthenticateStatus?.redirectUrl?.length;

    return (
        <Background className={styles.containerWrapper}>
            <Card.Header className={styles.cardHeader}>ReAuthentication Failed</Card.Header>
            <Card.Body className={styles.cardBody}>
                <p className={styles.title}>There was an issue with your ReAuthentication</p>
                <p className={styles.description}>
                    Unable to verify {additionalVerification.method}. All attempts used. Please
                    contact the administrator to proceed.
                </p>

                {displayRedirectBtn && (
                    <Button
                        block
                        variant={setButtonVariant(props.theme?.button.variant)}
                        className={styles.footerBtn}
                        onClick={handleRedirect}
                    >
                        Continue to {reAuthenticateStatus.companyName}
                    </Button>
                )}
            </Card.Body>
        </Background>
    );
}

ReAuthenticationFailed.propTypes = {
    reAuthenticateStatus: PropTypes.any.isRequired,
    theme: PropTypes.object.isRequired,
};

export default ReAuthenticationFailed;
