import {
    RESET_COMMUNICATION,
    REQUEST_COMMUNICATION,
    UPDATE_COMMUNICATION,
    RESET_ALL_REDUCERS,
} from 'actions';

export default (state = {}, action) => {
    return {
        ...root(state, action),
    };
};

const root = (state = { loading: false, success: false }, action) => {
    switch (action.type) {
        case RESET_ALL_REDUCERS: // does the same as RESET_COMMUNICATION
        case RESET_COMMUNICATION:
            return { loading: false, success: false };
        case REQUEST_COMMUNICATION:
            return { ...state, loading: true, success: false };
        case UPDATE_COMMUNICATION:
            return {
                ...state,
                ...action.data,
                loading: false,
                success: true,
                instant: Date.now(),
            };
        default:
            return state;
    }
};
