import React from 'react'
import styles from './Background.module.scss'
import Card from '../Card'
import Container from 'react-bootstrap/Container'
import cn from 'classnames'

const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export default (props) => {
  if (inIframe()) {
    return (
      <div className={styles.wrapperIframe}>
        <Card className={cn(styles.iframe, props.className)}>
          {props.children}
        </Card>
      </div>

    )
  }
  return (
    <div className={cn(styles.wrapper, props.wrapper)}>
      <Container className={styles.root}>
        <Card className={cn(props.className)}>
          {props.children}
        </Card>
      </Container>
    </div>
  )
}
