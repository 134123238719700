import { connect } from 'react-redux';
import { getProfile } from 'actions/profile';
import { setStatus } from 'actions/status';
import { resetKBA } from 'actions/identity';

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        session: state.session,
        identity: state.identity,
        kba: state.identity.kba,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
        setStatus: (status) => dispatch(setStatus(status)),
        resetKBA: () => dispatch(resetKBA()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps);
