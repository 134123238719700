import React from 'react';
import Card from 'react-bootstrap/Card';
import Background from 'core-components/Background';
import styles from './FailedScreens.module.scss';

function IDFailed({ profile }) {
    const changeDescription = profile?.company?.uuid === '27feebcb-295c-46f5-8e6b-6cc5af742b69';

    const description = changeDescription ? (
        <>
            Thank you for your interest in Constellation. <br /> <br />
            Unfortunately, we were not able to verify your identity. We apologize for the
            inconvenience, but we are unable to complete your enrollment.
        </>
    ) : (
        'Unable to verify identity using financial account. All attempts used. Please contact the administrator to proceed.'
    );

    return (
        <Background className={styles.kbaWrapper}>
            <Card.Header className={styles.cardHeader}>
                Financial account authentication failed
            </Card.Header>
            <Card.Body className={styles.cardBody}>
                <div className={styles.messageBox}>
                    <p className={styles.displayMessage}>
                        Could not match your information with the associated financial account.
                    </p>
                    <p className={styles.description}>{description}</p>
                </div>
            </Card.Body>
        </Background>
    );
}

export default IDFailed;
