import React from 'react';
import Form from 'react-bootstrap/Form';
import { ErrorMessage, getIn } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Input from '../Input';
import MaskedInput from 'react-text-mask';
import ErrorWithInfoIcon from '../Error/ErrorWithInfoIcon';
import PropTypes from 'prop-types';

const ValidatedDate = ({ field, form, label, labelClass, ...props }) => {
    const { touched, errors } = form;
    const ExampleCustomInput = React.forwardRef((props, ref) => {
        return (
            <MaskedInput
                mask={props.mask}
                className="form-control"
                {...props}
                render={(ref, props) => {
                    return (
                        <Input
                            ref={(input) => ref(input)}
                            {...props}
                            isInvalid={form?.touched[field.name] && !!form?.errors[field.name]}
                        />
                    );
                }}
                placeholder={props.example}
            />
        );
    });

    return (
        <Form.Group className={props.className}>
            {label && <Form.Label className={labelClass}>{label}</Form.Label>}
            <DatePicker
                {...props}
                showMonthDropdown
                strictParsing={true}
                showYearDropdown
                dropdownMode="select"
                selected={(field.value && new Date(field.value)) || null}
                onChange={(val) => {
                    form.setFieldValue(field.name, val);
                }}
                customInput={<ExampleCustomInput example={props.example} mask={props.mask} />}
            />
            {getIn(errors, field.name) && getIn(touched, field.name) && (
                <ErrorMessage name={field.name} component={ErrorWithInfoIcon} />
            )}
        </Form.Group>
    );
};

ValidatedDate.propTypes = {
    field: PropTypes.object,
    form: PropTypes.object,
    label: PropTypes.string,
    labelClass: PropTypes.string,
};

export default ValidatedDate;
