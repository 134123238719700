export default {
    GTM: {
        ID: process.env.REACT_APP_GTM_TAG,
        ENABLED: process.env.REACT_APP_GTM_ENABLED.trim() === 'true',
    },
    PRODUCT: {
        ENABLED: process.env.REACT_APP_PRODUCT_ANALYTICS_ENABLED,
        MIXPANEL: {
            TOKEN: process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN,
        },
    },
};
