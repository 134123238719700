import React from 'react';
import Form from 'react-bootstrap/Form';
import styles from './ValidatedRadioFields.module.scss';

export default ({ field, form, label, options, ...props }) => {
  return (
    <Form.Group controlId={field && field.name} {...props}>
      {label && <Form.Label className={styles.question}>{label}</Form.Label>}
      {options &&
        options.map((option, i) => {
          return (
            <Form.Check
              className={styles.option}
              custom
              onChange={e => form.setFieldValue(field.name, e.target.value)}
              value={option.value}
              key={i}
              type="radio"
              id={field.name + option.value}
              label={option.label}
              checked={String(option.value) === field.value}
            />
          );
        })}
    </Form.Group>
  );
};
