import { SET_DOCUMENT_ATTEMPT, RESET_DOCUMENT_ATTEMPT, RESET_ALL_REDUCERS } from 'actions';

const initialState = {
    documentAttempts: {
        count: 0,
        uuid: null,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_DOCUMENT_ATTEMPT:
            return {
                documentAttempts: action.data,
            };
        case RESET_DOCUMENT_ATTEMPT:
            return initialState;
        case RESET_ALL_REDUCERS:
            return initialState;
        default:
            return state;
    }
};
