import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles/reauthenticateScreens.module.css';
import Card from 'react-bootstrap/Card';
import Background from 'core-components/Background';
import Button from 'core-components/Button';
import { getReauthenticateAdditionalMethodData, setButtonVariant } from 'utils/utilities';

function ReAuthenticationSuccess({ theme, reAuthenticateStatus, ...props }) {
    const handleRedirect = () => {
        window.location.href = reAuthenticateStatus.redirectUrl;
    };

    const selfieVerification = reAuthenticateStatus?.selfieVerification;

    const additionalVerification = getReauthenticateAdditionalMethodData(
        reAuthenticateStatus?.additionalVerification
    );

    const reAuthenticateResult = [
        { id: 0, key: 'ReAuthentication Result', value: 'Complete' },
        { id: 1, key: 'Selfie match', value: 'Success' },
        { id: 2, key: 'Attempts', value: selfieVerification?.attempts },
        { id: 3, key: `${additionalVerification.method} match`, value: 'Success' },
        { id: 4, key: 'Attempts', value: additionalVerification.attempts },
    ];

    const displayRedirectBtn = reAuthenticateStatus?.redirectUrl?.length;

    return (
        <Background className={styles.containerWrapper}>
            <Card.Header className={styles.cardHeader}>
                Congratulations! You have successfully completed ReAuthenticate™
            </Card.Header>
            <Card.Body className={styles.cardBody}>
                <div className={styles.resultContainer}>
                    {reAuthenticateResult?.length
                        ? reAuthenticateResult.map((result) => (
                              <div className={styles.results} key={result.id}>
                                  <p>{result.key}</p>
                                  <div className={styles.resultBox}>{result.value}</div>
                              </div>
                          ))
                        : null}
                </div>

                {displayRedirectBtn && (
                    <Button
                        block
                        variant={setButtonVariant(theme?.button.variant)}
                        className={styles.footerBtn}
                        onClick={handleRedirect}
                    >
                        Continue to {reAuthenticateStatus.companyName}
                    </Button>
                )}
            </Card.Body>
        </Background>
    );
}

ReAuthenticationSuccess.propTypes = {
    theme: PropTypes.object.isRequired,
    reAuthenticateStatus: PropTypes.object.isRequired,
};

export default ReAuthenticationSuccess;
