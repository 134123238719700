import { connect } from 'react-redux';
import { updateSessionToken } from 'actions/session';
import { setVerificationConfiguration } from 'actions/general';

const mapStateToProps = (state) => {
    return {
        session: state.session,
        profile: state.profile,
        reAuthenticateSessionID: state.reAuthenticate?.reAuthenticateSessionID,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSessionToken: (token) => dispatch(updateSessionToken(token)),
        setVerificationConfiguration: (data) => dispatch(setVerificationConfiguration(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps);
