import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import styles from './BankSuccess.module.scss';
import Background from 'core-components/Background';
import Button from 'core-components/Button';
import { setButtonVariant } from 'utils/utilities';
import { connect } from 'react-redux';

function BankSuccess({ profile, verificationConfiguration, history }) {
    const tries = profile?.company?.user?.verificationDetails;
    const isKbaVerified = tries?.isKbaVerified === 1;
    const isDocumentVerified = tries?.isDocumentValid;

    const isDocumentEnabled = profile?.workflow?.idVerifications?.govtId?.enabled;
    const isKbaEnabled = profile?.workflow?.idVerifications?.kba?.enabled;

    const getMaxAttempts = (type) => {
        const kbaAttempts = profile?.workflow?.idVerifications?.kba?.attempts;
        const idAttempts = profile?.workflow?.idVerifications?.govtId?.attempts;

        if (type === 'govId') {
            return idAttempts;
        } else {
            return kbaAttempts;
        }
    };

    const isCardEnabled = (current, type) => {
        return getMaxAttempts(type) - current > 0;
    };

    const isKbaCardEnabled =
        isCardEnabled(tries?.kbaAttempts, 'KBA') && !isKbaVerified && isKbaEnabled;
    const isDocumentCardEnabled =
        isCardEnabled(tries?.govtIdAttempts, 'govtId') && !isDocumentVerified && isDocumentEnabled;

    const handleNextStep = () => {
        if (verificationConfiguration === 'AND' && (isKbaCardEnabled || isDocumentCardEnabled)) {
            history.push('/verification');
        } else {
            history.push(profile?.defaultRoute[2]);
        }
    };

    const isSingleMethod = verificationConfiguration === 'SINGLE';
    const isOtherMethodAvailable =
        verificationConfiguration === 'AND' && (!isKbaCardEnabled || !isDocumentCardEnabled);
    const isEEPAvailable = profile?.defaultRoute?.[2] === '/thank-you';

    const descText =
        (isSingleMethod || isOtherMethodAvailable) && isEEPAvailable ? (
            'Please continue to complete the verification process.'
        ) : (
            <>
                {' '}
                You will need to continue to complete the remaining verifications.
                <br />
                <br />
                Click Continue to proceed
            </>
        );

    return (
        <Background className={styles.bankWrapper}>
            <Card.Header className={styles.cardHeader}>
                🎉 The account owner information matched what you provided.
            </Card.Header>

            <Card.Body className={styles.cardBody}>
                <div className={styles.messageBox}>
                    <p className={styles.displayMessage}>{descText}</p>
                </div>

                <div className={styles.btnContainer}>
                    <Button
                        block
                        className={styles.nextStepBtn}
                        variant={setButtonVariant(profile.company?.theme.button.variant)}
                        onClick={handleNextStep}
                    >
                        Continue
                    </Button>
                </div>
            </Card.Body>
        </Background>
    );
}

BankSuccess.propTypes = {
    profile: PropTypes.object,
    history: PropTypes.any,
    verificationConfiguration: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        verificationConfiguration: state.general.verificationConfiguration,
    };
}

export default connect(mapStateToProps, null)(BankSuccess);
