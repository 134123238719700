import {
    RESET_ALL_REDUCERS,
    UPDATE_REAUTHENTICATE_SESSION_UUID,
    RESET_REAUTHENTICATE_SESSION_UUID,
    UPDATE_REAUTHENTICATE_SESSION_STATUS,
    UPDATE_REAUTHENTICATE_SELFIE_CHECK,
    UPDATE_REAUTHENTICATE_UPDATE_STATUS,
    UPDATE_REAUTHENTICATE_ADDITIONAL_VERIFICATION,
    UPDATE_SESSION_DEVICE_INFO,
    HIDE_CODE_RESEND_LINK,
} from 'actions';

const INITIAL_STATE = {
    reAuthenticateSessionID: undefined,
    reauthenticateSessionStatus: undefined,
    sessionStatus: undefined,
    isDeviceInfoSent: undefined,
    hideCodeResendLink: false,
};

export function reAuthenticateReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_REAUTHENTICATE_SESSION_UUID:
            return {
                ...state,
                reAuthenticateSessionID: action.data,
            };

        case RESET_REAUTHENTICATE_SESSION_UUID:
            return {
                ...state,
                reAuthenticateSessionID: undefined,
            };

        case UPDATE_REAUTHENTICATE_SESSION_STATUS:
            return {
                ...state,
                reauthenticateSessionStatus: action.data,
            };

        case UPDATE_REAUTHENTICATE_SELFIE_CHECK:
            return {
                ...state,
                reauthenticateSessionStatus: {
                    ...state.reauthenticateSessionStatus,
                    selfieVerification: {
                        ...action.payload,
                    },
                },
            };

        case UPDATE_REAUTHENTICATE_UPDATE_STATUS:
            return {
                ...state,
                sessionStatus: action.data,
            };

        case UPDATE_REAUTHENTICATE_ADDITIONAL_VERIFICATION:
            return {
                ...state,
                reauthenticateSessionStatus: {
                    ...state.reauthenticateSessionStatus,
                    additionalVerification: {
                        ...action.payload,
                    },
                },
            };

        case UPDATE_SESSION_DEVICE_INFO:
            return {
                ...state,
                isDeviceInfoSent: true,
            };

        case HIDE_CODE_RESEND_LINK:
            return {
                ...state,
                hideCodeResendLink: action.payload,
            };

        case RESET_ALL_REDUCERS:
            return {
                ...state,
                reAuthenticateSessionID: undefined,
                reauthenticateSessionStatus: undefined,
                sessionStatus: undefined,
                isDeviceInfoSent: undefined,
                hideCodeResendLink: false,
            };

        default:
            return state;
    }
}
