import {
    UPDATE_SHOW_INSTRUCTIONS,
    SET_INSTRUCTION_TYPE,
    SET_LAST_USED_INSTRUCTION_TYPE,
} from 'actions';

export const setShowInstructions = (data) => {
    return {
        type: UPDATE_SHOW_INSTRUCTIONS,
        payload: data,
    };
};

export const setInstructionType = (data) => {
    return {
        type: SET_INSTRUCTION_TYPE,
        payload: data,
    };
};

export const setLastUsedInstructionType = (data) => {
    return {
        type: SET_LAST_USED_INSTRUCTION_TYPE,
        payload: data,
    };
};
