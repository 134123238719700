import {
    UPDATE_TOKEN,
    RESET_TOKEN,
    UPDATE_SOURCE,
    SESSION_USER_SAVED,
    RESET_ALL_REDUCERS,
} from 'actions';

export default (state = {}, action) => {
    switch (action.type) {
        case UPDATE_TOKEN:
            return { ...state, token: action.data.token };
        case UPDATE_SOURCE:
            return { ...state, source: action.data.source };
        case RESET_TOKEN:
            return {};
        case SESSION_USER_SAVED:
            return { ...state, profileSaved: true };
        case RESET_ALL_REDUCERS:
            return {};
        default:
            return state;
    }
};
