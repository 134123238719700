import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import classes from './footer.module.css';

const Dots = ({ itemsLength, activeSlide, handleDotClick }) => {
    return (
        <div className={classes.dotsContainer}>
            {Array(itemsLength)
                .fill()
                .map((_, index) => (
                    <button
                        type="button"
                        className={
                            index === activeSlide
                                ? `${classes.dot} ${classes.dotFilled}`
                                : `${classes.dot}`
                        }
                        key={uuidv4()}
                        onClick={() => handleDotClick(index)}
                    />
                ))}
        </div>
    );
};

Dots.propTypes = {
    activeSlide: PropTypes.number,
    itemsLength: PropTypes.number,
    handleDotClick: PropTypes.func,
};

export default Dots;
