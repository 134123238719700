/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Alert from 'react-bootstrap/Alert';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
// components
import styles from './DeviceLinks.module.scss';
import Background from 'core-components/Background';
import Loader from 'core-components/Loader';
import Button from 'core-components/Button';
import Image from 'core-components/Image';
import Message from './Message';
import Email from './Email';
import QR from './QR';
import Link from './Link';
import TimeUpModal from './components/TimeUpModal';
import { REAUTHENTICATE_ROUTE, VERIFICATION_FLOWS, REAUTHENTICATE_ROUTES } from 'utils/constants';

const PROCESSING_TEXT = 'Your process has been started on your device.';

const DeviceLinks = ({
    profile,
    verificationConfiguration,
    verificationFlow,
    reauthenticateSessionStatus,
    reAuthenticateSessionID,
    // reauthenticate polling props
    startReauthenticatePolling,
    stopReauthenticatePolling,
    resetReauthenticatePolling,
    reauthenticateLastPoint,
    reauthenticateCount,
    getReauthenticateSessionStatus,
    ...props
}) => {
    const isReauthenticate = verificationFlow === VERIFICATION_FLOWS.REAUTHENTICATE;
    const sessionTheme = profile?.company?.theme || reauthenticateSessionStatus?.theme;

    // Generate the link according to session
    let LINK = 'https://verify.authenticating.com';

    const medallionLink = `${window.location.protocol}//${window.location.host}/mobile?token=${props.session.token}`;
    const reauthenticateLink = `${window.location.protocol}//${window.location.host}/${REAUTHENTICATE_ROUTE}/mobile?sessionUUID=${reAuthenticateSessionID}`;

    if (typeof window !== 'undefined') {
        LINK = isReauthenticate ? reauthenticateLink : medallionLink;
    }

    const [selected, setSelected] = React.useState('sms');
    const [cancelled, setCancelled] = React.useState(false);
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Reauthenticate flow starts 👇

    const isSelfieAttemptsUsed =
        reauthenticateLastPoint?.data?.selfieVerification?.attempts >=
        reauthenticateLastPoint?.data?.allowedAttempts;

    const isEmailAttemptsUsed =
        reauthenticateLastPoint?.data?.additionalVerification?.email?.attempts >=
        reauthenticateLastPoint?.data?.allowedAttempts;

    const isPhoneAttemptsUsed =
        reauthenticateLastPoint?.data?.additionalVerification?.phone?.attempts >=
        reauthenticateLastPoint?.data?.allowedAttempts;

    const emailFailed =
        reauthenticateLastPoint?.data?.additionalVerification?.email?.status === 'FAILED';
    const phoneFailed =
        reauthenticateLastPoint?.data?.additionalVerification?.phone?.status === 'FAILED';
    const selfieFailed = reauthenticateLastPoint?.data?.selfieVerification?.attempts === 'FAILED';

    const handleReauthenticateFailure = useCallback(() => {
        const currentStatus = reauthenticateLastPoint?.data?.status;

        if (currentStatus === 'COMPLETED' || currentStatus === 'FAILED') {
            if (currentStatus === 'COMPLETED') {
                stopReauthenticatePolling();
            } else if (selfieFailed || isSelfieAttemptsUsed) {
                props.history.push(REAUTHENTICATE_ROUTES.SELFIE_FAILURE);
                stopReauthenticatePolling();
            } else if (
                (emailFailed && isEmailAttemptsUsed) ||
                (phoneFailed && isPhoneAttemptsUsed)
            ) {
                props.history.push(REAUTHENTICATE_ROUTES.VERIFY_CODE_FAILURE);
                stopReauthenticatePolling();
            }
        } else if (reauthenticateCount > 60) {
            stopReauthenticatePolling();
            handleShow();
        }
    }, [reauthenticateLastPoint, reauthenticateCount]);

    const handleReauthenticatePolling = useCallback(() => {
        resetReauthenticatePolling();
        getReauthenticateSessionStatus(reAuthenticateSessionID);

        return () => resetReauthenticatePolling();
    }, []);

    const handleReauthenticateModalClose = () => {
        resetReauthenticatePolling();
        getReauthenticateSessionStatus(reAuthenticateSessionID);
        handleClose();
    };

    const handleReauthenticateSessionClose = () => {
        setCancelled(true);
        stopReauthenticatePolling();
    };

    // Reauthenticate flow ends 👆

    // Medallion flows starts 👇

    const bothEitherMethod =
        verificationConfiguration === 'AND' || verificationConfiguration === 'OR';

    // is Kba available or not
    const currentKbaAttempts = profile?.company?.user?.verificationDetails?.kbaAttempts;
    const totalKbaAttempts = profile?.workflow?.idVerifications?.kba?.attempts;
    const isKbaAttemptsUsed = currentKbaAttempts >= totalKbaAttempts;
    const isKbaVerified = profile?.company?.user?.verificationDetails?.isKbaVerified === 1;
    const isKbaAvailable = !isKbaVerified && !isKbaAttemptsUsed;

    // is bank option available or not
    const isBankVerified = profile?.company?.user?.verificationDetails?.isBankOwnerMatched;
    const currentBankAttempts = profile?.company?.user?.verificationDetails?.bankAttempts;
    const totalBankAttempts = profile?.workflow?.idVerifications?.bank?.attempts;
    const isBankAttemptsUsed = currentBankAttempts >= totalBankAttempts;
    const isBankAvailable = !isBankAttemptsUsed && !isBankVerified;

    const govtIdAttempts = props?.lastPoint?.data?.metadata?.govtIDAttempts;
    const maxIdAttempts = profile?.workflow?.idVerifications?.govtId?.attempts || 0;
    const limitExceed = govtIdAttempts >= maxIdAttempts;

    const handleMedallionModalClose = () => {
        props.resetPolling();
        props.startPolling();
        handleClose();
    };

    const handleMedallionPolling = useCallback(() => {
        if (isMobile) {
            window.location.replace(`${LINK}&&source=MOBILE`);
        }

        props.resetPolling();
        props.startPolling();
        return () => props.resetPolling();
    }, []);

    const handleMedallionSessionClose = () => {
        setCancelled(true);
        props.setStatus('IN_PROGRESS');
    };

    // Medallion flows ends 👆

    const handleModalClose = isReauthenticate
        ? handleReauthenticateModalClose
        : handleMedallionModalClose;

    React.useEffect(() => {
        setCancelled(false);
        if (!isReauthenticate && !profile.loading) {
            if (
                props.lastPoint?.data.status === 'COMPLETED' ||
                props.lastPoint?.data.workflowStatus?.idVerifications?.govt_id?.status ===
                    'COMPLETED'
            ) {
                props.history.goBack();
            } else if (limitExceed) {
                if (bothEitherMethod && (isKbaAvailable || isBankAvailable)) {
                    props.history.push('/verification');
                } else {
                    props.history.push('/verification/idFailed');
                }
            } else if (props.count > 60) {
                props.stopPolling();
                handleShow();
            }
        } else {
            handleReauthenticateFailure();
        }
    }, [props.lastPoint, props.count, handleReauthenticateFailure, isReauthenticate]);

    React.useEffect(() => {
        if (isReauthenticate) {
            handleReauthenticatePolling();
        } else {
            handleMedallionPolling();
        }
    }, [handleMedallionPolling, handleReauthenticatePolling, isReauthenticate]);

    let verificationOption = 'OR';

    if (verificationConfiguration) {
        verificationOption = verificationConfiguration;
    }

    const COMPONENTS = {
        sms: (
            <Message
                {...props}
                verificationOption={verificationOption}
                theme={sessionTheme}
                isReauthenticate={isReauthenticate}
            />
        ),
        email: (
            <Email
                {...props}
                verificationOption={verificationOption}
                theme={sessionTheme}
                isReauthenticate={isReauthenticate}
            />
        ),
        qr: <QR link={LINK} {...props} verificationOption={verificationOption} />,
        link: (
            <Link
                link={LINK}
                {...props}
                verificationOption={verificationOption}
                theme={sessionTheme}
            />
        ),
    };

    const handleSessionClose = isReauthenticate
        ? handleReauthenticateSessionClose
        : handleMedallionSessionClose;

    if (isMobile) {
        return (
            <Background className={styles.deviceLinkTabs}>
                <Loader />
            </Background>
        );
    }

    const isProcessing = isReauthenticate
        ? reauthenticateLastPoint?.data?.status === 'IN_PROGRESS'
        : props?.lastPoint?.data?.status !== 'IN_PROGRESS';

    const processingTextMedallion =
        props?.lastPoint?.data?.status === 'INITIATED'
            ? PROCESSING_TEXT
            : props?.lastPoint?.data?.status;

    const processingTextReauthenticate =
        reauthenticateLastPoint?.data?.status === 'IN_PROGRESS'
            ? PROCESSING_TEXT
            : reauthenticateLastPoint?.data?.status;

    return (
        <Background className={styles.deviceLinkTabs}>
            <TimeUpModal show={show} onHide={handleModalClose} />
            {isProcessing ? (
                <React.Fragment>
                    <Card.Header as="h5" className={styles.processingCardHeader}>
                        Processing on device.
                    </Card.Header>
                    <Card.Body
                        className={styles.body}
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Loader />
                        <br />
                        <div className={styles.processingTitle}>
                            {isReauthenticate
                                ? processingTextReauthenticate
                                : processingTextMedallion}
                        </div>
                        <span className={cn(styles.processingTitle, styles.warningTxt)}>
                            DO NOT CLOSE THIS WINDOW
                        </span>
                    </Card.Body>
                    <Card.Footer
                        style={{
                            border: 'none',
                            background: 'none',
                            marginBottom: '24px',
                        }}
                    >
                        <Button
                            block
                            className={styles.processingFooterBtn}
                            variant="outline-primary"
                            onClick={handleSessionClose}
                            disabled={cancelled}
                        >
                            Cancel
                        </Button>
                    </Card.Footer>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Card.Header className={styles.cardHeader}>
                        <Alert.Link
                            className={styles.backArrow}
                            onClick={() => window.history.back()}
                        >
                            <Image name="Back.svg" />
                        </Alert.Link>
                        Continue With Your Verification
                    </Card.Header>
                    <div className={styles.cardBodyWarpper}>
                        <Nav
                            fill
                            className={styles.navWrapper}
                            variant="tabs"
                            defaultActiveKey={selected}
                            onSelect={(selectedKey) => setSelected(selectedKey)}
                        >
                            <Nav.Item>
                                <Nav.Link
                                    className={cn(
                                        styles.navlink,
                                        selected === 'sms' && styles.active
                                    )}
                                    eventKey="sms"
                                >
                                    SMS
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    className={cn(
                                        styles.navlink,
                                        selected === 'email' && styles.active
                                    )}
                                    eventKey="email"
                                >
                                    Email
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    className={cn(
                                        styles.navlink,
                                        selected === 'qr' && styles.active
                                    )}
                                    eventKey="qr"
                                >
                                    QR Code
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    className={cn(
                                        styles.navlink,
                                        selected === 'link' && styles.active
                                    )}
                                    eventKey="link"
                                >
                                    Link
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        {COMPONENTS[selected]}
                    </div>
                </React.Fragment>
            )}
        </Background>
    );
};

DeviceLinks.propTypes = {
    profile: PropTypes.object,
    verificationConfiguration: PropTypes.string,
    verificationFlow: PropTypes.string,
    reauthenticateSessionStatus: PropTypes.object,
    session: PropTypes.object,
    reAuthenticateSessionID: PropTypes.string,
    resetPolling: PropTypes.func,
    startPolling: PropTypes.func,
    stopPolling: PropTypes.func,
    lastPoint: PropTypes.object,
    history: PropTypes.object,
    setStatus: PropTypes.func,
    count: PropTypes.any,
    startReauthenticatePolling: PropTypes.func,
    stopReauthenticatePolling: PropTypes.func,
    resetReauthenticatePolling: PropTypes.func,
    reauthenticateLastPoint: PropTypes.object,
    reauthenticateCount: PropTypes.number,
    getReauthenticateSessionStatus: PropTypes.func,
};

export default DeviceLinks;
