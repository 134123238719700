/* eslint-disable no-unused-vars */
import http from './http'

export const getKBAQuestionsAPI = (userAccessCode) => {
  return http.post('/identity/kba', {
    userAccessCode: userAccessCode,
  })
}

export const saveKBAAnswersAPI = (data) => {
  return http.post('/identity/kba-verification', data)
}

export const uploadPassportAPI = (passport, userAccessCode, handleProgress) => {
  const config = {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      )
      handleProgress(percentCompleted)
    },
  }
  return http.post(
    '/identity/passport/scan/enhanced',
    {
      idFront: passport.idFront,
      userAccessCode: userAccessCode,
      countryCode: passport.country
    },
    config,
  )
}

export const checkPassport = async (userAccessCode) => {
  return await http.post('/identity/passport/scan/status', {
    userAccessCode: userAccessCode,
  })
}

export const uploadDrivingLicenseAPI = async (
  drivingLicense,
  userAccessCode,
  handleProgress,
) => {
  const config = {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      )
      handleProgress(percentCompleted)
    },
  }
  return http.post(
    '/identity/document/scan/enhanced',
    {
      idFront: drivingLicense.front,
      idBack: drivingLicense.back,
      userAccessCode: userAccessCode,
      countryCode: drivingLicense.country,
      documentType: drivingLicense.documentType
    },
    config,
  )
}

export const checkDrivingLicense = async (userAccessCode) => {
  return await http.post('/identity/document/scan/status', {
    userAccessCode: userAccessCode,
  })
}

const poll = async (fn, validate, interval, maxAttempts) => {
  let attempts = 0

  const executePoll = async (resolve, reject) => {
    let result = undefined
    try {
      result = await fn()
    } catch (err) {
      return reject(err)
    }

    attempts++

    if (validate(result)) {
      return resolve(result)
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'))
    } else {
      setTimeout(executePoll, interval, resolve, reject)
    }
  }

  return new Promise(executePoll)
}

export const markCompleteAPI = () => {
  return http.post('/identity/passport/scan/status')
}


