import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Application from './components/Application';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer, toast } from 'react-toastify';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import configureStore, { history } from './store';
import { hotjar } from 'react-hotjar';
import { datadogRum } from '@datadog/browser-rum';
import ErrorBoundary from 'providers/ErrorBoundary';
import GoogleTagManager from 'providers/GoogleConversionTracker';
import { loggerConfig } from 'config';
const { store, persistor } = configureStore();

if (loggerConfig.LOGGING_ENABLED) {
    // url for more info:
    // https://docs.datadoghq.com/real_user_monitoring/browser/?tab=us#configuration
    datadogRum.init({
        applicationId: loggerConfig.SERVICE.APPLICATION_ID,
        clientToken: loggerConfig.SERVICE.CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'hosted-ui',
        // Specify a version number to identify the deployed version of your application in Datadog
        version: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_ENV}`,
        sampleRate: 100,
        premiumSampleRate: 0, // Disable's session replays
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        env: process.env.REACT_APP_ENV,
    });
}

process.env.REACT_APP_HJID && hotjar.initialize(process.env.REACT_APP_HJID, 6);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary>
                <GoogleTagManager />
                <ConnectedRouter history={history}>
                    <Application />
                    <ToastContainer
                        autoClose={8000}
                        draggable={false}
                        position={toast.POSITION.BOTTOM_CENTER}
                        theme="colored"
                    />
                </ConnectedRouter>
            </ErrorBoundary>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
