import {
    getKBAQuestionsAPI,
    saveKBAAnswersAPI,
    uploadPassportAPI,
    uploadDrivingLicenseAPI,
    markCompleteAPI,
    checkPassport,
    checkDrivingLicense,
} from 'api/identity';
import { getErrorMessage, toCamelCase } from 'utils/utilities';
import { REQUEST_KBA, RESET_KBA, UPDATE_KBA } from '.';
import { toast } from 'react-toastify';
import { createPollingActions, getPollingState } from 'redux-polling';

export const updateKBAQuestions = (data) => {
    return {
        type: UPDATE_KBA,
        data,
    };
};

export const getKBAQuestions = (userAccessCode) => (dispatch) => {
    dispatch({ type: REQUEST_KBA });
    return getKBAQuestionsAPI(userAccessCode)
        .then((response) => {
            dispatch(updateKBAQuestions(toCamelCase(response.data.data)));
        })
        .catch((error) => {
            const isMoreThanOneRecord = error?.response?.data?.errorMessage?.includes(
                'MORE_THAN_ONE_RECORD_FOUND'
            );
            if (error?.response?.status === 417 || isMoreThanOneRecord) {
                const errorMessage = isMoreThanOneRecord
                    ? 'Please update your information with at least your Full Legal Name, Date of Birth and last four digits of your SSN to generate the quiz.'
                    : 'We could not generate a knowledge based authentication quiz from the information provided. Please authenticate yourself with a photo ID.';

                toast.error(errorMessage);
                dispatch(
                    updateKBAQuestions({
                        error: '417',
                        message: errorMessage,
                    })
                );
            } else {
                dispatch(updateKBAQuestions({ error: getErrorMessage(error) }));
            }
        });
};

export const saveKBAAnswers = (data) => () => {
    return saveKBAAnswersAPI(data);
};

export const uploadPassport = (passport, userAccessCode, handleProgress) => (dispatch) => {
    return uploadPassportAPI(passport, userAccessCode, handleProgress).then(() => {
        dispatch(passportActions.reset());
        dispatch(passportActions.start(userAccessCode));
    });
};

export const uploadDrivingLicense =
    (drivingLicense, userAccessCode, handleProgress) => (dispatch) => {
        return uploadDrivingLicenseAPI(drivingLicense, userAccessCode, handleProgress).then(() => {
            dispatch(dlActions.reset());
            dispatch(dlActions.start(userAccessCode));
        });
    };

export const markComplete = () => () => {
    return markCompleteAPI();
};

/* Actions */
const pollingInterval = 15000;
const historyLength = 1;

export const passportActions = createPollingActions(
    'passport-status',
    (userAccessCode) => checkPassport(userAccessCode),
    pollingInterval,
    historyLength
);

export const dlActions = createPollingActions(
    'dl-status',
    (userAccessCode) => checkDrivingLicense(userAccessCode),
    pollingInterval,
    historyLength
);

/* Selectors */
const isPollingActive = (type) => (state) => getPollingState(state, type).isActive;

const getPointHistory = (type) => (state) => getPollingState(state, type).history;

const getLastPoint = (type) => (state) => getPollingState(state, type).lastEntry;

export const selectors = (type) => {
    return {
        isPollingActive: isPollingActive(type),
        getPointHistory: getPointHistory(type),
        getLastPoint: getLastPoint(type),
    };
};

export const resetKBA = () => {
    return {
        type: RESET_KBA,
    };
};
