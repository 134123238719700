import { ResultAsync } from 'neverthrow';
import { RESET_ALL_REDUCERS } from './index';

const dispatchActionType = (dispatch, actionType, metadata = {}, data = null, response) => {
    if (!actionType) return;

    const dispatchPayload = {
        type: actionType,
        data: data,
        meta: metadata,
    };

    if (response) {
        dispatchPayload['response'] = response;
    }

    dispatch(dispatchPayload);
};

const apiDispatcher =
    ({
        axiosMethod,
        action = { successType: null, inProgressType: null, failureType: null },
        commonConfig = { showToastOnError: false, rawResponse: false },
        metadata = {}, // any metadata that needs to passed into the type
    }) =>
    (dispatch) => {
        dispatchActionType(dispatch, action.inProgressType, metadata, null);

        const result = ResultAsync.fromPromise(axiosMethod(), (error) => error);

        return result
            .map((response) => {
                dispatchActionType(
                    dispatch,
                    action.successType,
                    metadata,
                    response.data,
                    commonConfig.rawResponse ? response : undefined
                );
                return response;
            })
            .mapErr((error) => {
                if (
                    (error?.response && error.response?.status === 403) ||
                    error.response?.status === 401
                ) {
                    //dispatch logout function
                    dispatchActionType(dispatch, RESET_ALL_REDUCERS);
                    window.location.href = '/session-expired?session=expired';
                }

                dispatchActionType(
                    dispatch,
                    action.failureType,
                    metadata,
                    error?.response?.data,
                    commonConfig.rawResponse ? error?.response : undefined
                );

                return error;
            });
    };

export default apiDispatcher;
