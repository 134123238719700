import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styles from './SessionExpired.module.scss';
import Expired_session_img from 'assets/images/session_expired.svg';
import { useLocation } from 'react-router';
import Loader from 'core-components/Loader';

function SessionExpired() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    const session = queryParams.get('session');

    if (!session) {
        return <Loader />;
    }

    return (
        <Box className={styles.container}>
            <Box className={styles.subContainer}>
                <h1 className={styles.heading}>
                    {session === 'expired' ? 'Expired Link' : 'Invalid Link'}
                </h1>
                <img
                    src={Expired_session_img}
                    alt="Expired session illustration"
                    className={styles.illustration}
                />
                <Typography className={styles.desc}>
                    {`Unfortunately, the link ${
                        session === 'expired' ? 'has expired' : 'is invalid'
                    }. Please use a valid link or contact your
                    administrator for further steps.`}
                </Typography>
            </Box>
        </Box>
    );
}

export default SessionExpired;
