import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        reAuthenticateStatus: state.reAuthenticate?.reauthenticateSessionStatus || {},
        reAuthenticateSessionId: state.reAuthenticate.reAuthenticateSessionID,
        theme: state.reAuthenticate?.reauthenticateSessionStatus?.theme,
    };
};

export default connect(mapStateToProps, {});
